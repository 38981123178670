const routes = [
  {
    path: "/",
    name: "parametrages_comptables__plan_comptable",
    meta: {
      can: true,
      title: "Plan comptable",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/ParametrageComptable/PlanComptable.vue"
      ),
  },

  {
    path: "type-facturation",
    name: "parametrages_comptables__type_facturation",
    meta: {
      can: true,
      title: "Type de facturation",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/ParametrageComptable/TypeFacturation.vue"
      ),
  },

  {
    path: "plan-analytique",
    name: "parametrages_comptables__plan_analytique",
    meta: {
      can: true,
      title: "Plan analytique",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/ParametrageComptable/PlanAnalytique.vue"
      ),
  },

  {
    path: "flux-financier",
    name: "parametrages_comptables__flux_financier",
    meta: {
      can: true,
      title: "Flux financier",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/ParametrageComptable/FluxFinancier.vue"
      ),
  },
];

export default routes;
