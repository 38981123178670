/* eslint-disable no-unused-vars */
import { apolloClient } from "@/vue-apollo.js";

export default {
  namespaced: true,

  state: {
    saisons: [],
    selectedSaison: null,
  },

  getters: {
    saisons: (state) => state.saisons,

    currentSaison(state) {
      return state.saisons.find((saison) => saison.EST_Actif == true);
    },

    currentSaisonId(state, getters) {
      return getters.currentSaison.id;
    },

    selectedSaison: (state) => {
      return state.selectedSaison;
    },

    selectedSaisonId(state, getters) {
      return getters.selectedSaison.id;
    },

    lastSaison(state, getters) {
      return state.saisons.find(
        (saison) => saison.id == getters.selectedSaison - 1
      );
    },

    lastSaisonId(state, getters) {
      return getters.lastSaison.id;
    },

    getSaison: (state) => (saison_annee) => {
      return state.saisons.find((saison) => saison.Annee == saison_annee);
    },

    nextSaison(state, getters) {
      return state.saisons.find(
        (s) =>
          s.id == getters.currentSaison.id + 1 &&
          (s.ID_EtapeSaison != 5 && s.ID_EtapeSaison != 4)
      );
    },
  },

  mutations: {
    SET_SAISONS(state, payload) {
      state.saisons = payload;
    },

    SET_SELECTED_SAISON(state, payload) {
      state.selectedSaison = payload;
    },
  },

  actions: {
    async initSaisons({ dispatch, commit, getters }, payload) {
      //Maybe sort result


      await commit("SET_SAISONS", payload);
      //onsole.log(payload)
      let saison = getters.currentSaison;
      if (getters.nextSaison) {
        saison = getters.nextSaison;
      }
      await commit("SET_SELECTED_SAISON", saison);
      return;
    },

    async getSaisons() {
      let query = {
        query: require("@/graphql/Saisons/get_saisons.gql"),
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.FEDE_Saison;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async setSelectedSaison({ commit, getters, dispatch }, payload) {
      await dispatch("app/setAppIsLoading", true, { root: true });

      await commit(
        "SET_SELECTED_SAISON",
        getters.saisons.find((sais) => sais.id == payload.ID_Saison)
      );
      await dispatch("app/hydrateApp", {}, { root: true });

      await dispatch("app/setAppIsLoading", false, { root: true });
    },
  },
};
