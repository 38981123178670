import store from "@/store";
import router from "@/router";
import RoutesParametrageComptable from "@/router/routes/GestionDesFinances/ParametrageComptable";
import RoutesSageSepa from "@/router/routes/GestionDesFinances/SageSepa";

const route = [
  {
    path: "/",
    name: "gestion_des_finances__dashboard",
    meta: {
      can: false,
      title: "Gestion des finances",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/views/GestionDesFinances/GestionDesFinances__Dashboard.vue"
      ),
  },
  {
    path: "parametrage-plan-comptable",
    name: "gestion_des_finances__parametrage_plan_comptable",
    meta: {
      can: false,
      title: "Paramétrage plan comptable",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/modules/GestionDesFinances/ParametragePlanComptable.vue"
      ),
    children: RoutesParametrageComptable,
  },
  {
    path: "export-sepa",
    // name: "gestion_des_finances__export_sage",
    meta: {
      can: false,
      title: "Exporter vers Sage",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/modules/GestionDesFinances/ExportSage.vue"
      ),
    children: RoutesSageSepa,
  },
  {
    path: "export-sage",
    name: "gestion_des_finances__generer_virements_sepa",
    meta: {
      can: false,
      title: "Générer les virements SEPA",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/modules/GestionDesFinances/GenererVirementsSEPA.vue"
      ),
  },
  {
    path: "rechercher-flux-financiers",
    name: "gestion_des_finances__rechercher_flux_financiers",
    meta: {
      can: false,
      title: "Rechercher les flux financiers",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/modules/GestionDesFinances/RechercherFluxFinanciers.vue"
      ),
  },
  {
    path: "etats-statistiques",
    name: "gestion_des_finances__etats_statistiques",
    meta: {
      can: false,
      title: "Etats statistiques",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/modules/GestionDesFinances/EtatsStatistiques.vue"
      ),
  },
  // {
  //   path: "rib",
  //   name: "gestion_des_finances__rib",
  //   meta: {
  //     can: false,
  //     title: "Gestion type de règlement et RIB d'une structure",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/Gestion_modality_Rib.vue"
  //     ),
  // },
  {
    path: "recapitulatif",
    name: "gestion_des_finances__recapitulatif",
    meta: {
      can: false,
      title: "Récapitulatif mensuel",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/Recapitulatif_mensuel.vue"
      ),
  },
];

export default route;
