const route = [
  {
    path: "/",
    name: "mes_licences__dashboard",
    meta: {
      can: false,
      title: "Mes licences",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MesLicences" */ "@/views/MesLicences/MesLicences__Dashboard.vue"
      ),
  },

  {
    path: "ma-licence",
    name: "mes_licences__ma_licence",
    meta: {
      can: false,
      title: "QRCODE licence",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MesLicences" */ "@/modules/MesLicences/MaLicence.vue"
      ),
  },

  {
    path: "historique",
    name: "mes_licences__historique_des_licences",
    meta: {
      can: false,
      title: "Historique de mes licences",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MesLicences" */ "@/modules/MesLicences/HistoriqueDesLicences.vue"
      ),
  },
];

export default route;
