<template>
  <v-container :class="containerClasses">
    <Loading :loading="izUploading && showLoading" />

    <v-row row wrap>
      <v-col :class="contentClasses">
        <input
          ref="input"
          type="file"
          name="image"
          accept="image/*"
          @change="setImage"
        />

        <section class="cropper-area">
          <div
            v-if="hasPreviousImage"
            :style="{ background: 'url(' + currentImageUrl + ')' }"
            class="image-uploader__current-image"
          >
            <font-awesome-icon
              icon="times"
              class="close"
              v-on:click="removeCurrentImage"
            />
          </div>

          <div v-if="hazImage" class="img-cropper">
            <vue-cropper
              ref="cropper"
              :aspect-ratio="5 / 2"
              :src="imgSrc"
              :full="true"
              preview=".preview"
            />
          </div>

          <div class="no-image-message" v-else>Veuillez choisir une image</div>

          <div class="v-card__actions">
            <v-btn
              color="info"
              icon
              large
              :disabled="!hazImage"
              v-if="config.action.zoom_in"
              @click.prevent="zoom(0.2)"
            >
              <span class="mdi mdi-magnify-minus-outline"></span>
            </v-btn>
            <v-btn
              color="info"
              icon
              large
              :disabled="!hazImage"
              v-if="config.action.zoom_out"
              @click.prevent="zoom(-0.2)"
            >
              <span class="mdi mdi-magnify-plus-outline"></span>
            </v-btn>

            <v-btn
              v-if="config.action.left"
              href="#"
              role="button"
              @click.prevent="move(-10, 0)"
              >Move Left</v-btn
            >
            <a
              v-if="config.action.right"
              href="#"
              role="button"
              @click.prevent="move(10, 0)"
              >Move Right</a
            >
            <a
              v-if="config.action.up"
              href="#"
              role="button"
              @click.prevent="move(0, -10)"
              >Move Up</a
            >
            <a
              v-if="config.action.down"
              href="#"
              role="button"
              @click.prevent="move(0, 10)"
              >Move Down</a
            >
            <a
              v-if="config.action.rotate_right"
              href="#"
              role="button"
              @click.prevent="rotate(90)"
              >Rotate +90deg</a
            >
            <a
              v-if="config.action.rotate_left"
              href="#"
              role="button"
              @click.prevent="rotate(-90)"
              >Rotate -90deg</a
            >
            <a
              v-if="config.action.flip_x"
              ref="flipX"
              href="#"
              role="button"
              @click.prevent="flipX"
              >Flip X</a
            >
            <a
              v-if="config.action.flip_y"
              ref="flipY"
              href="#"
              role="button"
              @click.prevent="flipY"
              >Flip Y</a
            >
            <a
              v-if="config.action.crop"
              href="#"
              role="button"
              @click.prevent="cropImage"
              >Crop</a
            >
            <a
              v-if="config.action.reset"
              href="#"
              role="button"
              @click.prevent="reset"
              >Reset</a
            >
            <a
              v-if="config.action.debug"
              href="#"
              role="button"
              @click.prevent="getData"
              >Get Data</a
            >
            <a
              v-if="config.action.debug"
              href="#"
              role="button"
              @click.prevent="setData"
              >Set Data</a
            >
            <a
              v-if="config.action.debug"
              href="#"
              role="button"
              @click.prevent="getCropBoxData"
              >Get CropBox Data</a
            >
            <v-btn v-if="config.action.debug" @click.prevent="setCropBoxData"
              >Set CropBox Data</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              class="btn btn-primary"
              v-if="config.action.upload_image"
              @click.prevent="showFileChooser"
              >Choisir une image</v-btn
            >
            <slot></slot>
            <!-- <v-btn @click.prevent="$emit('save-actu')" class="btn btn-primary"
              >Enregistrer</v-btn
            > -->
          </div>
          <v-spacer></v-spacer>
          <textarea v-if="config.action.debug" v-model="data" />
        </section>
      </v-col>

      <v-col class="xs-12 md-5" v-if="preview">
        <section class="preview-area">
          <div class="preview"></div>

          <v-alert
            v-if="textImageHint"
            border="top"
            colored-border
            color="orange lighten-5"
            icon="info"
            elevation="2"
            >{{ textImageHint }}</v-alert
          >

          <div v-if="config.show_crop">
            <p>Cropped Image</p>
            <div class="cropped-image">
              <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
              <div v-else class="crop-placeholder" />
            </div>
          </div>
        </section>
      </v-col>
    </v-row>

    <v-row v-if="!autoUpload">
      <v-col class="xs-12 md-5">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            class="btn btn-red mt-5 mr-4"
            @click="actionCancel"
            >Annuler</v-btn
          >
          <v-btn
            :disabled="!hazImage"
            class="btn btn-primary mt-5"
            @click="actionUpload"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import Loading from "@/components/LoadingIndicator.vue";
import { error_notification } from "@/plugins/notifications.js";

export default {
  components: {
    VueCropper,
    Loading,
  },
  props: {
    showEnregistrer: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    resultWidth: {
      type: Number,
      default: 650,
    },
    resultHeight: {
      type: Number,
      default: 650,
    },
    compression: {
      type: Number,
      default: 0.8,
    },
    textImageHint: {
      type: String,
      default: "",
    },
    preview: {
      type: Boolean,
      default: true,
    },
    /**
     * If autoUpload is true, the actual image upload will be triggered by the next value, startAutoUpload
     */
    autoUpload: {
      type: Boolean,
      default: false,
    },
    startAutoUpload: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: -1,
    },
    currentImageUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      imgSrc: "",
      cropImg: "",
      data: null,

      hazImage: false,

      config: {
        show_crop: false,
        action: {
          up: false,
          right: false,
          down: false,
          left: false,
          rotate_right: false,
          rotate_left: false,
          crop: false,
          flip_x: false,
          flip_y: false,
          upload_image: true,
          debug: false,
          zoom_in: true,
          zoom_out: true,
        },
      },

      filename: "",

      izUploading: false,

      hasPreviousImage: false,
    };
  },

  async created() {
    this.izUploading = this.uploading;
    if (this.currentImageUrl !== "") {
      this.hasPreviousImage = true;
    }
  },

  watch: {
    currentImageUrl(newVal, oldVal) {
      // console.log("currentimage", newVal, oldVal);
      if (newVal !== "" && oldVal === "") {
        this.hasPreviousImage = true;
      } else {
        this.hasPreviousImage = false;
      }
      if (this.$refs.cropper) {
        this.$refs.cropper.clear();
      }
    },

    uploading(val) {
      if (this.izUploading && !val) {
        this.resetVars();
      }
      this.izUploading = val;
    },

    startAutoUpload(newVal, oldVal) {
      // console.log(this.filename, this.filename !== "");
      if (newVal && !oldVal && this.autoUpload) {
        if (this.filename !== "") {
          // console.log("starting upload");
          this.actionUpload();
        } else {
          // console.log("missing sth");
          this.$emit("uploadError", "No file selected");
          error_notification("No file selected");
          this.startAutoUpload = false;
        }
      }
      // console.log("startAutoUpload", newVal, oldVal);
    },
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    containerClasses() {
      let classes = "";

      if (this.izUploading) {
        classes += "__uploading";
      }

      if (!this.avatar) {
        classes += " p-0";
      }

      return classes;
    },

    contentClasses() {
      let classes = "";

      classes += this.preview ? "xs-12 md-7" : "xs-12";

      if (!this.avatar) {
        classes += " py-0";
      }

      return classes;
    },
  },

  methods: {
    setFilename(fname) {
      this.filename = fname;
      this.$emit("fileSelected", this.filename);
    },
    removeCurrentImage() {
      this.hasPreviousImage = false;
      this.$emit("remove-register");
      if (this.news) {
        this.news.image_url = "";
      }
      this.setFilename("");
    },
    resetVars() {
      this.filename = "";
      this.imgSrc = "";
      this.cropImg = "";
      this.data = null;
      this.hazImage = false;
      // needs to be done so that file select will fire an event
      this.$refs.input.value = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      if (file.type.indexOf("image/") === -1) {
        // alert('Please select an image file');
        this.hazImage = false;
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
        this.hazImage = true;

        this.setFilename(file.name);
      } else {
        alert("Sorry, FileReader API not supported");
        this.hazImage = false;
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    /**
     * make sure that the filename extension is .jpg as we store the images as jgp
     * @param fname
     * @returns {*}
     */
    fixFilename(fname) {
      // TODO
      return fname;
    },

    actionCancel() {
      this.$emit("actionCancel");
      // give the modal time to have its close animation before we reset the content
      setTimeout(() => {
        this.resetVars();
      }, 500);
    },

    dataURItoBlob(dataURI) {
      let binary = atob(dataURI.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: "image/png" });
    },
    dataURItoBuffer(dataURI) {
      let binary = atob(dataURI.split(",")[1]);
      return Buffer.from(binary, "binary");
    },

    async actionUpload() {
      this.izUploading = true;

      // gets the cropped area with a specific resulting image size range and compression quality
      let image_data;
      if (this.maxWidth > 0) {
        let test = this.$refs.cropper.getCroppedCanvas({
          maxWidth: this.maxWidth,
        });
        test.width = this.maxWidth;
        image_data = test.toDataURL("image/jpeg", this.compression);
      } else {
        image_data = this.$refs.cropper
          .getCroppedCanvas({
            maxWidth: this.resultWidth,
            maxHeight: this.resultHeight,
            imageSmoothingEnabled: true,
            imageSmoothingQuality: "high",
          })
          .toDataURL("image/jpeg", this.compression);
      }
      const hash =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let payload = {
        image: image_data,
        key: `${this.currentStructureId}_${hash}_${this.fixFilename(
          this.filename
        )}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      // console.log('posting', payload);
      this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload", payload,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            }
          }
        ).then((response) => {
          if (response.data.location) {
            this.$emit("actionUpload", {
              CheminDocument: response.data.location,
              NomDocument: this.filename,
            });
          } else {
            this.izUploading = false;
            error_notification("Upload unsuccessful");
          }
        })
        .catch((error) => {
          this.izUploading = false;
          error_notification(error.toString());
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.no-image-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  font-weight: 500;
  background: #f3f3f3;
  font-size: 1rem;
  color: grey;
}

input[type="file"] {
  display: none;
}

.preview {
  width: 200px !important;
  height: 200px !important;
  border-radius: 200px !important;
  margin: 0 auto 20px;
  background: #f3f3f3;

  img {
    border-radius: 0px !important;
  }
}

.cropper-area {
  width: 614px;
  width: 100%;
  position: relative;
}

.mdi {
  font-size: 1.5rem;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
  width: 100%;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>

<style lang="scss">
.image-uploader__current-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-size: contain !important;
  background-color: white !important;
  background-repeat: no-repeat !important;
  background-position: top right !important;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 11;
    background: red;
    color: white;
    border-radius: 30px;
    width: 27px;
    font-size: 20px;
    padding: 3px;
    height: 27px;
    cursor: pointer;
  }
}
</style>
