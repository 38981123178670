const routes = [
  // {
  //   path: "/",
  //   name: "export__sepa",
  //   meta: {
  //     can: true,
  //     title: "Export Sepa",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/SageSepa/Sepa.vue"
  //     ),
  // },

  {
    path: "/",
    name: "export__sepa",
    meta: {
      can: true,
      title: "Export Sepa",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/SageSepa/Sepa.vue"
      ),
  },

  {
    path: "export-sage",
    name: "export__sage",
    meta: {
      can: true,
      title: "Export Sage",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptable_parametrage" */ "@/modules/GestionDesFinances/SageSepa/Sage.vue"
      ),
  },
];

export default routes;
