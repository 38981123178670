const routes = [
  {
    path: "actualite",
    name: "information_de_la_structure__news",
    meta: {
      can: true,
      title: "Actualités",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_news" */ "@/modules/GestionDeLaStructure/InformationStructure/News.vue"
      ),
  },

  {
    path: "/",
    name: "information_de_la_structure__presentation",
    meta: {
      can: true,
      title: "Présentation de la strucutre",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_presentation" */ "@/modules/GestionDeLaStructure/InformationStructure/PresentationDeLaStructure.vue"
      ),
  },
  {
    path: "/administration-structure",
    name: "administration_information_structure",
    meta: {
      title: "Administration de la structure",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_presentation" */ "@/modules/GestionDeLaStructure/InformationStructure/AdministrationDeLaStructure.vue"
      ),
  },
  {
    path: "gestion-salaries",
    name: "information_de_la_structure__salaries",
    meta: {
      can: true,
      title: "Gestion des salariés",
    },
    component: () =>
      import(
        /* webpackChunkName: "information_de_la_structure__salaries" */ "@/modules/GestionDeLaStructure/InformationStructure/GestionDesSalaries.vue"
      ),
  },
  {
    path: "emailing",
    name: "id_fuse_email",
    meta: {
      title: "Emailing",
    },
    component: () =>
      import(
        /* webpackChunkName: "information_de_la_structure__salaries" */ "@/modules/GestionDeLaStructure/IdFuseEmailing/IdFuseEmailing.vue"
      ),
  },

  {
    path: "organigramme",
    name: "information_de_la_structure__organigramme",
    meta: {
      can: true,
      title: "Organigramme",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_organigramme" */ "@/modules/GestionDeLaStructure/InformationStructure/Organigramme.vue"
      ),
  },

  {
    path: "domiciliation",
    name: "information_de_la_structure__domiciliation",
    meta: {
      can: true,
      title: "Adresses",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_domiciliation" */ "@/modules/GestionDeLaStructure/InformationStructure/Domiciliation.vue"
      ),
  },

  {
    path: "nom-correspondant",
    name: "information_de_la_structure__nom_correspondant",
    meta: {
      can: true,
      title: "Nom correspondant",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_nomCorrespondant" */ "@/modules/GestionDeLaStructure/InformationStructure/NomCorrespondant.vue"
      ),
  },

  {
    path: "accueil-et-contact",
    name: "information_de_la_structure__accueil_et_contact",
    meta: {
      can: true,
      title: "Accueil et contact",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_accueil_et_contact" */ "@/modules/GestionDeLaStructure/InformationStructure/AccueilEtContact.vue"
      ),
  },

  {
    path: "reseaux-sociaux",
    name: "information_de_la_structure__reseaux_sociaux",
    meta: {
      can: true,
      title: "Réseaux sociaux",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_reseaux_sociaux" */ "@/modules/GestionDeLaStructure/InformationStructure/ReseauxSociaux.vue"
      ),
  },
  {
    path: "documents-officiels",
    name: "information_de_la_structure__documents_officiels",
    meta: {
      title: "Documents officiels",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_reseaux_sociaux" */ "@/modules/GestionDeLaStructure/InformationStructure/DocumentsOfficiels.vue"
      ),
  },

  {
    path: "cotisations-et-tarifs",
    name: "information_de_la_structure__cotisation_et_tarifs",
    meta: {
      can: true,
      title: "Cotisation et tarifs",
    },
    component: () =>
      import(
        /* webpackChunkName: "InformationStructure_reseaux_sociaux" */ "@/modules/GestionDeLaStructure/InformationStructure/CotisationEtTarifs.vue"
      ),
  },
  {
    path: "finances",
    name: "information_de_la_structure__finances",
    meta: {
      can: true,
      title: "Finances",
    },
    component: () =>
      import(
        /* webpackChunkName: "Information_de_la_structure__cotisation_et_tarifs" */ "@/modules/GestionDeLaStructure/InformationStructure/Gestion_modality_Rib.vue"
      ),
  },
];

export default routes;
