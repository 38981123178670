const route = [
  {
    path: "/",
    name: "app_settings__dashboard",
    component: () =>
      import(
        /* webpackChunkName: "appSettings" */ "@/views/AppSettings/AppSettings__Dashboard.vue"
      ),
  },

  {
    path: "playground",
    name: "app_settings__playground",
    component: () =>
      import(
        /* webpackChunkName: "appSettings" */ "@/modules/Settings/Playground.vue"
      ),
  },
];

export default route;
