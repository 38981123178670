<template>
  <div v-if="mounted" style="padding: 14px 26px;">
    <div class="text-center mb-12">
      <span
        class="layout-left-title"
        v-text="`${currentUser.CT_Nom} ${currentUser.CT_Prenom}`"
      />

      <v-menu right offset-x v-if="upload || (showFusion && isAdmin)">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="ml-3" v-bind="attrs" v-on="on"
            >mdi-dots-horizontal</v-icon
          >
        </template>

        <v-list>
          <v-list-item
              v-if="upload || (showFusion && isAdmin)"
            class="d-flex bordering align-items-center justify-center"
            @click="openAvatarChange"
          >
            <p class="m-0 label-menu mr-1">
              Modifier la photo de profil
            </p>

            <div>
              <uploadAvatar
                :updateAvatarModalIsOpen="true"
                :context="{ ID_Utilisateur: this.context.UTI_Utilisateur }"
                :noNotice="true"
                :isLogo="true"
                class="mb-2"
              />
            </div>
          </v-list-item>
          <v-list-item
            v-if="showFusion && isAdmin"
            class="d-flex align-items-center"
            @click.prevent="$emit('showMergeDuplicate')"
            ><p class="m-0 label-menu">
              Fusionner un doublon
            </p>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div>
      <div class="d-flex align-center justify-space-between my-3">
        <span class="layout-left-label">Date de naissance</span>
        <span class="layout-left-text text-left" v-text="dateNaissance" />
      </div>
      <div class="d-flex align-center justify-space-between my-3">
        <span class="layout-left-label">Nationalité</span>
        <div class="layout-left-text text-left d-flex align-center">
          <country-flag
            :country="currentUser.ADR_Pays && currentUser.ADR_Pays.CodePays"
            size="small"
          />
          <span class="layout-left-text ml-3">
            {{
              currentUser.ADR_Pays && currentUser.ADR_Pays.NomNationalite
            }}</span
          >
        </div>
      </div>
      <div class="d-flex align-center justify-space-between my-3">
        <span class="layout-left-label">Numéro de licence</span>
        <div class="layout-left-text d-flex align-center">
          <v-chip outlined color="#082b6e">
            <span v-text="licenceNumber" />
          </v-chip>
        </div>
      </div>

      <div
        v-if="currentActiveLicence"
        class="d-flex flex-column"
        style="margin: 48px 0px;"
      >
        <span class="layout-left-text-blue my-2">
          Club
        </span>

        <span
          class="layout-left-text-blue-underline"
          v-text="
            currentActiveLicence &&
            currentActiveLicence.structure &&
            (
              (currentActiveLicence.structure.idTypeStructure === 5 || currentActiveLicence.structure.idTypeStructure === 4) && currentActiveLicence.product.slug.includes('hors_club') ?
              'Licence HORS CLUB' :
              currentActiveLicence.structure.nomStructure
            )
          "
        />
      </div>
      <div
        v-if="currentActiveLicence"
        class="d-flex align-center justify-space-between mb-3"
      >
        <div class="layout-left-text-blue" style="font-size: 500 !important;">
          Informations licence
        </div>
        <div>
          <div class="layout-left-text d-flex align-center">
            <v-chip outlined color="#082b6e">
              <span
                v-text="currentActiveLicence.product.label"
              />
            </v-chip>
          </div>
        </div>
      </div>
      <div
        v-if="currentActiveLicence"
        class="d-flex align-center justify-space-between my-3"
      >
        <span class="layout-left-label"> Date de saisie</span>
        <span class="layout-left-text" v-text="dateSaisieLicence" />
      </div>
      <div
        v-if="currentActiveLicence"
        class="d-flex align-center justify-space-between my-3"
      >
        <span class="layout-left-label">Date de fin de validité</span>
        <span class="layout-left-text" v-text="dateFinLicence" />
      </div>

      <div
        v-if="currentActiveLicence && currentActiveLicence.product.slug !== 'licence_decouverte'"
        class="d-flex align-center justify-space-between my-3"
      >
        <span class="layout-left-label">Assurance</span>
        <div class="layout-left-text d-flex align-center">
          <v-chip color="#082b6e" text-color="white"
            ><span>
              {{
                assuranceType &&
                assuranceType.option.label
              }}
            </span></v-chip
          >
        </div>
      </div>

      <div
        v-if="currentActiveLicence && currentActiveLicence.product.slug !== 'licence_decouverte'"
        class="layout-left-text-blue"
        style="font-size: 500 !important; margin-top: 48px;"
      >
        Option(s) d'asssurance
      </div>

      <div class="mt-3">
        <v-chip
          outlined
          color="#082B6E"
          class="my-1 mx-1"
          v-for="(option, index) in assuranceOptions"
          :key="index"
        >
          <span class="chips-text">
            {{ option && option.option.label }}</span
          ></v-chip
        >
      </div>

      <div v-if="isAdmin">
        <div
          class="layout-left-text-blue"
          style="font-size: 500 !important; margin-top: 48px;"
        >
          Informations d'authentification
        </div>
        <div
          class="d-flex align-center justify-space-between my-3"
        >
          <span class="layout-left-label">Identifiant</span>
          <span class="layout-left-text" v-text="currentUser.LOG_Login" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import uploadAvatar from "@/components/Common/Common__UpdateAvatar.vue";

import { bus } from "@/main";
import { OPTION_TYPE_ASSURANCE, OPTION_TYPE_IJ, OPTION_TYPE_OPTION_ASSURANCE, OPTION_TYPE_OPTION_LICENCE_HC } from "@/plugins/productService";
export default {
  components: { CountryFlag, uploadAvatar },

  data: () => ({
    mounted: false,
    licences: null,
    isUpload: false,
  }),

  async created() {
    await this.setData();

    bus.$on("updateCM", async () => {
      return await this.setData();
    });

    this.mounted = true;
  },
  watch: {
    currentUser: {
      handler() {
        console.log("this.currentUser reeel :>> ", this.currentUser);
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("saisons", ["selectedSaison", "getSaison"]),
    ...mapGetters("user", ["can", "getRole"]),

    currentActiveLicence() {
      return this.licences.find(
        (lic) => lic.season.id == this.context.ID_Saison && new Date(lic.dateEnd) > new Date()
      );
    },

    currentSaison() {
      return this.getSaison(this.context.ID_Saison);
    },

    licenceNumber() {
      if (!this.currentUser.LicenceNumero || !this.currentUser.EST_Licencie)
        return "-";
      let licenceNumero = this.currentUser.LicenceNumero.toString();
      if (licenceNumero.length && licenceNumero.length <= 6) {
        return `${licenceNumero.substr(0, 3)} ${licenceNumero.substr(3, 6)}`;
      }
      return `${licenceNumero.substr(0, 1)} ${licenceNumero.substr(
        1,
        3
      )} ${licenceNumero.substr(4, 7)}`;
    },

    dateSaisieLicence() {
      if (
        this.currentActiveLicence &&
        this.currentActiveLicence.dateStart
      ) {
        return this.$moment(
          this.currentActiveLicence.dateStart
        ).format("Do MMMM YYYY");
      }
      return null;
    },

    dateFinLicence() {
      if (
        this.currentActiveLicence &&
        this.currentActiveLicence.dateEnd
      ) {
        return this.$moment(
          this.currentActiveLicence.dateEnd
        ).format("Do MMMM YYYY");
      }
      return null;
    },

    dateNaissance() {
      return this.$moment(this.currentUser.DN_DateNaissance).format(
        "Do MMMM YYYY"
      );
    },

    assuranceType() {
      if (this.currentActiveLicence) {
        return this.currentActiveLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_ASSURANCE )[0] ?? null;
      }
      return null;
    },

    assuranceOptions() {
      if (this.currentActiveLicence) {
        let options = this.currentActiveLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE);
        let optionsHC = this.currentActiveLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC);
        let complementaire = this.currentActiveLicence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_IJ);
        return [...options, ...optionsHC, ...complementaire];
      }
      return null;
    },

    isAdmin() {
      let role = this.getRole?.SlugRole;
      console.log("role ------------------", role);
      if (role === "super_administrateur") return true;
      return false;
    },
  },

  methods: {
    ...mapActions("utilisateurs", ["getUserBasicInfos", "getLicenceUtilisateur"]),

    async setData() {
      if (this.context.UTI_Utilisateur) {
        this.currentUser = this.context.UTI_Utilisateur;
      } else {
        let user = await this.getUserBasicInfos(this.context.ID_Utilisateur);
        this.currentUser = user.UTI_Utilisateurs[0];
        console.log("this.currentUser", this.currentUser);
      }
      this.licences = await this.getLicenceUtilisateur({
        user: this.currentUser.id,
        season: this.currentSaison.id,
        'groups[1]': 'licence_history:read',
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      });
    },

    openAvatarChange() {
      this.$store.commit("structure/SET_OPEN_AVATAR_MODAL", true);
    },
  },

  props: {
    context: {
      type: Object,
    },
    showFusion: {
      type: Boolean,
      default: false,
    },
    upload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.text_deco {
  color: white;
  display: flex;
  justify-content: center;
  padding-top: 2vh;
}

.bordering {
  border-bottom: 0.5px solid #e7effd;
}
.label-menu {
  font-family: "Roboto";
  font-style: normal;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.layout-left-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #000000;
}
.layout-left-label {
  width: 160px;
  height: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.layout-left-text {
  height: 24px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}
.layout-left-text-blue {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #1c3d7a;
}
.layout-left-text-blue-underline {
  font-family: Roboto;
  font-style: normal;
  // font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #082b6e;
}
.chips-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
</style>
