/* eslint-disable no-unused-vars */
import { apolloClient } from "@/vue-apollo.js";

import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
export default {
  namespaced: true,

  state: {
    allVoyages: [],
    voyageUser: [],
    voyages: [],
  },

  getters: {
    getAllVoyages: (state) => state.allVoyages,
    getVoyageUser: (state) => state.voyageUser,
    getVoyage: (state) => state.voyages,
  },

  mutations: {
    SET_VOYAGES: (state, payload) => (state.allVoyages = [...payload]),
    SET_VOYAGE_USER: (state, payload) => (state.voyageUser = [...payload]),
    SET_VOYAGE: (state, payload) => (state.voyages = [...payload]),
  },

  actions: {
    async getVoyages({ commit }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Voyage/GetAllAssurancesVoyage.gql"),
          fetchPolicy: "network-only",
        });
        commit("SET_VOYAGES", response.data.UTI_AssuranceVoyage);
        return response.data.UTI_AssuranceVoyage;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async getVoyagesFiltered({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Voyage/GetAssuranceVoyage.gql"),
          fetchPolicy: "network-only",
          variables: { whereClause: payload.whereClause },
        });
        commit("SET_VOYAGE", response.data.UTI_AssuranceVoyage);
        return response.data.UTI_AssuranceVoyage;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async getVoyagesUser({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Voyage/GetAssuranceVoyageUser.gql"),
          variables: {
            ID_Utilisateur: payload.ID_Utilisateur,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_VOYAGE_USER", response.data.UTI_AssuranceVoyageUtilisateur);
        return response.data.UTI_AssuranceVoyageUtilisateur;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async getLastNumeroVoyage({ commit }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Voyage/GetNumeroLastVoyage.gql"),

          fetchPolicy: "network-only",
        });
        return response.data.UTI_AssuranceVoyage[0];
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async createVoyageUser({ commit, getters }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Voyage/CreateUpdateAssuranceVoyage.gql"),
          variables: {
            UTI_Data: [payload],
          },
        });
        console.log(
          `response===>>`,
          response.data.insert_UTI_AssuranceVoyageUtilisateur.returning[0]
        );

        commit("SET_VOYAGE_USER", [
          ...getters.getVoyageUser,
          response.data.insert_UTI_AssuranceVoyageUtilisateur.returning[0],
        ]);
        success_notification("Déclaration de voyage enregistrée");
      } catch (error) {
        console.log("errpr==>>", error);
        error_notification("Une erreur est survenue lors de la réservation");
      }
    },
    async updateAssuranceVoyageUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Assurances/update_Assurance_Voyage_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },
  },
};
