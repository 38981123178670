/* eslint-disable no-unused-vars */
import * as user from "./hasRole";

const permissionPlugin = {
  install(Vue) {
    Vue.prototype.$can = (capability) => {
      //   console.log(store.getters['auth/user'])
      return false;
    };

    Vue.prototype.$is = (role) => {
      switch (role) {
        case "federal":
          if (user.is("federal")) return true;
          return false;

        case "licencie":
          if (user.is("licencie")) return true;
          break;

        case "structure":
          // code block
          break;

        default:
          return false;
      }
    };

    Vue.directive("can", {
      inserted: (el, bindings, vnode) => {},
    });

    Vue.directive("is", {
      inserted: (el, bindings, vnode) => {
        if (bindings.arg == "federal") {
          if (!user.is("federal")) {
            el.parentElement.removeChild(vnode.elm);
          }
        }

        if (bindings.arg == "licencie") {
          if (!user.is("licencie")) {
            el.parentElement.removeChild(vnode.elm);
          }
        }
      },
    });
  },
};

export default permissionPlugin;
