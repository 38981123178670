const route = [
  {
    path: "/licences-dematerialise/:structure_id?/:user_id?",
    name: "licence_dematerilise_inscription",
    meta: {
      public: true,
      can: false,
      title: "Prendre une licence dematerialisée",
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/modules/LicencesDematerialise/licences_dematerialise_inscription.vue"
      ),
  },
];

export default route;
