<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="close()"
    max-width="900px"
  >
    <v-tabs value="slug" background-color="blue-dark" dark grow>
      <v-tabs-slider></v-tabs-slider>
      <v-tab style="display:flex;flex-direction:column;">
        <span v-if="isLogo" style="font-size:1.2rem;">
          Changer le logo
        </span>
        <span v-else style="font-size:1.2rem;">
          Changer la photo
        </span>
      </v-tab>
      <v-icon class="close-icon" @click="close">mdi-close</v-icon>
    </v-tabs>
    <div class="modal__content" v-if="mounted">
      <v-tabs-items>
        <v-card flat>
          <Loading :loading="izUploading && showLoading" />
          <div class="p-4">
            <v-row no-gutters>
              <v-col :class="contentClasses">
                <input
                  ref="input"
                  type="file"
                  name="image"
                  accept="image/*"
                  @change="setImage"
                />

                <section class="cropper-area">
                  <div
                    v-if="hasPreviousImage"
                    :style="{ background: 'url(' + currentImageUrl + ')' }"
                    class="image-uploader__current-image"
                  >
                    <font-awesome-icon
                      icon="times"
                      class="close"
                      v-on:click="removeCurrentImage"
                    />
                  </div>

                  <div v-if="hazImage" class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="imgSrc"
                      preview=".preview"
                    />
                  </div>

                  <div class="no-image-message" v-else>
                    <span v-if="isLogo">
                      Veuillez télécharger un logo
                    </span>
                    <span v-else>
                      Veuillez télécharger une photo
                    </span>
                  </div>

                  <div class="v-card__actions">
                    <v-btn
                      color="info"
                      icon
                      large
                      :disabled="!hazImage"
                      v-if="config.action.zoom_in"
                      @click.prevent="zoom(0.2)"
                    >
                      <span class="mdi mdi-magnify-plus-outline"></span>
                    </v-btn>
                    <v-btn
                      color="info"
                      icon
                      large
                      :disabled="!hazImage"
                      v-if="config.action.zoom_out"
                      @click.prevent="zoom(-0.2)"
                    >
                      <span class="mdi mdi-magnify-minus-outline"></span>
                    </v-btn>

                    <v-spacer></v-spacer>
                    <v-btn
                      class="btn btn-primary"
                      v-if="config.action.upload_image"
                      @click.prevent="showFileChooser"
                    >
                      <span v-if="isLogo">
                        Téléverser le logo
                      </span>
                      <span v-else>
                        Téléverser la photo
                      </span>
                    </v-btn>
                  </div>
                  <v-spacer></v-spacer>
                  <textarea v-if="config.action.debug" v-model="data" />
                </section>
              </v-col>

              <v-col class="xs-12 md-5 px-4" v-if="preview">
                <section class="preview-area">
                  <div class="preview"></div>

                  <v-alert
                    v-if="noNotice"
                    border="top"
                    colored-border
                    color="orange lighten-5"
                    icon="info"
                    elevation="2"
                  >
                    La photo du licencié doit être reconnaissable.
                  </v-alert>

                  <div v-if="config.show_crop">
                    <p>Cropped Image</p>
                    <div class="cropped-image">
                      <img v-if="cropImg" :src="cropImg" alt="Cropped Image" />
                      <div v-else class="crop-placeholder" />
                    </div>
                  </div>
                </section>
              </v-col>
            </v-row>

            <v-row v-if="!autoUpload" no-gutters>
              <v-col class="xs-12 md-5">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    type="submit"
                    class="btn btn-red mt-5 mr-4"
                    @click="actionCancel"
                  >
                    Annuler
                  </v-btn>
                  <v-btn
                    :disabled="!hazImage"
                    class="btn btn-primary mt-5"
                    @click="actionUpload"
                  >
                    Enregistrer
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-tabs-items>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import Loading from "@/components/LoadingIndicator.vue";
import { error_notification } from "@/plugins/notifications.js";

export default {
  components: {
    VueCropper,
    Loading,
  },

  data() {
    return {
      imgSrc: "",
      cropImg: "",
      data: null,

      hazImage: false,

      config: {
        show_crop: false,
        action: {
          up: false,
          right: false,
          down: false,
          left: false,
          rotate_right: false,
          rotate_left: false,
          crop: false,
          flip_x: false,
          flip_y: false,
          upload_image: true,
          debug: false,
          zoom_in: true,
          zoom_out: true,
        },
      },

      filename: "",

      izUploading: false,

      hasPreviousImage: false,
      mounted: false,
    };
  },

  async created() {
    this.izUploading = this.uploading;
    this.mounted = true;
    if (this.defaultImage) {
      this.imgSrc = this.defaultImage;
      // console.log(this.imgSrc);
    }
    // if (this.currentImageUrl !== '') {
    //     this.hasPreviousImage = true
    // }
  },

  watch: {
    // currentImageUrl(newVal, oldVal) {
    //     console.log('currentimage', newVal, oldVal);
    //     if (newVal !== '' && oldVal === '') {
    //         this.hasPreviousImage = true
    //     } else {
    //         this.hasPreviousImage = false
    //     }
    //     if (this.$refs.cropper) {
    //         this.$refs.cropper.clear()
    //     }
    // },

    uploading(val) {
      if (this.izUploading && !val) {
        this.resetVars();
      }
      this.izUploading = val;
    },
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
    containerClasses() {
      let classes = "";

      if (this.izUploading) {
        classes += "__uploading";
      }

      if (!this.avatar) {
        classes += " p-0";
      }

      return classes;
    },

    contentClasses() {
      let classes = "";

      classes += this.preview ? "xs-12 md-7" : "xs-12";

      if (!this.avatar) {
        classes += " py-0";
      }

      return classes;
    },
  },

  methods: {
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      if (file.type.indexOf("image/") === -1) {
        // alert('Please select an image file');
        this.hazImage = false;
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
        this.hazImage = true;

        this.setFilename(file.name);
      } else {
        alert("Sorry, FileReader API not supported");
        this.hazImage = false;
      }
    },

    setFilename(fname) {
      this.filename = fname;
      this.$emit("fileSelected", this.filename);
    },
    removeCurrentImage() {
      this.hasPreviousImage = false;
      if (this.news) {
        this.news.image_url = "";
      }
      this.setFilename("");
    },
    resetVars() {
      this.filename = "";
      this.imgSrc = "";
      this.cropImg = "";
      this.data = null;
      this.hazImage = false;
      // needs to be done so that file select will fire an event
      this.$refs.input.value = "";
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },

    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },

    showFileChooser() {
      this.$refs.input.click();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },

    /**
     * make sure that the filename extension is .jpg as we store the images as jgp
     * @param fname
     * @returns {*}
     */
    fixFilename(fname) {
      // TODO
      return new Date().valueOf() + "_" + fname;
    },

    actionCancel() {
      this.$emit("onClose");
      // give the modal time to have its close animation before we reset the content
      setTimeout(() => {
        this.resetVars();
      }, 500);
    },

    dataURItoBlob(dataURI) {
      let binary = atob(dataURI.split(",")[1]);
      let array = [];
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      return new Blob([new Uint8Array(array)], { type: "image/png" });
    },
    dataURItoBuffer(dataURI) {
      let binary = atob(dataURI.split(",")[1]);
      return Buffer.from(binary, "binary");
    },

    async actionUpload() {
      this.izUploading = true;

      // gets the cropped area with a specific resulting image size range and compression quality

      let image_data = this.$refs.cropper
        .getCroppedCanvas({
          width: this.resultWidth,
          height: this.resultHeight,
        })
        .toDataURL("image/jpeg", this.compression);
      // console.log(this.cropImage());

      const hash =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let payload = {
        image: image_data,
        key: `${this.currentStructureId}_${hash}_${this.fixFilename(
          this.filename
        )}`,
        config: {
          S3_BUCKET: process.env.S3_BUCKET,
        },
      };
      // ancien post https://api.ffme.walkingnerds.dev/upload
      this.axios
        .post(process.env.VUE_APP_FFME_BACK_URL + "/api/media-upload", payload,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
              }
            }
        )
        .then((response) => {
          if (response.data.location) {
            let freshFile = {
              url: response.data.location,
              filename: this.filename,
            };
            this.$emit("onFileUploaded", freshFile);
            return freshFile;
          } else {
            this.izUploading = false;
            error_notification("Upload unsuccessful");
          }
        })
        .catch((error) => {
          this.izUploading = false;
          error_notification(error.toString());
          console.log(error);
        });
    },

    close() {
      this.resetVars();
      this.$emit("onClose");
    },
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    uploading: {
      type: Boolean,
      default: false,
    },
    resultWidth: {
      type: Number,
      default: 400,
    },
    resultHeight: {
      type: Number,
      default: 400,
    },
    compression: {
      type: Number,
      default: 0.88,
    },
    textImageHint: {
      type: String,
      default: "",
    },
    preview: {
      type: Boolean,
      default: true,
    },
    /**
     * If autoUpload is true, the actual image upload will be triggered by the next value, startAutoUpload
     */
    autoUpload: {
      type: Boolean,
      default: false,
    },
    startAutoUpload: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: true,
    },
    maxWidth: {
      type: Number,
      default: -1,
    },
    currentImageUrl: {
      type: String,
      default: "",
    },

    isOpen: {
      type: Boolean,
      default: false,
    },

    defaultImage: {
      type: String,
    },

    noNotice: {
      type: Boolean,
      default: true,
    },
    isLogo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.no-image-message {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  font-weight: 500;
  background: #f3f3f3;
  font-size: 1rem;
  color: grey;
}

input[type="file"] {
  display: none;
}

.preview {
  width: 200px !important;
  height: 200px !important;
  border-radius: 200px !important;
  margin: 0 auto 20px;
  background: #f3f3f3;

  img {
    border-radius: 0px !important;
  }
}

.cropper-area {
  width: 614px;
  width: 100%;
  position: relative;
}

.mdi {
  font-size: 1.5rem;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
  width: 100%;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}
</style>

<style lang="scss">
.image-uploader__current-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-size: contain !important;
  background-color: white !important;
  background-repeat: no-repeat !important;
  background-position: top right !important;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 11;
    background: red;
    color: white;
    border-radius: 30px;
    width: 27px;
    font-size: 20px;
    padding: 3px;
    height: 27px;
    cursor: pointer;
  }
}
</style>
