import store from '@/store';
import router from '@/router';
import RoutesAdhesionDematerialise from '@/router/routes/GestionDeLaStructure/Adhesion_Dematerialise';

/* eslint-disable no-unused-vars*/

const route = [
  {
    path: '/',
    name: 'gestion_des_licences__dashboard',
    meta: {
      can: false,
      title: 'Gestion des licences',
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ '@/views/GestionDesLicences/GestionDesLicences__Dashboard.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (store.getters['structure/currentStructureType'].EST_Club) {
        return next();
      }
      return router.push({ name: 'Home' });
    },
  },

  {
    path: 'saisir-une-licence',
    name: 'gestion_des_licences__saisir_une_licence',
    meta: {
      can: true,
      title: 'Saisir une licence',
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/SaisirUneLicence.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (store.getters['structure/currentStructureType'].EST_Club) {
        return next();
      }
      return router.push({ name: 'Home' });
    },
  },

  {
    path: 'renouveler-licences-en-serie',
    name: 'gestion_des_licences__renouveler_licences_en_serie',
    meta: {
      can: true,
      title: 'Renouveler les licences en série',
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/RenouvelerLicencesEnSerie.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (store.getters['structure/currentStructureType'].EST_Club) {
        return next();
      }
      return router.push({ name: 'Home' });
    },
  },
  // adhesion dematerialise
  {
    path: 'adhesion-dematerialise',
    name: 'gestion_des_licences_dematerialises',
    meta: {
      can: true,
      title: 'Gestion des adhésions dématérialisées',
    },
    component: () =>
      import(
        '@/views/GestionDeLaStructure/Affiliations/Adhesion_Dematerialise_Layout.vue'
      ),
    children: RoutesAdhesionDematerialise,
    beforeEnter: (to, from, next) => {
      if (store.getters['structure/currentStructureType'].EST_Club) {
        return next();
      }
      return router.push({ name: 'Home' });
    },
  },

  {
    path: 'licence-decouverte',
    //name: "gestion_des_licences__saisir_une_licence_decouverte",
    // meta: {
    //   "can" : false,
    //   "title": "Saisir une licence découverte"
    // },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ '@/views/layouts/GestionLicencesDecouverte__layouts.vue'
      ),
    children: [
      {
        path: 'liste-licences-delivrees',
        name: 'gestion_des_licences__liste_licences_decouverte',
        meta: {
          can: true,
          title: 'Liste des licences découverte',
        },
        component: () =>
          import(
            /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/GestionLicencesDecouverte__ListeLicenceDecouverte.vue'
          ),
      },
      {
        path: '/',
        name: 'gestion_des_licences__saisir_une_licence_decouverte',
        meta: {
          can: true,
          title: 'Saisir une licence découverte',
        },
        component: () =>
          import(
            /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/GestionLicencesDecouverte__SaisirLicenceDecouverte.vue'
          ),
      },
    ],
  },

  {
    path: 'saisir-les-licences-via-csv',
    name: 'gestion_des_licences__saisir_les_licences_via_csv',
    meta: {
      can: true,
      title: 'Saisir les licences via csv',
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/SaisirLesLicencesviaCSV.vue'
      ),
  },

  {
    path: 'gestion-licences-hors-club',
    // name: "gestion_des_animations_locale__gestion_licence_hors_club",
    //meta: "can",
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ '@/views/layouts/GestionLicencesHorsClub__layouts.vue'
      ),
    beforeEnter: (to, from, next) => {
      // if (
      //   store.getters['structure/currentStructureType'].SlugTypeStructure ===
      //   'service_federal_ffme'
      // )
      //   return next();
      return router.push({ name: 'Home' });
    },
    children: [
      {
        path: 'demandes-en-attente',
        name: 'gestion_des_animations_locale__demande_de_licence_hors_club',
        meta: {
          can: true,
          title: 'Licences hors club en attente',
        },
        component: () =>
          import(
            /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/GestionLicencesHorsClub__DemandesEnAttente.vue'
          ),
      },
      {
        path: '/',
        name: 'gestion_des_animations_locale__liste_licences_hors_club',
        meta: {
          can: true,
          title: 'Liste licences hors-club',
        },
        component: () =>
          import(
            /* webpackChunkName: "gestion_des_licences" */ '@/modules/GestionDesLicences/GestionLicencesHorsClub__ListeLicenceHorsClub.vue'
          ),
      },
    ],
  },
  {
    path: '/gestion-des-licences-siege',
    name: 'gestion_des_licences_siege__dashboard',
    meta: {
      can: false,
      title: 'Gestion des licences siège',
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences_siege" */ '@/views/GestionDesLicences/GestionDesLicencesSiege__Dashboard.vue'
      ),
  },
  // {
  //   path: "/gestion-des-licences-siege/saisir-une-licence-siege",
  //   name: "gestion_des_licences__saisir_une_licence_siege",
  //   meta: {
  //     "can" : true,
  //     "title": "Saisir une licence siège"
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gestion_des_licences_siege" */ "@/modules/GestionDesLicences/SaisirUneLicenceSiege.vue"
  //     ),
  // },
  // {
  //   path: "/gestion-des-licences-siege/liste-licence-siege",
  //   name: "gestion_des_licences__list",
  //   meta: {
  //     "can" : true,
  //     "title": "Liste des licences siège"
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gestion_des_licences_siege" */ "@/modules/GestionDesLicences/LicenceSiege__List.vue"
  //     ),
  // },
];

export default route;
