import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { error_notification } from "@/plugins/notifications.js";
import store from "@/store";
import { sleep } from "@/plugins/utils";

const CryptoJS = require("crypto-js");
const isDev = process.env.VUE_APP_ENV === "dev";
const hasura_key_decrypted = CryptoJS.AES.decrypt(
  process.env.VUE_APP_HASURA_KEY,
  "secret key 123"
);
const role_decrypt = CryptoJS.AES.decrypt(
  process.env.VUE_APP_HASURA_ROLE,
  "secret key 123"
);
const graphql_api_decrypt = CryptoJS.AES.decrypt(
  process.env.VUE_APP_GRAPHQL_API,
  "secret key 123"
);

const HASURA_KEY_PASSWORD = isDev
  ? process.env.VUE_APP_HASURA_KEY
  : hasura_key_decrypted.toString(CryptoJS.enc.Utf8);
const USER_ROLE = isDev
  ? process.env.VUE_APP_HASURA_ROLE
  : role_decrypt.toString(CryptoJS.enc.Utf8);
const VUE_APP_GRAPHQL_API = isDev
  ? process.env.VUE_APP_GRAPHQL_API
  : graphql_api_decrypt.toString(CryptoJS.enc.Utf8);
const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;
const VUE_APP_WSS = process.env.VUE_APP_WSS;
const LOCAL_HOST = process.env.VUE_APP_LOCAL_HOST;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${extensions.path}`
      );
      if (extensions.code == "invalid-jwt") {
        error_notification("Déconnexion en cours...");
        store.dispatch("app/appIsLoading", true);
        localStorage.removeItem(TOKEN_NAME);
        return redirect();
      }
    });
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
    // error_notification("Déconnexion en cours...");
  }
});

const redirect = async () => {
  await sleep(4000);
  window.location = process.env.VUE_APP_FRONT_NEXT_URL;
};

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: VUE_APP_GRAPHQL_API,
  // tonga dia atao mpointe https://back.myffme.fr/ ty zn? sa https://back.myffme.fr/v1/graphql
  // uri: "https://back-staging.myffme.fr/v1/graphql",
});
//subscription
const getHeaders = () => {
  const headers = {};
  const token = window.localStorage.getItem(TOKEN_NAME);
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return headers;
};
const subscriptionLink = new WebSocketLink({
  uri: VUE_APP_WSS,
  options: {
    reconnect: true,
    timeout: 30000,
    connectionParams: () => {
      return {
        headers: {
          Authorization:
            "Bearer " +
            localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
          "X-Hasura-Role": USER_ROLE,
          "Access-Control-Allow-Origin": LOCAL_HOST,
        },
      };
    },
  },
});
export const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  if (localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)) {
    operation.setContext({
      headers: {
        Authorization:
          "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME),
        "X-Hasura-Role": USER_ROLE,
        "Access-Control-Allow-Origin": LOCAL_HOST,
      },
    });
  } else {
    operation.setContext({
      headers: {
        "X-Hasura-Role": USER_ROLE,
        "x-hasura-admin-secret": HASURA_KEY_PASSWORD,
        "Access-Control-Allow-Origin": LOCAL_HOST,
      },
    });
  }

  return forward(operation);
});

// Create the apollo client
export const apolloClient = new ApolloClient({
  // link: concat(authMiddleware, httpLink),
  link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export const apolloWSClient = new ApolloClient({
  // link: concat(authMiddleware, subscriptionLink),
  link: ApolloLink.from([authMiddleware, subscriptionLink]),
  // link: subscriptionLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

// Install the vue plugin
Vue.use(VueApollo);

export default apolloProvider;
