import Vue from "vue";
import Vuex from "vuex";

import App from "./modules/app.js";
import Modules from "./modules/modules.js";
import Saisons from "./modules/saisons.js";
import Auth from "./modules/auth.js";
import User from "./modules/user.js";
import Basics from "./modules/basics";
import Structure from "./modules/structure.js";
import Utilisateurs from "./modules/utilisateurs.js";
import Tarifs from "./modules/tarifs.js";
import Admin from "./modules/admin.js";
import Playground from "./modules/playground.js";
import Voyage from "./modules/voyage";
import Finances from "./modules/finances";
import Licences from "./modules/licences";
import Config from "./modules/config";
import Documents from "./modules/documents";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app: App,
    modules: Modules,
    saisons: Saisons,
    auth: Auth,
    user: User,
    basics: Basics,
    structure: Structure,
    utilisateurs: Utilisateurs,
    tarifs: Tarifs,
    admin: Admin,
    playground: Playground,
    voyage: Voyage,
    finances: Finances,
    licences: Licences,
    config: Config,
    documents: Documents,
  },
});

export default store;
