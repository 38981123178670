import { apolloClient } from "@/vue-apollo";
import { getTokenUserId } from "@/plugins/auth";
import router from "@/router";

const state = {
  token: null,
  user: {},
};

const getters = {
  authenticated(state) {
    return state.token && state.user;
  },

  currentUserID(state) {
    return state.user.id;
  },

  user(state) {
    return state.user;
  },

  token(state) {
    return state.token;
  },
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_AUTH_USER(state, user) {
    state.user = user;
  },
};

const actions = {
  async attempt({ commit, state }, { token, to, from }) {
    if (token) commit("SET_TOKEN", token);

    if (!state.token) {
      localStorage.setItem('ffme-referrer', to.fullPath);

      window.location = process.env.VUE_APP_FRONT_NEXT_URL + '/authentification/deconnexion';

      return Promise.reject('Token non défini');
    }

    let pending = JSON.parse(JSON.stringify(router.history.pending));
    let params = JSON.parse(JSON.stringify(router.history.pending.query));

    delete pending.query.token;
    delete pending.query.appMode;
    delete pending.query.structureId;

    let baseUrl = Object.keys(pending.query).map((key) => key + '=' + pending.query[key]).join('&');
    let url = Object.keys(params).map((key) => key + '=' + params[key]).join('&');

    if (baseUrl !== url) {
      router.replace(pending);
    }

    try {
      let response = await apolloClient.query({
        query: require("@/graphql/Auth/Me.gql"),
        variables: {
          __ID_Utilisateur: getTokenUserId(token),
        },
      });

      if (response.data.UTI_Utilisateurs[0]) {
        let user = response.data.UTI_Utilisateurs[0];
        //history.replaceState(null, "title", "localhost:8080")

        return commit("SET_AUTH_USER", {
          id: user.id,
          username: user.LOG_Login,
          email: user.CT_Email,
        });
      } else {
        throw "Le token ne correspond à aucuns utilisateurs";
      }
    } catch (e) {
      commit("SET_TOKEN", null);
      commit("SET_USER", null);

      localStorage.setItem('ffme-referrer', to.fullPath)

      window.location = process.env.VUE_APP_FRONT_NEXT_URL + '/authentification/deconnexion';

      return Promise.reject(e);
    }
  },
};

export { state, getters, mutations, actions };
