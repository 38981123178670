import axios from "axios";

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const getHeaders = () => {
  if (localStorage.getItem(TOKEN_NAME)) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
    };
  }

  return {
    'Content-Type': 'application/json',
  };
}

export const getLicences = async (payload, count = false) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + "/api/licences?active=true",
      {
        params: payload,
        headers: getHeaders()
      }
    )
    .then((response) => {
      if (count) {
        return response.data['hydra:totalItems'];
      }

      return response.data?.['hydra:member'];
    })
    .catch((error) => {
    });
}
