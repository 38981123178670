import VueRouter from 'vue-router';

const route = [
  {
    path: 'payment-success',
    name: 'payment_success',
    meta: {
      public: true,
      can: false,
      title: 'Paiement effectué',
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ '@/views/Lemonway/LemonwaySuccess.vue'
      ),
  },

  {
    path: 'payment-error',
    name: 'payment_error',
    meta: {
      public: true,
      can: false,
      title: 'Paiement erreur',
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ '@/views/Lemonway/LemonwayError.vue'
      ),
  },

  {
    path: 'payment-cancel',
    name: 'payment_cancel',
    meta: {
      public: true,
      can: false,
      title: 'Paiement annulé',
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ '@/views/Lemonway/LemonwayCancel.vue'
      ),
  },

  {
    path: 'mercanet',
    name: 'mercanet',
    meta: {
      public: true,
      can: false,
      title: 'Paiement mercanet',
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ '@/views/Lemonway/LemonwayMercanet.vue'
      ),
  },

  {
    path: 'payment-stop',
    name: 'page_de_paiement_ffme',
    meta: {
      public: true,
      can: false,
      title: 'Page de paiement ffme',
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ '@/views/Lemonway/PagePaiementFFME.vue'
      ),
  },
];

export default route;
