import store from "@/store";

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

store.subscribe((mutation) => {
  switch (mutation.type) {
    case "auth/SET_TOKEN":
      if (mutation.payload) {
        // console.log('payload',mutation.payload)
        // Vue.axios.defaults.headers.common['Authorization'] = "Bearer" + mutation.payload
        if (process.env.VUE_APP_ENV == "dev" && localStorage.getItem(TOKEN_NAME) != mutation.payload) {
          console.log("TOKEN", mutation.payload)
        }

        localStorage.setItem(TOKEN_NAME, mutation.payload);
      } else {
        // Vue.axios.defaults.headers.common['Authorization'] = null
        localStorage.removeItem(TOKEN_NAME);
      }
      break;
  }
});
