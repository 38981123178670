/* eslint-disable no-unused-vars */

import { apolloClient } from "@/vue-apollo.js";

export default {
  namespaced: true,

  state: {
    STR_Modules: [],
  },

  getters: {
    getModules(state) {
      return state.STR_Modules;
    },

    getModulesByCategorie: (state) => (categorie_slug) => {
      return state.STR_Modules.filter((mod) => mod.STR_ModuleCategorie)
        .filter(
          (mod) => mod.STR_ModuleCategorie.SlugModuleCategorie == categorie_slug
        )
        .sort((a, b) => {
          return a.Ordre - b.Ordre;
        });
    },
  },

  mutations: {
    SET_MODULES_LIST(state, payload) {
      state.STR_Modules = payload;
    },
  },

  actions: {
    async initModules({ commit }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Modules/get_module.gql"),
      });
      commit("SET_MODULES_LIST", response.data.STR_Module);
    },
  },
};
