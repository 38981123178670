import url from "url";
import jwt_decode from "jwt-decode";

const getToken = () => {
  let href = url.parse(window.location.href);
  let tokenObject = {};
  let token = null;

  if (href.query && href.query.includes('token')) {
    href.query
      .split("&")
      .forEach(function (x) {
        var arr = x.split("=");

        if (arr[0] !== 'token') {
          return;
        }

        arr[1] && (tokenObject[arr[0]] = arr[1]);
      });

    token = tokenObject.token ?? null;
  } else {
    token = localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME) ?? null;
  }

  return token;
};

const getTokenUserId = (token) => {
  let decodedToken = jwt_decode(token);

  if (process.env.VUE_APP_ENV !== "production" && (localStorage.getItem('ffme_id_user') ?? null)) {
    return localStorage.getItem('ffme_id_user');
  }

  return decodedToken.sub;
};

export { getToken, getTokenUserId };
