import RoutesInformationDeLaStructure from "@/router/routes/GestionDeLaStructure/InformationDeLaStructure";
import RoutesAffiliations from "@/router/routes/GestionDeLaStructure/Affiliations";

const route = [
  {
    path: "/",
    name: "gestion_de_la_structure__dashboard",
    meta: {
      can: false,
      title: "Gestion de la structure",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_de_la_structure" */ "@/views/GestionDeLaStructure/GestionDeLaStructure__Dashboard.vue"
      ),
  },
  {
    path: "gestion-des-voyages",
    name: "gestion__des__déclarations__de__voyages",
    component: () =>
      import(
        /* webpackChunkName: "gestion_de_la_structure" */ "@/views/GestionDeLaStructure/Voyages/GestionVoyage.vue"
      ),
  },

  {
    path: "gestion-des-affiliations/:seasonId",
    name: "gestion__des__déclarations__des__affiliations_structure",
    meta: {
      can: false,
      title: "Informations générales",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_de_la_structure" */ "@/views/GestionDeLaStructure/Affiliations/Affiliations__Layout.vue"
      ),
    children: RoutesAffiliations,
  },

  {
    path: "informations-structure",
    component: () =>
      import(
        /* webpackChunkName: "gestion_de_la_structure" */ "@/views/GestionDeLaStructure/InformationDeLaStructure/InformationDeLaStructure__Layout.vue"
      ),
    children: RoutesInformationDeLaStructure,
  },
  {
    path: "gestion-des-documents",
    name: "information_de_la_structure__gestion_des_documents",
    meta: {
      public: false,
      title: "Ressources documentaires",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_de_la_structure" */ "@/modules/GestionDeLaStructure/InformationStructure/GestionDesDocuments.vue"
      ),
  },
  {
    path: "tableau-de-bord",
    name: "information_de_la_structure__tableau_de_bord",
    component: () =>
      import(
        /* webpackChunkName: "gestion_de_la_structure" */ "@/modules/GestionDeLaStructure/InformationStructure/TableauDeBord.vue"
      ),
  },
  {
    path: "historique-affiliation",
    name: "information__historique_affiliations",
    component: () =>
      import(
        /* webpackChunkName: "affilitation_info_general" */ "@/modules/GestionDeLaStructure/HistoriqueAffiliations/HistoriqueDesAttestations.vue"
      ),
  },
];

export default route;
