import url from "url";

const getStructureIdFromUrl = () => {
  let href = url.parse(window.location.href);
  let structureObject = {};
  let structureId = null;

  if (href.query && href.query.includes('structureId')) {
    href.query
      .split("&")
      .forEach(function (x) {
        var arr = x.split("=");

        if (arr[0] !== 'structureId') {
          return;
        }

        arr[1] && (structureObject[arr[0]] = arr[1]);
      });
    structureId = structureObject.structureId ?? null;
  } else {
    structureId = localStorage.getItem(process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME) ?? null
  }

  return structureId;
};

export { getStructureIdFromUrl };
