const route = [
  {
    path: "/",
    name: "mes_assurances__dashboard",
    meta: {
      can: false,
      title: "Mes assurances",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/views/MesAssurances/MesAssurances__Dashboard.vue"
      ),
  },

  {
    path: "ma-licence",
    name: "mes_licences__ajouter_des_options",
    meta: {
      can: true,
      title: "Ajouter des options d'assurance",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/modules/MesAssurances/AjouterDesOptions.vue"
      ),
  },

  {
    path: "voyage",
    name: "mes_assurances__declarer_un_voyage",
    meta: {
      title: "Déclarer un voyage",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/modules/MesAssurances/DeclarerVoyage.vue"
      ),
  },

  // {
  //    path: 'historique',
  //    name:'mes_licences__historique_des_licences',
  //    component: () => import(/* webpackChunkName: "MesLicences" */ '@/modules/MesLicences/HistoriqueDesLicences.vue'),
  //  },
];

export default route;
