const routes = [
  {
    path: "/",
    name: "single_structure__presentation",
    meta: {
      can: true,
      title: "Présentation de la structure",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_presentation" */ "@/modules/GestionDeLaStructure/InformationStructure/PresentationDeLaStructure.vue"
      ),
  },
  {
    path: "administration",
    name: "single_administration_information_structure",
    meta: {
      can: true,
      title: "Administration",
    },
    component: () =>
      import(
        /* webpackChunkName: "Single_administration_infos" */ "@/modules/GestionDeLaStructure/InformationStructure/AdministrationDeLaStructure.vue"
      ),
  },
  {
    path: "actualite",
    name: "single_structure__news",
    meta: {
      can: true,
      title: "Actualités",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_news" */ "@/modules/GestionDeLaStructure/InformationStructure/News.vue"
      ),
  },

  {
    path: "organigramme",
    name: "single_structure__organigramme",
    meta: {
      can: true,
      title: "Organigramme",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_organigramme" */ "@/modules/GestionDeLaStructure/InformationStructure/Organigramme.vue"
      ),
  },

  {
    path: "domiciliation",
    name: "single_structure__domiciliation",
    meta: {
      can: true,
      title: "Domiciliation",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_domiciliation" */ "@/modules/GestionDeLaStructure/InformationStructure/Domiciliation.vue"
      ),
  },

  {
    path: "nom-correspondant",
    name: "single_structure__nom_correspondant",
    meta: {
      can: true,
      title: "Nom correspondant",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_nomCorrespondant" */ "@/modules/GestionDeLaStructure/SingleStructure/NomCorrespondant.vue"
      ),
  },

  {
    path: "accueil-et-contact",
    name: "single_structure__accueil_et_contact",
    meta: {
      can: true,
      title: "Accueil et contact",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_accueil_et_contact" */ "@/modules/GestionDeLaStructure/InformationStructure/AccueilEtContact.vue"
      ),
  },

  {
    path: "reseaux-sociaux",
    name: "single_structure__reseaux_sociaux",
    meta: {
      can: true,
      title: "Réseaux sociaux",
    },
    component: () =>
      import(
        /* webpackChunkName: "SingleStructure_reseaux_sociaux" */ "@/modules/GestionDeLaStructure/InformationStructure/ReseauxSociaux.vue"
      ),
  },

  {
    path: "documents-officiels",
    name: "single_structure__documents_officiels",
    meta: {
      can: true,
      title: "Documents officiels",
    },
    component: () =>
      import(
        /* webpackChunkName: "Single_structure__documents_officiels" */ "@/modules/GestionDeLaStructure/InformationStructure/DocumentsOfficiels.vue"
      ),
  },

  {
    path: "finances",
    name: "single_structure__finances",
    meta: {
      can: true,
      title: "Finances",
    },
    component: () =>
      import(
        /* webpackChunkName: "Single_structure__finances" */ "@/modules/GestionDeLaStructure/InformationStructure/Gestion_modality_Rib.vue"
      ),
  },

  {
    path: "gestion-salaries",
    name: "single_structure__salaries",
    meta: {
      can: true,
      title: "Gestion des salariés",
    },
    component: () =>
      import(
        /* webpackChunkName: "Single_structure__salaries" */ "@/modules/GestionDeLaStructure/InformationStructure/GestionDesSalaries.vue"
      ),
  },
];

export default routes;
