import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
//import './registerServiceWorker'
import axios from "axios";
import VueAxios from "vue-axios";
import apolloProvider from "./vue-apollo";
import "./assets/scss/app.scss";
// import i18n from '@/plugins/i18n'
import vuetify from "@/plugins/vuetify"; // path to vuetify export
import Toast, { POSITION } from "vue-toastification";
import "material-design-icons-iconfont/dist/material-design-icons.css";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinner,
  faHome,
  faBookmark,
  faTrophy,
  faGraduationCap,
  faAddressCard,
  faPaste,
  faMapSigns,
  faFileAlt,
  faUpload,
  faChartBar,
  faEye,
  faDownload,
  faCalendarAlt,
  faCog,
  faTimesCircle,
  faImage,
  faSync,
  faUser,
  faEnvelope,
  faFolderOpen,
  faPlus,
  faTimes,
  faExclamationTriangle,
  faCheck,
  faQuestion,
  faTrashAlt,
  faGlobe,
  faCircleNotch,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import VueMoment from "vue-moment";
import moment from "moment-timezone";

import RolesPermissionsPolicies from "@/plugins/RolesPermissionsPolicies";
// import Vuelidate from "vuelidate";
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.use(RolesPermissionsPolicies);
// Vue.use(Vuelidate);

// TOAST NOTIF
const ToastOption = {
  // You can set your default options here
  position: POSITION.BOTTOM_RIGHT,
  draggable: false,
};
Vue.use(Toast, ToastOption);
// END TOAST NOTIF

// MOMENT
Vue.use(VueMoment, {
  moment,
});
Vue.moment.locale("fr");
// END MOMENT

// FA
// fontawesome (could just import fas which includes ALL icons, but might be a bit big...
library.add(
  faSpinner,
  faHome,
  faBookmark,
  faTrophy,
  faGraduationCap,
  faAddressCard,
  faPaste,
  faMapSigns,
  faFileAlt,
  faUpload,
  faChartBar,
  faEye,
  faDownload,
  faCalendarAlt,
  faCog,
  faTimesCircle,
  faImage,
  faSync,
  faUser,
  faEnvelope,
  faFolderOpen,
  faFacebook,
  faTwitter,
  faInstagram,
  faWhatsapp,
  faPlus,
  faTimes,
  faExclamationTriangle,
  faCheck,
  faQuestion,
  faTrashAlt,
  faLinkedin,
  faGlobe,
  faCircleNotch,
  faCamera
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
// END FA

Vue.config.productionTip = false;

// AXIOS
Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
// END AXIOS

// Add validatioon Rules
require("@/config/validationRules");

// Check if we have a token
require("@/store/modules/authSubscriber");

export const bus = new Vue();

// Global event bus
new Vue({
  store,
  router,
  // i18n,
  apolloProvider,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
