/* eslint-disable no-unused-vars */
import { apolloClient } from "@/vue-apollo.js";
export default {
  namespaced: true,

  state: {
    currentAssurance: [],
    isPrintingAttestationLicence: false,
    isPrintingLicence: false,
  },

  getters: {},

  mutations: {
    SET_CURRENT_ASSURANCES: (state, payload) =>
      (state.currentAssurance = [...payload]),
    SET_IS_PRINTING_ATTESTATION_LICENCE: (state, payload) =>
      (state.isPrintingAttestationLicence = payload),
    SET_IS_PRINTING_LICENCE: (state, payload) =>
      (state.isPrintingLicence = payload),
  },

  actions: {
    async getPlanComptableFiltred({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Finances/GetPlanComptable.gql"),
          fetchPolicy: "network-only",
          variables: { whereClause: payload.whereClause },
        });
        commit("SET_PLAN_COMPTABLE", response.data.FIN_PlanComptable);
        return response.data.FIN_PlanComptable;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
  },
};
