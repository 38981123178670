/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import { apolloClient } from "@/vue-apollo";

const state = {};

const getters = {};

const mutations = {
  SET_RESEAUX_SOCIAUX(state, payload) {
    state.structures[payload.index].DOC_Url = payload.data;
  },

  INSERT_STRUCTURE_URL(state, payload) {
    state.structures[payload.index].DOC_Url.push(payload.data);
  },

  UPDATE_STRUCTURE_URL(state, payload) {
    let doc = state.structures[payload.index].DOC_Url.find(
      (doc) => doc.id == payload.data.id
    );
    let indexDoc = state.structures[payload.index].DOC_Url.indexOf(doc);
    state.structures[payload.index].DOC_Url[indexDoc] = payload.data;
  },
};

const actions = {
  async getReseauxUrl({ commit, getters }) {
    if (getters.currentStructure.DOC_Url) {
      return getters.currentStructure.DOC_Url;
    }

    let response = await apolloClient.query({
      query: require("@/graphql/Url/get_url_structure_reseaux_sociaux.gql"),
      variables: {
        strucuture_ID: getters.currentStructureId,
      },
    });

    commit("SET_RESEAUX_SOCIAUX", {
      index: getters.currentIndex,
      data: response.data.DOC_Url,
    });
    return getters.currentStructure.DOC_Url;
  },
  async getReseauxUrlFromStrID({} ,id) {
    console.log(id)
    let response = await apolloClient.query({
      query: require("@/graphql/Url/get_url_structure_reseaux_sociaux.gql"),
      variables: {
        strucuture_ID: id,
      },
    });
    return response.data.DOC_Url;
  },

  async updateStructureUrl({ commit, getters }, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Url/update_url.gql"),
      variables: {
        Url_id: payload.id,
        NomUrl: payload.NomUrl,
        Url: payload.Url,
      },
    });
    let data = response.data.update_DOC_Url.returning;
    if (data[0]) {
      commit("UPDATE_STRUCTURE_URL", {
        index: getters.currentIndex,
        data: data[0],
      });
      return data[0];
    }
  },

  async insertStructureUrl({ commit, getters }, payload) {
    // console.log("payload", payload);
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Url/insert_url_structure.gql"),
      variables: {
        EST_NouvelleFenetre: true,
        EST_Valide: true,
        EST_ValideParUtilisateur: true,
        ID_Structure: getters.currentStructureId,
        ID_TypeUrl: payload.type_id,
        NomUrl: payload.NomUrl,
        Url: payload.Url,
      },
    });
    let data = response.data.insert_DOC_Url.returning;

    if (data[0]) {
      commit("INSERT_STRUCTURE_URL", {
        index: getters.currentIndex,
        data: data[0],
      });
      return data[0];
    }
  },

  async deleteUrl({}, payload) {
    let mutation = {
      mutation: require("@/graphql/Url/delete_url.gql"),
      variables: payload,
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        // console.log(response);
      })
      .catch((e) => {
        console.log(e);
      });
  },
};

export { state, getters, mutations, actions };
