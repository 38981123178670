<template>
  <div v-if="loading" :class="{ ffme__loading: true, __fixed: fixed }">
    <div v-if="progress === false">
      <div v-if="title">
        <div class="__font-600">
          {{ title }}
        </div>
      </div>

      <v-progress-circular
        :size="70"
        :width="7"
        color="white"
        indeterminate
      ></v-progress-circular>
    </div>

    <div v-else class="loading__progress-container">
      <div v-if="title">
        <div class="__font-600">
          {{ title }}
        </div>
      </div>

      <v-progress-linear
        :background-opacity="0.3"
        :height="12"
        :indeterminate="progress === 0 || progress === 100"
        :top="top"
        :value="progress"
        color="#0045ab"
      ></v-progress-linear>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingIndicator",

  components: {},

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: [Number, Boolean],
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    top: {
      type: [Number, String, Boolean],
      default: true,
    },
  },

  data() {
    return {};
  },
};
</script>
<style lang="scss">
.ffme__loading {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.37);
  justify-content: center;
  align-items: center;
  z-index: 10;

  .svg-inline__fa {
    width: 2em;
    height: auto;
    color: white;
  }

  &.__fixed {
    position: fixed;
  }
}
.loading__progress-container {
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 12px;

  .v-progress-linear {
    margin: 10px 0;
    min-width: 320px;

    @media (max-width: 600px) {
      min-width: 220px;
    }
  }
}
</style>
