import store from "@/store";
import router from "@/router";
import RoutesKyc from "@/router/routes/GestionDesFinances/LemonwayKyc";
const route = [
  {
    path: "/",
    name: "gestion_comptabilite__dashboard",
    meta: {
      can: false,
      title: "Gestion comptabilité",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_comptabilite" */ "@/views/GestionComptabilite/GestionComptabilite__Dashboard.vue"
      ),
  },
  {
    path: "rechercher-flux-financiers",
    name: "gestion_comptabilite__rechercher_flux_financiers",
    meta: {
      can: false,
      title: "Rechercher les flux financiers",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_finances" */ "@/modules/GestionDesFinances/RechercherFluxFinanciers.vue"
      ),
  },
  {
    path: "gestion-des-factures",
    name: "gestion_comptabilite__gestion_des_factures",
    meta: {
      can: true,
      title: "Gestion des factures",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/GestionDesFactures.vue"
      ),
  },
  {
    path: "gestion-des-factures/:id",
    name: "gestion_comptabilite__detail_facture",
    meta: {
      can: true,
      title: "Detail facture",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/DetailFactures.vue"
      ),
  },

  {
    path: "sage",
    name: "gestion_comptabilite__gestion_sage",
    meta: {
      can: true,
      title: "SAGE",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/GestionSage.vue"
      ),
  },

  {
    path: "sage",
    name: "gestion_comptabilite__tableau_de_controle",
    meta: {
      can: true,
      title: "Tableau de contrôle",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/TableauDeControle.vue"
      ),
  },
  {
    path: "kyc-old",
    name: "gestion_comptabilite__lemonway_kyc",
    meta: {
      can: false,
      title: "Gestion de compte lemonway",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/GestionKyc.vue"
      ),
    children: RoutesKyc,
  },
  {
    path: "kyc",
    name: "gestion_lemonway_verification_kyc",
    meta: {
      can: false,
      title: "Gestion de compte lemonway",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/GestionVerificationKyc.vue"
      ),
    // children: RoutesKyc,
  },
  {
    path: "place-marche",
    name: "gestion_lemonway_place_marche_kyc",
    meta: {
      can: false,
      title: "PLACE DE MARCHÉ",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/GestionPlaceMarcheKyc.vue"
      ),
  },
  // {
  //   path: 'rib',
  //   name: 'gestion_comptabilite__structure_rib',
  //   meta: {
  //     can: false,
  //     title: "Gestion type de règlement et RIB d'une structure",
  //   },
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "gestion_des_competitions" */ '@/modules/GestionComptabilite/Gestion_modality_Rib.vue'
  //     ),
  //   children: RoutesKyc,
  // },
  {
    path: "recapitulatif",
    name: "gestion_comptabilite__structure_recapitulatif",
    meta: {
      can: false,
      title: "Récapitulatif mensuel",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionComptabilite/Recapitulatif_mensuel.vue"
      ),
    children: RoutesKyc,
  },
];

export default route;
