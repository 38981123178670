import url from "url";

const getAppModeFromUrl = () => {
  let href = url.parse(window.location.href);
  let appModeObject = {};
  let appMode = null;

  if (href.query && href.query.includes('appMode')) {
    href.query
      .split("&")
      .forEach(function (x) {
        var arr = x.split("=");

        if (arr[0] !== 'appMode') {
          return;
        }

        arr[1] && (appModeObject[arr[0]] = arr[1]);
      });
    appMode = appModeObject.appMode ?? null;
  } else {
    appMode = localStorage.getItem(process.env.VUE_APP_CURRENT_USER_MODE) ?? null
  }

  return appMode;
};

export { getAppModeFromUrl };
