import Vue from "vue";
// import i18n from '@/plugins/i18n'
import Vuetify from "vuetify/lib";
import en from "vuetify/es5/locale/en";
import fr from "vuetify/es5/locale/fr";

Vue.use(Vuetify);

const preset = {
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xs: 600,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  icons: {
    iconfont: "mdi",
    values: {},
  },
  rtl: false,
  theme: {
    dark: false,
    default: "light",
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: undefined,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        "blue-primary": "#00aded",
        "blue-second": "#0646a5",
        "blue-dark": "#002755",
        "gray-primary": "#303750",
        "gray-dark": "#1f263e",
        "red-dark": "#ad190d",
        accent: "#82B1FF",
        error: "#ad190d",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
        white: "#fff",
      },
      dark: {
        primary: "#2196F3",
        secondary: "#424242",
        accent: "#FF4081",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
      },
    },
  },
  // lang: {
  //   t: (key, ...params) => i18n.t(key, params),
  // },
  lang: {
    locales: { en, fr },
    current: "fr",
  },
};

export default new Vuetify({
  // The provided global preset is first merged with defaults
  preset,
});
