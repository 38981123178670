import { apolloClient } from "@/vue-apollo";
import { apolloWSClient } from "@/vue-apollo";

const mutations = {
  SET_FFME_NEWS(state, payload) {
    state.ffme.STR_News = payload;
  },

  SET_STRUCTURE_NEWS(state, payload) {
    state.structures[payload.index].STR_News = payload.data;
  },

  INSERT_STRUCTURE_NEWS(state, payload) {
    state.structures[payload.index].STR_News.push(payload.data);
  },

  INSERT_STRUCTURE_NEWS_FFME(state, payload) {
    state.ffme.STR_News.push(payload);
  },
};

const actions = {
  async getListTypeNews(ctx) {
    let response = await apolloClient.query({
      query: require("@/graphql/News/get_List_newsType.gql"),
    });

    return response.data.LIST_NewsType;
  },

  async getFFMENews(ctx, { ID_TypeNews }) {
    let response = await apolloClient
      .query({
        query: require("@/graphql/News/get_ffme_news_list.gql"),
        variables: {
          ID_TypeNews,
        },
      })
      .then((cache) => {
        const data = cache.readQuery({
          query: require("@/graphql/News/get_ffme_news_list.gql"),
          variables: {
            ID_TypeNews,
          },
        });
      });

    // let variables = null;
    // let response = await apolloWSClient
    //   .subscribe({
    //     query: require("@/graphql/News/get_ffme_news.gql"),
    //     variables: {
    //       ID_TypeNews,
    //     },
    //   })
    //   .subscribe({
    //     async next(data) {
    //       console.log("graphql data real==>>", data);
    //       // if (data.data.todos.length) {
    //       //   // check if the received todo is already present
    //       //   if (data.data.todos[0].id !== that.todos[0].id) {
    //       //     that.newTodosCount = that.newTodosCount + data.data.todos.length;
    //       //   }
    //       // }
    //       return await (variables = data.STR_News);
    //     },
    //     error(err) {
    //       console.error("err", err);
    //     },
    //   });
    // // let response = await apolloWSClient.subscribe({
    // //   query: require("@/graphql/News/get_ffme_news.gql"),
    // //   variables: {
    // //     ID_TypeNews,
    // //   },
    // // });
    return response.data.STR_News;
  },
  async getNewsByStructureID(ctx, { ID_Structure }) {
    let response = await apolloClient.query({
      query: require("@/graphql/News/get_news_by_structure_id.gql"),
      fetchPolicy: "network-only",
      variables: {
        ID_Structure,
      },
    });

    return response.data.STR_News;
  },

  async updateNewsImg(ctx, payload) {
    let mutation = {
      mutation: require("@/graphql/Documents/insert_document2.gql"),
      variables: { document: payload },
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async updateStructureNews(ctx, { news }) {
    // console.log('news:', news)
    let mutation = {
      mutation: require("@/graphql/News/update_news.gql"),
      variables: {
        news: news,
      },
      update: (store, { data }) => {
        if (data.insert_STR_News.affected_rows) {
          let query = {
            query: require("@/graphql/News/get_news_by_structure_id.gql"),
            variables: {
              ID_Structure: news.ID_Structure,
            },
          };
          let newsIndex = null;
          const dataRead = store.readQuery(query);
          dataRead.STR_News.forEach((dataNews, index) => {
            if (dataNews.id === news.id) newsIndex = index;
          });
          if (newsIndex)
            dataRead.STR_News[newsIndex] = {
              ...data.insert_STR_News.returning[0],
            };
          else dataRead.STR_News.push(data.insert_STR_News.returning[0]);
          store.writeQuery({ ...query, data: dataRead });
        }
      },
    };
    let response = await apolloClient.mutate(mutation);
    // console.log('response:', response)

    let data = response.data.insert_STR_News.returning;
    return data[0];
  },

  async deleteStructureNews(ctx, payload) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/News/delete_news.gql"),
      variables: {
        ID_News: payload.news.id,
      },
      update: (store, { data }) => {
        if (data.delete_STR_News.affected_rows) {
          let query = {
            query: require("@/graphql/News/get_news_by_structure_id.gql"),
            variables: {
              ID_Structure: payload.ID_Structure,
            },
          };
          let newsIndex = null;
          const dataRead = store.readQuery(query);
          dataRead.STR_News.forEach((news, index) => {
            if (news.id === payload.news.id) newsIndex = index;
          });
          if (typeof newsIndex === "number")
            dataRead.STR_News.splice(newsIndex, 1);
          store.writeQuery({ ...query, data: dataRead });
        }
      },
    });
    let data = response.data.delete_STR_News.returning;
    return data[0];
  },
};

export { actions, mutations };
