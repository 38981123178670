const routes = [
  {
    path: "/",
    name: "affiliations__informations_generales",
    meta: {
      can: true,
      title: "Informations générales",
    },
    // meta: 'can',
    component: () =>
      import(
        /* webpackChunkName: "affilitation_info_general" */ "@/modules/GestionDeLaStructure/Affiliations/InformationGenerales.vue"
      ),
  },

  {
    path: "activites-du-club",
    name: "affiliations__activites_du_club",
    meta: {
      can: true,
      title: "Activités du club",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_activite_club" */ "@/modules/GestionDeLaStructure/Affiliations/ActivitesDuClub.vue"
      ),
  },

  {
    path: "documents-a-joindre",
    name: "affiliations__documents_a_joindre",
    meta: {
      can: true,
      title: "Documents à joindre",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_documents_a_joindre" */ "@/modules/GestionDeLaStructure/Affiliations/DocumentsAJoindre.vue"
      ),
  },

  {
    path: "gestion-salaries",
    name: "affiliations__gestion_des_salaries",
    meta: {
      can: true,
      title: "Gestion des salariés",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_gestion_salaries" */ "@/modules/GestionDeLaStructure/Affiliations/GestionDesSalaries.vue"
      ),
  },
  {
    path: "tableau-de-synthese",
    name: "affiliations__tableau_de_synthese",
    meta: {
      can: true,
      title: "Tableau de synthèse",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_tableau_synthese" */ "@/modules/GestionDeLaStructure/Affiliations/TableauDeSynthese.vue"
      ),
  },
];

export default routes;
