/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import { apolloClient } from "@/vue-apollo";
import { isEmpty, sleep } from "@/plugins/utils";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";

import router from "@/router";
// import router from "@/router"
import * as news from "@/store/modules/Structure/News.js";
import * as utilisateurs from "@/store/modules/Structure/Utilisateurs.js";
import * as adresses from "@/store/modules/Structure/Adresses.js";
import * as affiliations from "@/store/modules/Structure/Affiliations.js";
import * as informations from "@/store/modules/Structure/Informations.js";
import * as url from "@/store/modules/Structure/Url.js";

export default {
  namespaced: true,

  state: {
    currentStructureId: null,
    structures: null,
    ffme: {},
    listCreneaux: null,
    groupeCreneaux: null,
    listHoraires: null,
    initalCheckbox: true,
    MAaffiliation: {},
    moneyOuts: [],
    moneyOutsIban: null,
    moneyOutsTab: null,
    openMoneyOuts: null,
    openAvatarModalChange: null,
    userWithFunctionInStructure: [],
    userWithFunctionSelected: [],
    structureCategorieList: [],
    structureList: [],
    activeListSelected: null,
    userSelectedInList: null,
    structureSubscriptionIdFuse: null,
    listOfStructure: [],
  },

  getters: {
    //
    getInitialCheckbox(state) {
      return state.initalCheckbox;
    },
    //get horaires
    getListHoraires(state) {
      return state.listHoraires;
    },
    //groupeCreneaux

    getGroupeCreneaux(state) {
      return state.groupeCreneaux;
    },
    // creneaux
    getListCreneaux(state) {
      return state.listCreneaux;
    },
    getFFME(state) {
      return state.ffme;
    },

    // get module available for the current structure
    getCurrentModulesAvailable(state, getters) {
      return getters.currentStructure.ADR_DroitsType;
    },
    // get module available for the current structure
    getCurrentRoleOnStructure(state, getters) {
      return getters.currentStructure.ADR_RoleType;
    },

    // list of struture whre is user is linked
    structures: (state) => state.structures,

    // just if we have a role fede
    hasRoleFederal(state) {
      // console.log(state.structures)
      return state.structures.filter((structure) => structure.EST_RoleFederal);
    },

    // get ones with role federal
    withRoleFederal(state) {
      return state.structures.filter((structure) => structure.EST_RoleFederal);
    },

    // get structure where user has EST_Actif flag
    userIsactiveOn(state) {
      return state.structures.filter((structure) => structure.EST_Actif);
    },

    // get the current strucutre id
    currentStructureId(state) {
      return state.currentStructureId;
    },

    currentStructureType(state, getters) {
      return getters.currentStructure.STR_Structure.LIST_StructureType;
    },

    // gte inddex of the current structure
    currentIndex(state, getters) {
      return state.structures.indexOf(getters.currentStructure);
    },

    // get inde of a structure by id
    structureIndex: (state) => (structure_id) => {
      let structure = state.structures.find(
        (str) => str.ID_Structure == structure_id
      );
      return state.structures.indexOf(structure);
    },

    // get a strucutre by id
    getStructure: (state) => (structure_id) => {
      return state.structures.find((str) => str.ID_Structure == structure_id);
    },

    // bget the currnt strucutre
    currentStructure(state) {
      return state.structures.filter(
        (structure) => structure.ID_Structure === state.currentStructureId
      )[0];
    },

    hasCodeFederal(state) {
      return !!state.structures.filter(
        (structure) => structure.ID_Structure === state.currentStructureId
      )[0]?.STR_Structure?.ID_CodeFederal;
    },
    // get the tthe strucutre whre the current user is licencie for the user_mode  == licencie
    getStructureLicencie(state) {
      return state.structures.find(
        (structure) => structure.EST_StructureLicencie
      );
    },

    // get avatar of the current strucutre
    currentStructureAvatar(state, getters, rootState) {
      if (!getters.currentStructure) return "";
      let categorie_id = rootState.basics.LIST.LIST_DocumentType.find(
        (doc) => doc.SlugTypeDocument == "avatar_structure"
      ).id;
      return getters.currentStructure.STR_Structure.DOC_Documents.filter(
        (doc) => doc.ID_Type_Document === categorie_id
      )[0];
    },

    // Check if first connection for structure utilisateur
    isFirstConnection(state) {
      return state.structures.filter(
        (structure) => structure.ID_Structure === state.currentStructureId
      )[0].EST_PremiereConnexion;
    },
  },

  mutations: {
    SET_LIST_OF_STRUCTURE(state, payload) {
      state.listOfStructure = [...payload];
    },
    SET_MONEY_OUTS(state, payload) {
      state.moneyOuts = payload;
    },
    SET_MONEY_OUTS_TAB(state, payload) {
      state.moneyOutsTab = payload;
    },
    SET_MONEY_OUTS_IBAN(state, payload) {
      state.moneyOutsIban = payload;
    },
    SET_OPEN_MONEY_OUTS(state, payload) {
      state.openMoneyOuts = payload;
    },
    SET_OPEN_AVATAR_MODAL(state, payload) {
      state.openAvatarModalChange = payload;
    },

    SET_USER_ACTIVE_IN_LIST: (state, payload) => {
      state.userSelectedInList = payload;
    },
    // id fuse
    SET_ACTIVE_LIST_STRUCTURE: (state, payload) => {
      state.activeListSelected = payload;
    },
    SET_STRUCTURE_CATEGORIE_LIST: (state, payload) => {
      state.structureCategorieList = payload;
    },
    SET_STRUCTURE_SUBSCRIPTION_ID_FUSE: (state, payload) => {
      state.structureSubscriptionIdFuse = [...payload];
    },
    SET_STRUCTURE_LIST: (state, payload) => {
      state.structureList = [...payload];
    },
    SET_NEW_LIST_PARTICIPANT: (state, { ID_UtilisateurListe, ID_Liste }) => {
      const listeToUpdate = state.structureList.find(
        (liste) => liste.id === ID_Liste
      );
      state.structureList
        .find((liste) => liste.id === ID_Liste)
        ?.STR_StructureListeUtilisateurs.splice(
          listeToUpdate.STR_StructureListeUtilisateurs.findIndex(
            (user) => user.id === ID_UtilisateurListe
          ),
          1
        );
    },
    SET_SELECTED_USER_WITH_FUNCTION: (state, payload) => {
      state.userWithFunctionSelected = [
        ...state.userWithFunctionSelected,
        ...payload,
      ];
    },

    SET_USER_WITH_FUNCTION_STRUCTURE: (state, payload) => {
      state.userWithFunctionInStructure = [...payload];
    },
    //MA Affiliation

    SET_MA_AFFILIATION(state, payload) {
      state.MAaffiliation = { ...state.MAaffiliation, ...payload };
    },
    //basics
    SET_INITIAL_CHECKBOX(state, payload) {
      state.initalCheckbox = payload;
    },
    //horaire
    SET_GROUPE_HORAIRES(state, payload) {
      state.listHoraires = [...payload];
    },
    SET_GROUPE_CRENEAUX_UPDATE(state, { groupeCreneauID, status, userID }) {
      const UtilisateursTemp = state.groupeCreneaux.find(
        (groupe) => groupe.id === groupeCreneauID
      );
      let newValue = UtilisateursTemp?.STR_GroupeSaisonCreneau[0]?.UTI_UtilisateursTemps?.find(
        (user) => user.id === userID
      );
      newValue = { ...newValue, Status: status };
      state.groupeCreneaux
        .find((groupe) => groupe.id === groupeCreneauID)
        .STR_GroupeSaisonCreneau[0]?.UTI_UtilisateursTemps?.splice(
          UtilisateursTemp?.STR_GroupeSaisonCreneau[0]?.UTI_UtilisateursTemps.findIndex(
            (user) => user.id === userID
          ),
          1,
          newValue
        );
    },
    //groupe creneau

    SET_GROUPE_CRENEAUX(state, payload) {
      state.groupeCreneaux = [...payload];
    },
    // creneaux

    SET_LIST_CRENEAUX(state, payload) {
      state.listCreneaux = [...payload];
    },
    SET_STRUCTURES(state, payload) {
      // console.log('SET_STRUCTURES', payload)
      state.structures = payload;
    },

    SET_CURRENT_STRUCTURE_ID(state, payload) {
      //console.log('SET_CURRENT_STRUCTURE', payload)
      // SET IT IN LOCAL STORAGE FOR NEXT RELOADING
      state.currentStructureId = payload;
    },

    SET_INIT_STRUCTURE_INFO(state, payload) {
      state.structures[payload.index].STR_Structure = payload.data;
    },

    SET_INIT_STRUCTURE_MODULES(state, payload) {
      // console.log('SET_MODULES_FOR_STRCUTURE:', payload.data)
      state.structures[payload.index].ADR_DroitsType = payload.data;
    },

    SET_INIT_STRUCTURE_ROLE(state, payload) {
      console.log("here", { ...state.structures[payload.index] });
      // console.log('SET_ROLE_FOR_STRCUTURE:', payload.data)
      state.structures[payload.index].ADR_RoleType = payload.data;
    },

    INSERT_DOCUMENT(state, document) {
      // console.log('structure - INSERT_DOCUMENT', state, document);
      if (!document.ID_Structure) {
        return;
      }
      // get structure this document belongs to
      let targetStructure = state.structures.filter(
        (structure) => structure.ID_Structure === document.ID_Structure
      )[0];
      // check if DOC_Documents exists, and if so, if we already have the current doc saved. if so, remove it
      if (targetStructure.DOC_Documents) {
        // delete any existing doc with the same id
        targetStructure.DOC_Documents = targetStructure.DOC_Documents.filter(
          (doc) => doc.id !== document.id
        );
      } else {
        // not initialized yet
        targetStructure.DOC_Documents = [];
      }
      // add the new doc
      targetStructure.DOC_Documents.push(document);
    },

    ...informations.mutations,
    ...utilisateurs.mutations,
    ...adresses.mutations,
    ...affiliations.mutations,
    ...news.mutations,
    ...url.mutations,
  },

  actions: {
    //deleteDemande licence
    async deleteDemandeLicence(_, payload) {
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Affiliations/deleteDemandeLicence.gql"),
          variables: {
            id: payload.UTI_UtilisateursTemp.id,
          },
        });
        success_notification("Suppression de la demande effectuée avec succès");
      } catch (error) {
        error_notification("Erreur de suppression de la demande");
      }
    },

    // update or refeuse licence demande
    async updateDemandeLicence(
      { commit },
      { UTI_UtilisateursTemp, status, groupeCreneauID }
    ) {
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Affiliations/updateDemandeLicence.gql"),
          variables: {
            id: UTI_UtilisateursTemp.id,
            status,
          },
        });
        commit("SET_GROUPE_CRENEAUX_UPDATE", {
          groupeCreneauID,
          status,
          userID: UTI_UtilisateursTemp.id,
        });
        success_notification(
          status === 1
            ? "Adhésion validée, demande de paiement envoyée"
            : "Adhésion refusée"
        );
      } catch (error) {
        error_notification(
          "Oups, une erreur est survenure! Veuillez réessayer plus tard."
        );
      }
    },
    // get usert with fonction in structure
    async getUserWhoHasFunctionInStructure({ commit }, { ID_Structure }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Structures/get_user_with_fonction.gql"),
          variables: {
            ID_Structure,
          },
          fetchPolicy: "network-only",
        });
        commit(
          "SET_USER_WITH_FUNCTION_STRUCTURE",
          response.data?.STR_StructureUtilisateur
        );
        return response.data.STR_StructureUtilisateur;
      } catch (error) {
        error_notification(error.message);
      }
    },
    // insert and create new List
    async createUpdateNewListStructure({ commit, state }, { liste }) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/UserList/createUpdateListUser.gql"),
          variables: {
            liste,
          },
        });
        if (liste.id) {
          let newListe = [...state.structureList];
          newListe.splice(
            state.structureList.findIndex((list) => list.id === liste.id),
            1
          );
          commit("SET_STRUCTURE_LIST", newListe);
          success_notification("Liste supprime avec succès");
        } else {
          commit("SET_STRUCTURE_LIST", [
            ...state.structureList,
            response.data.insert_STR_StructureListe.returning[0],
          ]);
          success_notification("Liste ajoute avec succès");
        }
      } catch (error) {
        console.log("error :>> ", error);
        error_notification("Erreur creation de liste");
      }
    },
    // get all structure liste
    async getStructureListeBySeason({ commit }, { ID_Structure, ID_Saison }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/UserList/getStructureListe.gql"),
          variables: {
            ID_Structure,
            ID_Saison,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_STRUCTURE_LIST", response.data.STR_StructureListe);
      } catch (error) {
        error_notification("Oups! Une erreur est survenue!");
      }
    },
    // create
    async updateCreateSubcription({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/UserList/createUpdateSubscriptionToIDFuse.gql"),
          variables: {
            data: payload,
          },
        });
        commit(
          "SET_STRUCTURE_SUBSCRIPTION_ID_FUSE",
          response.data.insert_STR_StructureIdFuse.returning
        );
        success_notification(
          payload?.id
            ? "Abonnement annule avec succès"
            : "Abonnement ajoute avec succès!"
        );
      } catch (error) {
        error_notification("Oups! Une erreur est survenue");
      }
    },

    // get if structure has a subscription to ID fuse
    async getStructureSubscription({ commit }, { ID_Structure }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/UserList/getStructureAbonnementIDFuse.gql"),
          variables: {
            ID_Structure,
          },
          fetchPolicy: "network-only",
        });
        commit(
          "SET_STRUCTURE_SUBSCRIPTION_ID_FUSE",
          response.data.STR_StructureIdFuse
        );
      } catch (error) {
        error_notification("Oups! Une erreur est survenue!");
      }
    },

    // removeUser from structureListe
    async removeUserFromStructureListe(
      { commit },
      { ID_Utilisateur, ID_Liste, ID_UtilisateurListe }
    ) {
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/UserList/removeUserFromStructureListe.gql"),
          variables: {
            ID_Utilisateur,
            ID_Liste,
          },
        });
        commit("SET_NEW_LIST_PARTICIPANT", { ID_UtilisateurListe, ID_Liste });
        success_notification("Utilisateur supprimé de la liste succès!");
      } catch (error) {
        error_notification("Erreur de suppression!");
      }
    },

    // create new categorie for list
    async createUpdateNewStructureListeCategorie({ commit, state }, { data }) {
      try {
        let response = await apolloClient.mutate({
          mutation: require("@/graphql/UserList/createNewStructureListeCategorie.gql"),
          variables: {
            data,
          },
        });
        commit("SET_STRUCTURE_CATEGORIE_LIST", [
          ...state.structureCategorieList,
          response.data.insert_STR_StructureListeCategorie.returning[0],
        ]);
        success_notification("Categorie creer avec succès");
      } catch (error) {
        error_notification("Erreur de creation de la categorie");
      }
    },

    // get all list categorie
    async getStructureListCategorie({ commit }, { ID_Structure }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/UserList/getStructureListeCategorie.gql"),
          variables: {
            ID_Structure,
          },
          fetchPolicy: "network-only",
        });
        commit(
          "SET_STRUCTURE_CATEGORIE_LIST",
          response.data.STR_StructureListeCategorie
        );
      } catch (error) {
        error_notification("Oups! Une erreur est survenue!");
      }
    },

    //groupe creneau (new)

    async createGroupeCreneau({ commit, getters }, payload) {
      const oldGroupeCreneau = getters.getGroupeCreneaux;
      const isUpdate = oldGroupeCreneau.some(
        (creneau) => creneau.id === payload[0].id
      );
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Affiliations/createGroupeDataCreneau.gql"),
          variables: {
            GroupeData: payload,
          },
        });
        const newCreneau = oldGroupeCreneau.map((creneau) => {
          if (creneau.id === payload[0].id) {
            return response.data.insert_STR_GroupeCreneau.returning[0];
          } else return { ...creneau };
        });
        if (isUpdate) {
          const newCreneau = oldGroupeCreneau.map((creneau) => {
            if (creneau.id === payload[0].id) {
              return response.data.insert_STR_GroupeCreneau.returning[0];
            } else return { ...creneau };
          });
          commit("SET_GROUPE_CRENEAUX", newCreneau);
        } else {
          const newCreneau = [
            ...oldGroupeCreneau,
            response.data.insert_STR_GroupeCreneau.returning[0],
          ];
          commit("SET_GROUPE_CRENEAUX", newCreneau);
        }
        const message = isUpdate
          ? "Modification effectuée avec succès"
          : "Création groupe avec succès";
        success_notification(message);
      } catch (error) {
        let message = isUpdate
          ? "Erreur de modification du groupe"
          : "Erreur de création de groupe";
        if (error.message.includes("STR_GroupeCreneau_NomGroupe_key")) {
          message = "Erreur de création! Duplication du nom de groupe";
        }
        error_notification(message);
      }
    },

    async getGroupeCreneauFullData({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Affiliations/getGroupeCreneauFullData.gql"),
          variables: {
            ID_Structure: payload.ID_Structure,
            ID_Saison: payload.ID_Saison,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_GROUPE_CRENEAUX", response.data.STR_GroupeCreneau);
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },
    async getGroupeCreneauBySaison({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Affiliations/getGroupeCreneauBySaison.gql"),
          variables: {
            ID_Saison: payload.ID_Saison,
            ID_Structure: payload.ID_Structure,
          },
          fetchPolicy: "network-only",
        });
        commit("SET_GROUPE_CRENEAUX", response.data.STR_GroupeCreneau);
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },
    //get list horaires structure
    async getListHoraires({ commit, getters }, payload) {
      const response = await apolloClient.query({
        query: require("@/graphql/Affiliations/getGroupeHoraireList.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_Saison: payload.ID_Saison,
        },
        fetchPolicy: "network-only",
      });
      return response.data.STR_GroupeCreneauHoraires;
    },
    async deleteGroupeCreneaux({ commit, getters }, payload) {
      const oldGroupeCreneau = getters.getGroupeCreneaux;

      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Affiliations/deleteGroupeCreneaux.gql"),
          variables: {
            ID_CreanauGroupe: payload.ID_CreanauGroupe,
            ID_Saison: payload.ID_Saison,
          },
        });

        const newCreneau = oldGroupeCreneau.filter(
          (creneau) => creneau.id !== payload.ID_CreanauGroupe
        );
        commit("SET_GROUPE_CRENEAUX", newCreneau);
        success_notification("Le groupe a été supprimé avec succès");
      } catch (error) {
        error_notification("Erreur de la suppression du groupe");
      }
    },
    async deleteCreneauHoraire({ commit, getters }, payload) {
      const oldGroupeCreneau = getters.getGroupeCreneaux;
      const creneauToUpdate = oldGroupeCreneau.find(
        (groupe) => groupe.id === payload.groupe.id
      );

      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Affiliations/deleteCreneauHoraires.gql"),
          variables: {
            id: payload.ID_Horaire,
          },
        });
        const creneauHoraireCurrentSaison = creneauToUpdate.STR_GroupeSaisonCreneau.find(
          (creneau) => creneau.ID_Saison === payload.currentSaison
        );

        const creneauHoraireHorsSaison = creneauToUpdate.STR_GroupeSaisonCreneau.filter(
          (creneau) => creneau.ID_Saison !== payload.currentSaison
        );

        const creneauHoraire = [
          ...creneauHoraireCurrentSaison.STR_GroupeCreneauHoraires,
        ];

        const newCreneauHoraire = creneauHoraire.filter(
          (creneau) => creneau.id !== payload.ID_Horaire
        );
        const newGroupe = {
          ...creneauToUpdate,
          STR_GroupeSaisonCreneau: [
            ...creneauHoraireHorsSaison,
            {
              ...creneauHoraireCurrentSaison,
              STR_GroupeCreneauHoraires: newCreneauHoraire,
            },
          ],
        };

        const newGroupeHoraire = oldGroupeCreneau.map((creneau) => {
          if (creneau.id === payload.groupe.id) return { ...newGroupe };
          else return { ...creneau };
        });
        // const newCreneau = oldGroupeCreneau.filter(
        //   (creneau) => creneau.id !== payload.ID_CreanauGroupe
        // );
        commit("SET_GROUPE_CRENEAUX", newGroupeHoraire);
        success_notification("Créneau horaire supprimé avec succès");
      } catch (error) {
        error_notification("Erreur de suppression du créneau horaire");
      }
    },
    //get list creneaux (old)
    async updateColor({ commit }, payload) {
      try {
        let response = await apolloClient.mutate({
          mutation: require("@/graphql/Affiliations/updateColorCreneau.gql"),
          variables: {
            NomGroupe: payload.NomGroupe,
            ID_CodeCouleur: payload.ID_CodeCouleur,
          },
        });
        success_notification("Modification de la couleur avec succès");
      } catch (error) {
        error_notification("Erreur de modification de la couleur");
      }
    },
    async getAllColor() {
      const response = await apolloClient.query({
        query: require("@/graphql/Affiliations/getAllColor.gql"),
      });
      return response.data.LIST_Couleur;
    },
    // init of the stuctures, wich one we need to loads
    async initStructures({ commit, getters, dispatch }, payload) {
      // set the basic info of the structure
      commit("SET_STRUCTURES", payload.structures);
      if (getters.getStructureLicencie) {
        await dispatch("loadStructures", {
          ID_User: payload.userId,
          IDS_Structures: getters.getStructureLicencie.ID_Structure,
        });
        dispatch("setCurrentStructureLicencie");
      }

      // First, check if we have a role federal
      if (getters.hasRoleFederal.length) {
        // So just load the structures data we need and set the current structure id from local storage
        await dispatch("loadStructures", {
          ID_User: payload.userId,
          IDS_Structures: getters.withRoleFederal.map(
            (structure) => structure.ID_Structure
          ),
        });
        await dispatch("setCurrentStructureFederal");
      }
      return;
    },

    async loadStructures({ commit, getters, dispatch }, payload) {
      console.log(`payload====>>>`, payload);
      let response = await apolloClient.query({
        query: require("@/graphql/init_load_structures.gql"),
        variables: {
          ID_User: payload.ID_User,
          IDS_Structures: payload.IDS_Structures,
        },
      });
      console.log(response);
      await response.data.STR_Structure.forEach(async (infoStructure) => {
        let roleModules = await dispatch(
          "formatModulesStructure",
          infoStructure.STR_StructureUtilisateurs[0]
        );
        let index = getters.structures.indexOf(
          getters.structures.filter(
            (structure) => structure.ID_Structure === infoStructure.id
          )[0]
        );

        commit("SET_INIT_STRUCTURE_INFO", {
          index: index,
          data: infoStructure,
        });
        commit("SET_INIT_STRUCTURE_ROLE", {
          index: index,
          data: roleModules.role,
        });
        commit("SET_INIT_STRUCTURE_MODULES", {
          index: index,
          data: roleModules.modules,
        });
        return;
      });
      return;
    },

    async formatModulesStructure({ commit, dispatch }, modules) {
      if (!modules || isEmpty(modules)) return [];

      let modulesByRole = [];
      let roleOnStructure = {};
      if (modules.ADM_RoleType && modules.ADM_RoleType.ADM_RoleModule) {
        roleOnStructure = modules.ADM_RoleType;
        modulesByRole = await dispatch("formatModuleObject", {
          items: modules.ADM_RoleType.ADM_RoleModule,
          byRole: true,
        });
      }

      let modulesByUtilisateur = [];
      if (modules.ADM_StructureUtilisateurModule) {
        modulesByUtilisateur = await dispatch("formatModuleObject", {
          items: modules.ADM_StructureUtilisateurModule,
          byRole: false,
        });
      }

      return {
        modules: [...modulesByRole, ...modulesByUtilisateur],
        role: roleOnStructure,
      };
    },

    async formatModuleObject({ commit }, payload) {
      if (!payload.items.length) return [];
      return payload.items.map((item) => ({
        ID_Module: item.STR_Module.id,
        NomModule: item.STR_Module.NomModule,
        SlugModule: item.STR_Module.SlugModule,
        ID_ModuleCategorie: item.STR_Module.STR_ModuleCategorie.id,
        SlugModuleCategorie:
          item.STR_Module.STR_ModuleCategorie.SlugModuleCategorie,
        NomModuleCategorie:
          item.STR_Module.STR_ModuleCategorie.NomModuleCategorie,
        EST_Actif: item.EST_Actif,
        DateFinDroit: item.DateFinDroit || null,
        Actions: item.Actions,
        FromRole: payload.byRole ? true : false,
      }));
    },

    // set the current strucutre of a licencie, should be the one with EST_Sttructure licencie on STR_Utilisateur Structure
    async setCurrentStructureLicencie({ getters, commit }) {
      let currentStructureLicencie = getters.getStructureLicencie;
      if (currentStructureLicencie) {
        localStorage.setItem(
          "currentUserStructureId",
          getters.getStructureLicencie.ID_Structure
        );
        commit(
          "SET_CURRENT_STRUCTURE_ID",
          getters.getStructureLicencie.ID_Structure
        );
      } else {
        commit("SET_CURRENT_STRUCTURE_ID", null);
      }
    },
    async getStructureFonction(_, { ID_Structure, ID_Fonction }) {
      let query = {
        query: require("@/graphql/Structures/get_structure_collaborator_role.gql"),
        variables: { ID_Structure, ID_Fonction },
        fetchPolicy: "network-only",
      };
      const response = await apolloClient.query(query);
      console.log(
        "response.data.STR_StructureUtilisateur :>> ",
        response.data.STR_StructureUtilisateur
      );
      return response.data.STR_StructureUtilisateur;
    },
    async getStructuresByID(ctx, payload) {
      let query = {
        query: require("@/graphql/Structures/get_structure_byID.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.STR_StructureUtilisateur;
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    async getStructureListBySpecificInput({ commit }, { whereClause }) {
      const query = {
        query: require("@/graphql/Structures/get_structure_by_specific_input.gql"),
        variables: { whereClause },
        fetchPolicy: "network-only",
      };
      const response = await apolloClient.query(query);
      commit("SET_LIST_OF_STRUCTURE", response.data.STR_Structure);
      return response.data.STR_Structure;
    },
    // SET TH CURRENT STRUCTURE FEDERAL
    async setCurrentStructureFederal({ state, getters, commit }, structureId) {
      let forcedStructureId = parseInt(structureId);

      if (structureId && !Number.isNaN(forcedStructureId) && Number.isInteger(forcedStructureId)) {
        localStorage.setItem(
          process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME,
          structureId
        );
      }

      let currentStructureId;
      let localStructureID =
        localStorage.getItem(process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME) ??
        null;
      const userMode = localStorage.ffme_user_mode;

      // if licencie mode we take user structure and remove structureffme id
      if (userMode === "licencie") {
        const currentUserStructureID = localStorage.getItem(
          "currentUserStructureId"
        );
        localStorage.removeItem(process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME);
        return commit("SET_CURRENT_STRUCTURE_ID", +currentUserStructureID);
      }

      if (
        localStructureID == null ||
        localStructureID == "null" ||
        !localStructureID
      ) {
        localStorage.removeItem(process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME);
        currentStructureId = getters.withRoleFederal.find(
          (structure) => structure?.ID_Structure
        )?.ID_Structure;
      } else {
        let struct = getters.withRoleFederal.find(
          (str) => str.ID_Structure == localStructureID
        );

        if (struct && struct.ID_Structure) {
          currentStructureId = struct.ID_Structure;
        } else {
          currentStructureId = getters.withRoleFederal[0]?.ID_Structure;
        }
      }

      commit("SET_CURRENT_STRUCTURE_ID", currentStructureId);

      localStorage.setItem(
        process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME,
        currentStructureId
      );
    },

    // SET A ID FOR CHANGE CURRENT STRUCTURE ON FEDERAL VIEW
    async setCurrentStrucureFederal({ commit, dispatch }, payload) {
      // TODO Maybe add animtation for a good transition here
      await dispatch("app/setAppIsLoading", true, { root: true });

      if (router.history.current.name != "Home") {
        router.push({ name: "Home" });
      }

      commit("SET_CURRENT_STRUCTURE_ID", payload);
      localStorage.setItem(
        process.env.VUE_APP_CURRENT_STRUCTURE_ID_NAME,
        payload
      );
      await dispatch("app/setAppIsLoading", false, { root: true });
      await dispatch("app/hydrateApp", {}, { root: true });

      return;
    },
    async getStructureByID({ }, id) {
      let query = {
        query: require("@/graphql/Structures/get_structure_from_id.gql"),
        variables: { ID_Structure: id },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.STR_Structure[0];
        })
        .catch((e) => {
          console.log("error", e);
        });
    },
    async getStructureAvatar({ }, payload) {
      let query = {
        query: require("@/graphql/Documents/get_document_avatar_structure.gql"),
        variables: { ID_Structure: +payload.ID_Structure },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.DOC_Document;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateStructureAvatar({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Documents/insert_document2.gql"),
        variables: { document: payload },
        update: (store, { data: { insert_DOC_Document } }) => {
          if (insert_DOC_Document.affected_rows) {
            let query = {
              query: require("@/graphql/Documents/get_document_avatar_structure.gql"),
              variables: { ID_Structure: payload.ID_Structure },
            };
            const data = store.readQuery(query);
            data.DOC_Document[0] = { ...data.DOC_Document[0], payload };
            store.writeQuery({ ...query, data: data });
          }
        },
      };

      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async activateDesactivateAdhesionOnline({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Structures/activate_desactivate_adhesion_online.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          EST_AdhesionOnlineOuverte: payload.EST_AdhesionOnlineOuverte,
        },
      };

      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          success_notification(
            payload.EST_AdhesionOnlineOuverte
              ? "Adhésion en ligne activée"
              : "Adhésion en ligne désactivée"
          );
          return response;
        })
        .catch((e) => {
          error_notification("Oups! Une erreur est survenue");
          console.log(e);
        });
    },

    async getStructureList({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_list.gql"),
        variables: {
          whereClause: payload.whereClause,
        },
      });

      return response.data.STR_Structure;
    },

    async getStructureParent({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_parent.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
        },
      });

      return response.data.STR_Structure;
    },

    async getFluxFinancier({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_flux_financier.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_Saison: payload.ID_Saison,
        },
      });

      return response.data.MKP_FluxFinancier;
    },

    async getTarifsCotisation({ }, payload) {
      // console.log(payload)
      let response = await apolloClient.query({
        query: require("@/graphql/Tarifs/get_structure_cotisation_club_tarifs.gql"),
        variables: {
          IDS_Structures: payload.IDS_Structures,
          ID_Saison: payload.ID_Saison,
        },
      });

      return response.data.MKP_Tarif;
    },

    async getTarifCotisationFFME({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Tarifs/get_structure_cotisation_ffme.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
        },
      });

      return response.data.MKP_Tarif[0];
    },

    async getDepartement({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Affiliations/get_departement.gql"),
        variables: {
          ID_Departement: payload.ID_Departement,
        },
      });
      return response.data.STR_Structure;
    },

    async getRegion({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Affiliations/get_region.gql"),
        variables: {
          ID_TypeStructure: payload.ID_TypeStructure,
          ID_Region: payload.ID_Region,
        },
      });
      return response.data.STR_Structure;
    },

    async getAllFluxFinancierStructure({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_all_structure_flux_financier.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
        },
      });

      return response.data.MKP_FluxFinancier;
    },

    async getFluxDetail({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_flux_financier_detail.gql"),
        variables: {
          ID_Flux: payload.ID_Flux,
        },
      });

      return response.data.MKP_FluxFinancier;
    },

    async searchStructure({ }, payload) {
      console.log(payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/search_structure.gql"),
        variables: {
          whereClause: payload.whereClause,
        },
      });

      return response.data.STR_Structure;
    },

    async getLicenceDecouverteTarif({ }, { ID_Structure, ID_Saison }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_licence_decouverte_tarif.gql"),
        variables: {
          ID_Saison,
          ID_Structure,
        },
      });

      return response.data.MKP_Tarif;
    },
    async getTarifLicenceDecouverte({ }, { NomTarif, ID_Saison }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_tarif_licence_decouverte.gql"),
        variables: {
          ID_Saison,
          NomTarif,
        },
      });

      return response.data.MKP_Tarif;
    },

    async getStructureLicenceDecouverte({ }, { ID_Structure, ID_Saison }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Licences/get_all_licence_decouverte.gql"),
        variables: {
          ID_Saison,
          ID_Structure,
        },
        fetchPolicy: "network-only",
      });

      return response.data.STR_LicenceDecouverte;
    },

    async getLastLicenceDecouverte() {
      let response = await apolloClient.query({
        query: require("@/graphql/Licences/get_Last_Licence_Decouverte.gql"),
        fetchPolicy: "network-only",
      });

      return response.data.STR_LicenceDecouverte;
    },

    async updateLicenceDecouverteFluxFinancier(
      { },
      { fluxID, fluxDetailID, updatedFlux, updatedFluxDetail }
    ) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Structures/update_licence_decouverte_flux_financier.gql"),
        variables: {
          fluxID,
          fluxDetailID,
          updatedFlux,
          updatedFluxDetail,
        },
      });

      return response.data;
    },

    async insertStructure({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Structures/insert_structure_and_adresse.gql"),
        variables: {
          structure: payload.structure,
          infoGenerales: payload.infoGenerales,
        },
      });

      return response.data;
    },
    async createNewStructure(_, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Structures/insert_structure.gql"),
        variables: {
          structure: payload.structure,
        },
      });

      return response.data.insert_STR_Structure.returning[0].id;
    },

    async insertPrimoAffiliation({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Structures/insert_primo_affiliation_structure.gql"),
        variables: {
          affiliation: payload.affiliation,
        },
      });

      return response.data.insert_STR_Affiliation.returning[0];
    },
    async getLastStructure() {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_last_structure.gql"),
        fetchPolicy: "network-only",
      });
      return response.data.STR_Structure[0];
    },

    async getStructureType({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_type.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
        },
      });
      return response.data.STR_Structure[0];
    },

    async searchStructureExport({ }, { whereClause, ID_Saison }) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/search_structure_export.gql"),
        variables: {
          whereClause,
          ID_Saison,
        },
      });

      return response.data.STR_Structure;
    },

    async getDocumentStructureByType({ }, payload) {
      console.log(payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_document_by_type.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_DocumentType: payload.ID_DocumentType,
        },
        fetchPolicy: "network-only",
      });

      return response.data.DOC_Document;
    },

    async getStructurePresident({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structure_president.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
        },
      });

      return response.data.STR_StructureUtilisateur;
    },

    async getStatistiquesStructures({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_structures_stats.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
        },
      });

      return response.data.STAT_Licence;
    },

    async getRibByStructure({ }, payload) {
      console.log("payload !!!", payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Rib/get_Rib_By_Structure.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
        },
        fetchPolicy: "network-only",
      });

      return response.data.MKP_Rib;
    },

    async getAffiliationTempByName({ }, payload) {
      console.log("payload !!!", payload);
      let response = await apolloClient.query({
        query: require("@/graphql/Affiliations/getAffiliationTempByName.gql"),
        variables: {
          name: payload.name,
        },
        fetchPolicy: "network-only",
      });

      console.log("response", response);
      return response.data.STR_AffiliationTemp[0];
    },

    async updateGroupeSaisonCreneauUser({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Structures/update_STR_GroupeSaisonCreneauUser.gql"),
          variables: {
            first: payload.first,
            selected: payload.selected,
          },
        });
        return response.data.update_STR_GroupeSaisonCreneau.returning[0];
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },
    async updateStructureUtilisateurUser({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Structures/update_Structure_Utilisateur_User.gql"),
          variables: {
            first: payload.first,
            selected: payload.selected,
          },
        });
        return response.data.update_STR_StructureUtilisateur.returning[0];
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },

    async insertRib({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Rib/insert_MKP_Rib.gql"),
          variables: {
            rib: payload,
          },
        });
        return response.data.insert_MKP_Rib;
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },

    async paymentModalityModification({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Rib/payment_Modality_Modification.gql"),
          variables: {
            structureID: payload.structureID,
            datemodification: payload.datemodification,
            idModalite: payload.idModalite,
          },
        });
        return response.data.update_MKP_Rib;
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },

    async createUpdateRibInfos({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Rib/update_Rib_Infos.gql"),
          variables: {
            // MkpRib: [payload],
            structureID: payload.ID_Structure,
            domicilebancaire: payload.Domiciliation,
            titulaire: payload.FIN_NomTitulaire,
            numero_iban: payload.FIN_IbanNumero,
            numero_bic: payload.FIN_BicNumero,
          },
        });
        return response.data.update_MKP_Rib;
      } catch (error) {
        console.log("error fetching==>>", error);
      }
    },

    async updateTypePrelevement({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Rib/update_type_prelevement.gql"),
        variables: {
          type: payload.type,
          structureID: payload.structureID,
        },

        // update: () => {

        // }
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          // console.log(response);

          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateCodeRum({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Rib/update_Code_Rum.gql"),
        variables: {
          codeRum: payload.codeRum,
          structureID: payload.structureID,
        },

        // update: () => {

        // }
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          // console.log(response);

          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUserOrganizationalChart({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Structures/get_user_organizational_chart.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
        },
        fetchPolicy: "network-only",
      });

      return response.data.STR_StructureUtilisateur;
    },

    ...informations.actions,
    ...utilisateurs.actions,
    ...adresses.actions,
    ...affiliations.actions,
    ...news.actions,
    ...url.actions,
  },
};
