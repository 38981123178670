import store from "@/store";
import router from "@/router";
/* eslint-disable no-unused-vars*/

const route = [
  {
    path: "/",
    name: "gestion_des_affiliations__dashboard",
    meta: {
      "can": false,
      "title": "Gestion des affiliations"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ "@/views/GestionDesAffiliations/GestionDesAffiliations__Dashboard.vue"
      ),
    beforeEnter: (to, from, next) => {
      const structureType = store.getters["structure/currentStructureType"].SlugTypeStructure
      console.log(structureType)
      if (structureType == "service_federal_ffme" || structureType == "comite_territorial" || structureType == "ligue") {
        return next();
      }
      return router.push({ name: "Home" });
    },
  },
  {
    path: "primo-affiliations",
    name: "gestion_des_affiliations__demandes_primo_affiliations",
    meta: {
      "can": false,
      "title": "Primo affiliations"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ "@/modules/GestionDesAffiliations/GestionDesAffiliations__DemandesPrimoAffiliations.vue"
      ),
    beforeEnter: (to, from, next) => {
      const structureType = store.getters["structure/currentStructureType"].SlugTypeStructure
      if (structureType == "service_federal_ffme" || structureType == "comite_territorial" || structureType == "ligue") {
        return next();
      }
      return router.push({ name: "Home" });
    },
  },

  {
    path: "primo-affiliations/:id",
    name: "gestion_des_affiliations__primo_affiliation_detail",
    meta: {
      "can": false,
      "title": "Fiche demande de primo affiliations"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences" */ "@/modules/GestionDesAffiliations/GestionDesAffiliations__PrimoAffiliationsDetail.vue"
      ),
    beforeEnter: (to, from, next) => {
      const structureType = store.getters["structure/currentStructureType"].SlugTypeStructure
      if (structureType == "service_federal_ffme" || structureType == "comite_territorial" || structureType == "ligue") {
        return next();
      }
      return router.push({ name: "Home" });
    },
  },



];

export default route;
