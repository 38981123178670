<template>
  <div v-if="mounted">
    <FicheLicence :context="{ ID_Utilisateur: ID_Utilisateur }"></FicheLicence>
  </div>
  <div v-else>
    Chargement ...
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FicheLicence from "@/components/User/User__FicheLicence.vue";

export default {
  components: { FicheLicence },

  data: () => ({
    mounted: false,
    ID_utilisatueur: null,
  }),

  async created() {
    this.ID_Utilisateur = this.$route.params.id;
    this.mounted = true;
  },
};
</script>

<style lang="scss"></style>
