import { apolloClient } from "@/vue-apollo.js";
import { isEmpty } from "@/plugins/utils.js";

const state = {};

const getters = {};

const mutations = {
  SET_CERTIFICATS_MEDICAUX(state, certificatsMedicaux) {
    state.UTI_Utilisateur.certificatsMedicaux = certificatsMedicaux;
  },

  INSERT_CERTIFICAT_MEDICAUX(state, certificatMedicaux) {
    // delete any certificat with the same id
    state.UTI_Utilisateur.certificatsMedicaux = state.UTI_Utilisateur.certificatsMedicaux.filter(
      (cert) => cert.id !== certificatMedicaux.id
    );
    // add the new doc
    state.UTI_Utilisateur.certificatsMedicaux.push(certificatMedicaux);
  },
};

const actions = {
  async getCertificatsMedicaux({ commit, state, getters }, {ID_Utilisateur, ID_Saison}) {
    // console.log(ID_Saison)

    let response = await apolloClient.query({
      query: require("@/graphql/Account/CertificatsMedicaux/get.gql"),
      variables: {
        ID_Utilisateur: ID_Utilisateur || getters.currentUserId,
        ID_Saison: ID_Saison
      },
      fetchPolicy: 'network-only'
    });

    let data = response.data.DOC_Document;
    if (!ID_Utilisateur) {
      commit("SET_CERTIFICATS_MEDICAUX", data);
    } else {
      return data;
    }
  },

  async insertCertificatMedical({ commit }, {newCertificate, ID_Utilisateur, updateIDs}) {
    console.log('newCertificate, ID_Utilisateur, updateIDs:', newCertificate, ID_Utilisateur, updateIDs)
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Account/CertificatsMedicaux/insert.gql"),
      variables: {
        newCertificate,
        ID_Utilisateur,
        updateIDs
      },
    });

    let data = response.data.insert_DOC_Document.returning;
    // if (data[0]) {
    //   commit("INSERT_CERTIFICAT_MEDICAUX", data[0]);
    // }
    return data[0]
  },

  async getInfoSante(_, payload) {
      let query = {
        query: require("@/graphql/Documents/get_infos_sante.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.DOC_Document;
        })
        .catch((e) => {
          console.log(e);
        });
    },
};

export { state, getters, mutations, actions };
