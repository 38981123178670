import axios from "axios";

export const PRODUCT_CATEGORY_LICENCE = 'licence';
export const PRODUCT_CATEGORY_AFFILIATION = 'affiliation';

export const PRODUCT_LICENCE_HORS_CLUB_JEUNE = 'hors_club_jeune';
export const PRODUCT_LICENCE_HORS_CLUB_ADULTE = 'hors_club_adulte';

export const PRODUCT_AFFILIATION_EA = 'affiliation_ea';
export const PRODUCT_AFFILIATION_MA = 'affiliation_ma';
export const PRODUCT_AFFILIATION_CLUB = 'affiliation_club';

export const OPTION_TYPE_ASSURANCE = 'assurance';
export const OPTION_TYPE_OPTION_LICENCE_HC = 'option_licence_hc';
export const OPTION_TYPE_OPTION_ASSURANCE = 'option_assurance';
export const OPTION_TYPE_IJ = 'indemnite_journaliere';

export const LICENCE_TYPE_ADULTE = 'licence_adulte';
export const LICENCE_TYPE_JEUNE = 'licence_jeune';
export const LICENCE_TYPE_FAMILLE = 'licence_famille';

export const ASSURANCE_RC = 'rc';
export const ASSURANCE_BASE = 'base';
export const ASSURANCE_BASE_PLUS = 'base_plus';
export const ASSURANCE_BASE_PLUS_PLUS = 'base_plus_plus';
export const ASSURANCE_VOYAGE = 'assurance_voyage';

export const ASSURANCE_OPTION_PROTECTION_AGRESSION = 'protection_agression';
export const ASSURANCE_OPTION_COMPETITION = 'competition';

export const OPTION_AFFILIATION_0 = 'affiliation_0';
export const OPTION_AFFILIATION_100 = 'affiliation_100';
export const OPTION_AFFILIATION_301 = 'affiliation_301';

const TOKEN_NAME = process.env.VUE_APP_AUTH_TOKEN_NAME;

const getHeaders = () => {
  if (localStorage.getItem(TOKEN_NAME)) {
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem(TOKEN_NAME),
    };
  }

  return {
    'Content-Type': 'application/json',
  };
}

export const getProducts = async (payload) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + "/api/products?active=true",
      {
        params: payload,
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response.data?.['hydra:member'];
    })
    .catch((error) => {
    });
}

export const getOptions = async (payload) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + "/api/options?active=true",
      {
        params: payload,
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response.data?.['hydra:member'];
    })
    .catch((error) => {
    });
};

export const getPrices = async (payload) => {
  return await axios
    .get(process.env.VUE_APP_FFME_BACK_URL + "/api/prices",
      {
        params: payload,
        headers: getHeaders()
      }
    )
    .then((response) => {
      return response.data?.['hydra:member'];
    })
    .catch((error) => {
    });
};
