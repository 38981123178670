import { apolloClient } from "@/vue-apollo.js";
// import { isEmpty} from '@/plugins/utils.js';
/* eslint-disable no-empty-pattern */

const mutations = {
  SET_STRUCTURE_ADRESSE(state, payload) {
    state.structures[payload.index].ADR_Adresse = payload.data;
  },

  ADD_STRUCTURE_ADRESSE(state, payload) {
    state.structures[payload.index].ADR_Adresse.push(payload.data);
  },

  UPDATE_STRUCTURE_ADRESSE(state, payload) {
    let adresses = state.structures[payload.index].ADR_Adresse;
    let index = adresses.indexOf(
      adresses.find((adr) => adr.id === payload.data.id)
    );
    state.structures[payload.index].ADR_Adresse[index] = payload.data;
  },

  DELETE_STRUCTURE_ADRESSE(state, payload) {
    let adresses = state.structures[payload.index].ADR_Adresse;
    let index = adresses.indexOf(
      adresses.find((adr) => adr.id === payload.data.id)
    );
    adresses.splice(index, 1);
  },

  UPDATE_STRUCTURE_ADRESSE_ASSIGNEMENT(state, payload) {
    let key;
    if (payload.type == "accueil") {
      key = "ID_AdresseAccueil";
    } else if (payload.type == "correspondance") {
      key = "ID_AdresseCorrespondance";
    } else if (payload.type == "legal") {
      key = "ID_AdresseSiegeSocial";
    }
    state.structures[payload.index].STR_Structure[key] = payload.data[key];
  },
};

const actions = {
  async getStructureAdresses({ }, payload) {
    let response = await apolloClient.query({
      query: require("@/graphql/Adresses/get_adresse_structure.gql"),
      fetchPolicy: "network-only",
      variables: {
        ID_Structure: payload.ID_Structure,
      },
    });
    return response.data.ADR_Adresse;
  },

  async insertStructureAddress({ }, payload) {
    let mutation = {
      mutation: require("@/graphql/Adresses/insert_adresse_structure.gql"),
      variables: {
        adresse: payload.adresse,
      },
      update: (store, { data: { insert_ADR_Adresse } }) => {
        if (insert_ADR_Adresse.returning[0]) {
          let query = {
            query: require("@/graphql/Adresses/get_adresse_structure.gql"),
            variables: { ID_Structure: payload.ID_Structure },
          };
          const data = store.readQuery(query);

          let ID_Adresse = insert_ADR_Adresse.returning[0].id;
          let adresse = data.ADR_Adresse.find((adr) => adr.id == ID_Adresse);
          //  console.log('insert',insert_ADR_Adresse)
          //  console.log('query',data)
          //console.log(ID_Adresse)
          if (!adresse) {
            data.ADR_Adresse.push(insert_ADR_Adresse.returning[0]);
          } else {
            adresse = insert_ADR_Adresse.returning[0];
          }
          store.writeQuery({ ...query, data: data });
        }
      },
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  async insertAdresse({ }, payload) {
    let mutation = {
      mutation: require("@/graphql/Adresses/insert_adresse.gql"),
      variables: {
        adresse: payload,
      },
    };
    return await apolloClient
      .mutate(mutation)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // async updateStructureAddress( { commit,getters },  payload) {

  //   let response = await apolloClient.mutate({
  //       mutation: require('@/graphql/Adresses/update_adresse.gql'),
  //       variables: {
  //         Id_Adresse: payload.id,
  //         NomAdresse: payload.NomAdresse,
  //         Adresse1: payload.Adresse1,
  //         Adresse2: payload.Adresse2,
  //         CodePostal: payload.CodePostal,
  //         Ville: payload.Ville,
  //         ID_Pays: payload.ID_Pays
  //       },
  //   })
  //   let data = response.data.update_ADR_Adresse.returning

  //   if(data[0]) {
  //       commit('UPDATE_STRUCTURE_ADRESSE',  {index: getters.currentIndex, data: data[0]} )
  //       return data[0]
  //   }
  //   return
  // },

  async deleteStructureAdresse({ }, payload) {
    await apolloClient.mutate({
      mutation: require("@/graphql/Adresses/delete_adresse.gql"),
      variables: {
        Adresse_id: payload.adresse.id,
      },
      update: (store, { data: { delete_ADR_Adresse } }) => {
        if (delete_ADR_Adresse.affected_rows) {
          let query = {
            query: require("@/graphql/Adresses/get_adresse_structure.gql"),
            variables: { ID_Structure: payload.ID_Structure },
          };
          const data = store.readQuery(query);
          let index = data.ADR_Adresse.indexOf(
            data.ADR_Adresse.find((adr) => adr.id == payload.adresse.id)
          );
          data.ADR_Adresse.splice(index, 1);
          store.writeQuery({ ...query, data: data });
        }
      },
    });
    return;
  },

  async updateStructureAdresseAssignement({ commit, getters }, payload) {
    // console.log(payload);
    let file;
    if (payload.type == "accueil")
      file = "update_structure_adresse_accueil.gql";
    else if (payload.type == "correspondance")
      file = "update_structure_adresse_correspondance.gql";
    else if (payload.type == "legal")
      file = "update_structure_adresse_legal.gql";

    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Structures/" + file),
      variables: {
        Id_Adresse: payload.adresse.id,
        Id_Structure: payload.ID_Structure,
      },
    });
    let data = response.data.update_STR_Structure.returning;

    if (data[0]) {
      // console.log("returnunfg");
      commit("UPDATE_STRUCTURE_ADRESSE_ASSIGNEMENT", {
        index: getters.currentIndex,
        data: data[0],
        type: payload.type,
      });
      return data[0];
    }
    return;
  },

  async updateStructureAdresseCorrespondant({ dispatch }, payload) {
    let data = {
      type: "correspondance",
      adresse: { id: payload.id },
      ID_Structure: payload.ID_Structure,
    };
    let response = await dispatch("updateStructureAdresseAssignement", data);
    return response;
  },
};

export { mutations, actions };
