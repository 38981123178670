import * as auth from "./Auth/auth";
import * as rolesPermissions from "./Auth/rolesPermissions";

export default {
  namespaced: true,

  state: {
    ...auth.state,
    ...rolesPermissions.state,
  },

  getters: {
    ...auth.getters,
    ...rolesPermissions.getters,
  },

  mutations: {
    ...auth.mutations,
    ...rolesPermissions.mutations,
  },

  actions: {
    ...auth.actions,
    ...rolesPermissions.actions,
  },

  modules: {},
};
