const routes = [
  {
    path: '/',
    name: 'gestion_lemonway_kyc_creation_compte',
    meta: {
      // can: false,
      title: 'Création de compte KYC',
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_info_general" */ '@/modules/GestionComptabilite/GestionLemonwayKyc/CreateAccountKyc.vue'
      ),
  },

  {
    path: 'importation-document',
    name: 'gestion_lemonway_kyc_importation_document',
    meta: {
      // can: false,
      title: 'Importation de document KYC',
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_activite_club" */ '@/modules/GestionComptabilite/GestionLemonwayKyc/UploadDocumentKyc.vue'
      ),
  },

  {
    path: 'statut-document',
    name: 'gestion_lemonway_kyc_status_document',
    meta: {
      // can: false,
      title: 'Statut des documents KYC',
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_activite_club" */ '@/modules/GestionComptabilite/GestionLemonwayKyc/StatusDocumentKyc.vue'
      ),
  },
];

export default routes;
