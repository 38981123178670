import Vue from "vue";

const success_notification = (message, options = {}) => {
  const defaultSettings = {
    timeout: 5000,
  };

  const settings = { ...defaultSettings, ...options };

  return Vue.$toast.success(message, settings);
};

const warning_notification = (message, options = {}) => {
  const defaultSettings = {
    timeout: 5000,
  };

  const settings = { ...defaultSettings, ...options };

  return Vue.$toast.warning(message, settings);
};

const error_notification = (message, options = {}) => {
  const defaultSettings = {
    timeout: 5000,
  };

  const settings = { ...defaultSettings, ...options };

  return Vue.$toast.error(message ?? "Oups, une erreur est survenue...", settings);
};

const clear_notifications = () => {
  Vue.$toast.clear();
};

export { success_notification, warning_notification, error_notification, clear_notifications };
