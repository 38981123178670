/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */

import { apolloClient } from "@/vue-apollo.js";
import {
  capitalizeFirstLetter, generateFakeUserUuidForTest,
  randomNb,
  trimAndCapitalizeFirstLetter,
  trimAndUppercaseAllLetters,
} from "@/plugins/utils";
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import fetch from "node-fetch";
import axios from "axios";

export default {
  namespaced: true,

  state: {
    isInIDFuseState: false,
    userToAddToList: null,
    hasValidQS: false,
    utilisateursFullData: null,
  },

  getters: {},

  mutations: {
    SET_IS_IN_ID_FUSE_STATE: (state, payload) => {
      state.isInIDFuseState = payload;
    },
    SET_USER_TO_ADD_LIST: (state, payload) => {
      state.userToAddToList = [...payload];
    },
    SET_HAS_VALID_QS: (state, payload) => {
      state.hasValidQS = payload;
    },
    SET_UTILISATEUR_FULL_DATA: (state, payload) => {
      state.utilisateursFullData = payload;
    },
  },

  actions: {
    async getUtilisateur({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/get_utilisateur.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
        fetchPolicy: "cache-first",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_Utilisateurs[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUtilisateurAvatar({ }, payload) {
      let query = {
        query: require("@/graphql/Documents/get_document_avatar_user.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.DOC_Document;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUserPaymentAccount() {
      const response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_user_payment_account.gql"),
      });
      return response.data.UTI_UtilisateurLemonway;
    },

    async getUserPaymentAccountStructure({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/get_user_payment_account_structure.gql"),
        variables: { ID_Structure: payload },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_UtilisateurLemonway;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateUserPaymentAccount({ }, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/insert_user_payment_account.gql"),
        variables: {
          userAccount: payload.userAccount,
        },
      });

      return response;
    },

    async updateUtilisateurAvatar({ rootGetters }, payload) {
      let mutation = {
        mutation: require("@/graphql/Documents/insert_document2.gql"),
        variables: { document: payload },
        update: (store, { data: { insert_ADR_Adresse } }) => {
          if (insert_ADR_Adresse.affected_rows) {
            let query = {
              query: require("@/graphql/Documents/get_document_avatar_user.gql"),
              variables: { ID_Utilisateur: payload.ID_Utilisateur },
            };
            const data = store.readQuery(query);
            data.DOC_Document[0] = { ...data.DOC_Document[0], payload };
            store.writeQuery({ ...query, data: data });
          }
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUtilisateurAdresse({ }, payload) {
      let query = {
        query: require("@/graphql/Adresses/get_adresse_utilisateur.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.ADR_Adresse[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateUtilisateurCoordonnees({ }, payload) {
      let user = { ...payload.user };
      let adresse = { ...payload.adresse };
      delete user.ADR_Pays;
      delete user.DOC_Documents;
      delete adresse.ADR_Pays;
      delete adresse.__typename;
      delete user.__typename;

      let mutation = {
        mutation: require("@/graphql/Utilisateurs/update_utilisateur_coordonnees.gql"),
        variables: { user: user, adresse: adresse },
        update: (
          store,
          { data: { insert_ADR_Adresse, insert_UTI_Utilisateurs } }
        ) => {
          if (
            insert_ADR_Adresse.affected_rows &&
            insert_UTI_Utilisateurs.affected_rows
          ) {
            let queryUtilisateur = {
              query: require("@/graphql/Utilisateurs/get_utilisateur.gql"),
              variables: { ID_Utilisateur: payload.user.id },
            };
            let queryAdresse = {
              query: require("@/graphql/Adresses/get_adresse_utilisateur.gql"),
              variables: { ID_Utilisateur: payload.user.id },
            };
            // get the result of the query we want top update
            const dataUtilisateur = store.readQuery(queryUtilisateur);
            //make the desired changes
            dataUtilisateur.UTI_Utilisateurs[0] = payload.user;
            // rewrite the store cache
            store.writeQuery({ ...queryUtilisateur, data: dataUtilisateur });

            const dataAdresse = store.readQuery(queryAdresse);
            dataAdresse.ADR_Adresse[0] = payload.adresse;
            store.writeQuery({ ...queryAdresse, data: dataAdresse });
          }
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async deleteLicenceAssurance({ }, payload) {
      console.log("payload ", payload);
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/delete_licence_assurance.gql"),

        variables: {
          id: payload.id,
        },
      });
      return response;
    },

    async getUtilisateurFullData({ commit }, user_id) {
      console.log("getUtilisateurFullData user_id", user_id);
      try {
        let response = await apolloClient.query({
          query: require("@/graphql/Utilisateurs/get_utilisateur_full_data.gql"),
          variables: { ID_Utilisateur: user_id },
          fetchPolicy: "network-only",
        });
        console.log("response.data", response.data);
        commit("SET_UTILISATEUR_FULL_DATA", response.data);
        return response.data;
      } catch (e) {
        return null;
      }
    },

    async getUserBasicInfos({ commit }, user_id) {
      console.log("user_id", user_id);
      try {
        let response = await apolloClient.query({
          query: require("@/graphql/Utilisateurs/get_user_basic_infos.gql"),
          variables: { ID_Utilisateur: user_id },
          fetchPolicy: "network-only",
        });
        console.log("response.data", response.data);
        return response.data;
      } catch (e) {
        return null;
      }
    },

    async getUtilisateurActivites({ }, payload) {
      let query = {
        query: require("@/graphql/Activites/get_activite_utilisateur.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.ACTI_ActivitePratiquee;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateUtilisateurActivites({ }, payload) {
      let activites = payload.activites.map((act) => {
        let item = { ...act };
        delete item.LIST_ActiviteType;
        delete item.__typename;
        return item;
      });

      let mutation = {
        mutation: require("@/graphql/Activites/update_activite_utilisateur.gql"),
        variables: {
          Activities: activites,
          ID_Utilisateur: payload.ID_Utilisateur,
        },
        update: (store, { data: { insert_ACTI_ActivitePratiquee } }) => {
          if (insert_ACTI_ActivitePratiquee.affected_rows) {
            let query = {
              query: require("@/graphql/Activites/get_activite_utilisateur.gql"),
              variables: { ID_Utilisateur: payload.ID_Utilisateur },
            };
            const data = store.readQuery(query);
            data.ACTI_ActivitePratiquee = payload.activites;
            store.writeQuery({ ...query, data: data });
          }
        },
      };

      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUtilisateursPAP({ }, payload) {
      let query = {
        query: require("@/graphql/Pap/get_pap.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_PersonnesAPrevenir;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateUtilisateurPAP({ }, payload) {
      delete payload.user.__typename;

      let mutation = {
        mutation: require("@/graphql/Pap/update_pap.gql"),
        variables: { user: payload.user },
        update: (store, { data: { insert_UTI_PersonnesAPrevenir } }) => {
          if (insert_UTI_PersonnesAPrevenir) {
            let query = {
              query: require("@/graphql/Pap/get_pap.gql"),
              variables: { ID_Utilisateur: payload.ID_Utilisateur },
            };
            const data = store.readQuery(query);
            let returnedUser = insert_UTI_PersonnesAPrevenir.returning[0];
            let existUser = data.UTI_PersonnesAPrevenir.find(
              (user) => user.id === returnedUser.id
            );
            if (existUser) {
              let index = data.UTI_PersonnesAPrevenir.indexOf(existUser);
              data.UTI_PersonnesAPrevenir[index] = returnedUser;
            } else {
              data.UTI_PersonnesAPrevenir.push(returnedUser);
            }
            store.writeQuery({ ...query, data: data });
          }
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async insertUserPersonnePrevenir({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Pap/update_pap.gql"),
        variables: { user: payload },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async deleteUtilisateurPAP({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Pap/delete_pap.gql"),
        variables: { ID_Pap: payload.ID_Pap },
        update: (store, { data: { delete_UTI_PersonnesAPrevenir } }) => {
          if (delete_UTI_PersonnesAPrevenir) {
            let query = {
              query: require("@/graphql/Pap/get_pap.gql"),
              variables: { ID_Utilisateur: payload.ID_Utilisateur },
            };
            const data = store.readQuery(query);
            let returnedID = delete_UTI_PersonnesAPrevenir.returning[0];
            let pap = data.UTI_PersonnesAPrevenir.find(
              (pap) => pap.id === returnedID
            );
            let index = data.UTI_PersonnesAPrevenir.indexOf(pap);
            data.UTI_PersonnesAPrevenir.splice(index, 1);
            store.writeQuery({ ...query, data: data });
          }
        },
      };

      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUtilisateurDocumentKyc({ }, payload) {
      let query = {
        query: require("@/graphql/Documents/get_document_kyc.gql"),
        variables: { ID_Utilisateur: payload },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.DOC_Document;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUtilisateurCertificatsM(_, payload) {
      let query = {
        query: require("@/graphql/Documents/get_document_certificat_utilisateur.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.DOC_Document;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getUtilisateurQuestionnaireSante(_, payload) {
      let query = {
        query: require("@/graphql/Documents/get_document_questionnaire_sante.gql"),
        variables: { ID_Utilisateur: payload.ID_Utilisateur },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.DOC_Document;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async deleteUtilisateurCerttificatsM({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Documents/delete_document_certificat_utilisateur.gql"),
        variables: { ID_Document: payload.idToDelete },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          console.log("response", response);
          if (response.data.delete_DOC_Document.returning[0].ID_Type_Document === 60) {
            success_notification("Attestation de santé supprimée avec succès");
          } else {
            success_notification("Certificat médical supprimé avec succès");
          }
          return true;
        })
        .catch((e) => {
          error_notification("Erreur de suppression!");
          return false;
        });
    },

    async searchUtilisateurs({ }, payload) {
      //await sleep(2000)
      let query = {
        query: require("@/graphql/Utilisateurs/search_utilisateurs.gql"),
        variables: {
          CT_Nom: "%" + payload.CT_Nom.slice(0, 4) + "%",
          CT_Prenom: "%" + payload.CT_Prenom.slice(0, 4) + "%",
          DN_DateNaissance: payload.DN_DateNaissance,
          ID_Saison: payload.ID_Saison,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_Utilisateurs;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async saveTempUser({ }, payload) {
      console.log(payload.user);
      let mutation = {
        mutation: require("@/graphql/Utilisateurs/insert_temp_user.gql"),
        variables: { user: payload.user },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          console.log("response", response);
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getUtilisateursTemp({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/get_utilisateurs_temp.gql"),
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_UtilisateursTemp;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateTempUserReminderDate({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Utilisateurs/update_temp_user_reminder_date.gql"),
        variables: {
          userId: payload.userId,
          lastReminderDate: payload.lastReminderDate,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    },

    async getUtilisateursTempById({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/get_utilisateurs_temp_by_id.gql"),
        variables: {
          id: payload.id,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.UTI_UtilisateursTemp;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateUser({ }, { user }) {
      let mutation = {
        mutation: require("@/graphql/Utilisateurs/insert_user.gql"),
        variables: {
          user,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          // console.log(response);

          return response.data.insert_UTI_Utilisateurs.returning[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async updateUserAbonnement({ }, { user }) {
      let mutation = {
        mutation: require("@/graphql/Utilisateurs/update_user_abonnement.gql"),
        variables: {
          user,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          // console.log(response);

          return response.data.insert_UTI_Utilisateurs.returning[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async updateUserESTLicencie({ }, { ID_Utilisateur }) {
      let mutation = {
        mutation: require("@/graphql/Utilisateurs/update_user_est_licencie.gql"),
        variables: {
          ID_Utilisateur,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response.data.insert_UTI_Utilisateurs.returning[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async updateUserIsNonPratiquant({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Licences/update__is_non_pratiquant.gql"),
        variables: {
          userID: payload.userID,
          isNonPratiquant: payload.isNonPratiquant,
        },

        // update: () => {

        // }
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          // console.log(response);

          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async updateUserLicenceType({ }, payload) {
      let mutation = {
        mutation: require("@/graphql/Licences/update_licence_type.gql"),
        variables: {
          ID_Licence: payload.ID_Licence,
          NewID: payload.NewID,
        },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          // console.log(response);
          success_notification("Modification du type de licence avec succès");

          return response.data;
        })
        .catch((e) => {
          error_notification("Oups! Une erreur est survenue");
          console.log(e);
        });
    },

    async checkUserByNameBirthday(ctx, { firstname, lastname, birthday }) {
      return await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_utilisateur_by_name_birthday.gql"),
        variables: {
          firstname,
          lastname,
          birthday: birthday ? birthday : null,
        },
      });
    },
    async updateUserAdressPAP(ctx, { user, papId }) {
      let {
        pap_phone,
        pap_email,
        nationality,
        fix_phone,
        mobile_phone,
        email_1,
        email_2,
        adress,
        complement,
        cedex,
        city,
        country,
        userInfos,
      } = user;
      let adressChanges = {
        ID_Pays: country,
        Adresse1: adress,
        Adresse2: complement,
        CodePostal: cedex,
        Ville: city,
      };
      let userChanges = {
        ID_PaysNationalite: nationality,
        CT_Email: email_1,
        CT_Email2: email_2,
        CT_TelephoneFixe: fix_phone,
        CT_TelephoneMobile: mobile_phone,
      };
      let papChanges = {
        Tel_Mobile: "+33 " + pap_phone,
        Email: pap_email,
      };
      // remove falsy keys
      Object.keys(papChanges).forEach(
        (key) => !papChanges[key] && delete papChanges[key]
      );
      Object.keys(userChanges).forEach(
        (key) => !userChanges[key] && delete userChanges[key]
      );
      Object.keys(adressChanges).forEach(
        (key) => !adressChanges[key] && delete adressChanges[key]
      );
      await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_user_adress_pap.gql"),
        variables: {
          papId,
          userId: userInfos.id_user,
          adress: adressChanges,
          user: userChanges,
          pap: papChanges,
        },
      });
    },
    async updateUserAdressCreatePAP(ctx, user) {
      let {
        pap_phone,
        pap_email,
        pap_firstname,
        pap_lastname,
        nationality,
        fix_phone,
        mobile_phone,
        email_1,
        email_2,
        adress,
        complement,
        cedex,
        city,
        country,
        userInfos,
      } = user;
      let adressChanges = {
        ID_Pays: country,
        Adresse1: adress,
        Adresse2: complement,
        CodePostal: cedex,
        Ville: city,
      };
      let userChanges = {
        ID_PaysNationalite: nationality,
        CT_Email: email_1,
        CT_Email2: email_2,
        CT_TelephoneFixe: fix_phone,
        CT_TelephoneMobile: mobile_phone,
      };
      let newPap = {
        Tel_Mobile: "+33 " + pap_phone,
        Email: pap_email,
        ID_Utilisateur: userInfos.id_user,
        Nom: pap_lastname,
        Prenom: pap_firstname,
      };
      // remove falsy keys
      Object.keys(newPap).forEach((key) => !newPap[key] && delete newPap[key]);
      Object.keys(userChanges).forEach(
        (key) => !userChanges[key] && delete userChanges[key]
      );
      Object.keys(adressChanges).forEach(
        (key) => !adressChanges[key] && delete adressChanges[key]
      );
      await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_user_adress_create_pap.gql"),
        variables: {
          userId: userInfos.id_user,
          adress: adressChanges,
          user: userChanges,
          pap: newPap,
        },
      });
    },
    async renewUserUpdatePAP(ctx, { user, papId, licence }) {
      let {
        pap_phone,
        pap_email,
        nationality,
        fix_phone,
        mobile_phone,
        email_1,
        email_2,
        adress,
        complement,
        cedex,
        city,
        country,
        userInfos,
      } = user;
      let adressChanges = {
        ID_Pays: country,
        Adresse1: adress,
        Adresse2: complement,
        CodePostal: cedex,
        Ville: city,
      };
      let userChanges = {
        ID_PaysNationalite: nationality,
        CT_Email: email_1,
        CT_Email2: email_2,
        CT_TelephoneFixe: fix_phone,
        CT_TelephoneMobile: mobile_phone,
      };
      let papChanges = {
        Tel_Mobile: "+33 " + pap_phone,
        Email: pap_email,
      };
      // remove falsy keys
      Object.keys(papChanges).forEach(
        (key) => !papChanges[key] && delete papChanges[key]
      );
      Object.keys(userChanges).forEach(
        (key) => !userChanges[key] && delete userChanges[key]
      );
      Object.keys(adressChanges).forEach(
        (key) => !adressChanges[key] && delete adressChanges[key]
      );
      await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/renew_user.gql"),
        variables: {
          papId,
          userId: userInfos.id_user,
          adress: adressChanges,
          user: userChanges,
          pap: papChanges,
          licence,
        },
      });
    },
    async renewUserCreatePAP(ctx, { user, licence }) {
      let {
        pap_phone,
        pap_email,
        pap_firstname,
        pap_lastname,
        nationality,
        sex,
        fix_phone,
        mobile_phone,
        email_1,
        email_2,
        adress,
        complement,
        cedex,
        city,
        country,
        userInfos,
      } = user;
      let adressChanges = {
        ID_Pays: country,
        Adresse1: adress,
        Adresse2: complement,
        CodePostal: cedex,
        Ville: city,
      };
      let userChanges = {
        ID_PaysNationalite: nationality,
        CT_Email: email_1,
        CT_Email2: email_2,
        CT_TelephoneFixe: fix_phone,
        CT_TelephoneMobile: mobile_phone,
        CT_EST_Civilite: sex === "H" ? 1 : 2,
      };
      let newPap = {
        Tel_Mobile: "+33 " + pap_phone,
        Email: pap_email,
        ID_Utilisateur: userInfos.id_user,
        Nom: pap_lastname,
        Prenom: pap_firstname,
      };
      // remove falsy keys
      Object.keys(newPap).forEach((key) => !newPap[key] && delete newPap[key]);
      Object.keys(userChanges).forEach(
        (key) => !userChanges[key] && delete userChanges[key]
      );
      Object.keys(adressChanges).forEach(
        (key) => !adressChanges[key] && delete adressChanges[key]
      );
      await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/renew_user_create_pap.gql"),
        variables: {
          userId: userInfos.id_user,
          adress: adressChanges,
          user: userChanges,
          pap: newPap,
          licence,
        },
      });
    },
    async populateUser(ctx, { user, userID, licence }) {
      let {
        birthday,
        firstname,
        lastname,
        sex,
        pap_phone,
        pap_email,
        pap_complement,
        pap_cedex,
        pap_city,
        pap_firstname,
        pap_lastname,
        nationality,
        fix_phone,
        mobile_phone,
        email_1,
        email_2,
        adress,
        complement,
        cedex,
        city,
        country,
        userInfos,
      } = user;

      let adressChanges = {
        ID_Pays: country,
        Adresse1: adress,
        Adresse2: complement,
        CodePostal: cedex,
        Ville: city,
        ID_Utilisateur: userID,
      };

      let userChanges = {
        CT_EST_Civilite: sex.toUpperCase() === "H" ? 1 : 0,
        CT_Nom: lastname,
        CT_Prenom: firstname,
        DN_DateNaissance: birthday,
        ID_PaysNationalite: nationality,
        CT_Email: email_1,
        CT_Email2: email_2,
        CT_TelephoneFixe: fix_phone,
        CT_TelephoneMobile: mobile_phone,
      };
      let newPap = {
        Tel_Mobile: "+33 " + pap_phone,
        Email: pap_email,
        ID_Utilisateur: userID,
        Nom: pap_lastname,
        Prenom: pap_firstname,
      };
      // remove falsy keys
      Object.keys(newPap).forEach((key) => !newPap[key] && delete newPap[key]);
      Object.keys(userChanges).forEach(
        (key) => !userChanges[key] && delete userChanges[key]
      );
      Object.keys(adressChanges).forEach(
        (key) => !adressChanges[key] && delete adressChanges[key]
      );
      return await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/populate_user.gql"),
        variables: {
          userId: userID,
          adress: adressChanges,
          user: userChanges,
          pap: newPap,
          licence,
        },
      });
    },
    async sendModifDemande({ }, { editedUser }) {
      console.log("date envoyée en bdd", editedUser.Modif_DN_DateNaissance);

      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Account/Modifications/insert_user_modif_demande.gql"),
        variables: {
          modifs: editedUser,
        },
      });
    },
    async update({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Account/Modifications/insert_user_modif_demande.gql"),
        variables: {
          ID_Utilisateur: payload.id,
          Nom_User: payload.nom,
        },
      });
    },
    async getAllDemandesModif(ctx, { ID_Structure, isFFME }) {
      if (isFFME) {
        let response = await apolloClient.mutate({
          mutation: require("@/graphql/Account/Modifications/get_demandes_modifications_ffme.gql"),
        });
        return response.data.UTI_DemandeModification;
      } else {
        let response = await apolloClient.mutate({
          mutation: require("@/graphql/Account/Modifications/get_demandes_modifications.gql"),
          variables: {
            ID_Structure,
          },
        });
        return response.data.UTI_DemandeModification;
      }
    },
    async updateImmediatlyUser(ctx, payload) {
      console.log("payload", payload);
      try {
        let response = await apolloClient.mutate({
          mutation: require("@/graphql/Account/Modifications/update_immediatly_user.gql"),
          variables: {
            ID_Utilisateur: payload.ID_Utilisateur,

            data: payload.data,
            // CT_EST_Civilite: payload.CT_EST_Civilite,
            // CT_Nom: payload.CT_Nom,
            // CT_Prenom: payload.CT_Prenom,
            // // DN_DateNaissance: payload.DN_DateNaissance,
            // CT_NomDeNaissance: payload.CT_NomDeNaissance,
            // DN_CommuneNaissance: payload.DN_CommuneNaissance,
          },
        });
        return response.data.update_UTI_Utilisateurs.returning[0].id;
      } catch (error) {
        console.log(error);
      }
    },

    async updateFluxFinancierUser({ commit }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Finances/update_Flux_Financier_User.gql"),
          variables: {
            first: payload.first,
            selected: payload.selected,
          },
        });

        return response.data;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async updateUserModif(ctx, { payload }) {
      let { id, fields, userModifications, ID_Modified_User } = payload;
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Account/Modifications/update_user_and_demande_modification.gql"),
        variables: {
          id,
          fields,
          id_user: ID_Modified_User,
          userModifs: userModifications,
        },
      });
      // console.log('response:', response)
    },
    async updateDemandeModif(ctx, payload) {
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Account/Modifications/update_demande_modification.gql"),
        variables: {
          id: payload.id,
          fields: payload.fields,
        },
      });
      // console.log('response:', response)
    },

    async getUtilisateurRoleModule({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/get_utilisateurs_module_role.gql"),
        variables: {
          ID_Structure: payload.user.ID_Structure,
          ID_Utilisateur: payload.user.ID_Utilisateur,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getLicenceUtilisateur({ }, payload) {
      return await axios
        .get(process.env.VUE_APP_FFME_BACK_URL + "/api/licences",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            },
            params: {
              ...payload,
              active: true,
              pagination: false,
              partial: true,
              'groups[0]': 'licence:read',
            }
          }
        )
        .then((response) => {
          return response.data?.['hydra:member'];
        })
        .catch((error) => {
        });
    },

    async getUserLicences({ }, payload) {
      return await axios
        .get(process.env.VUE_APP_FFME_BACK_URL + "/api/licences",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem(process.env.VUE_APP_AUTH_TOKEN_NAME)
            },
            params: {
              ...payload,
              'groups[]': 'licence:read',
            }
          }
        )
        .then((response) => {
          return response.data?.['hydra:member'];
        })
        .catch((error) => {
        });
    },

    async getFluxFinancierUtilisateur({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/get_flux_financier_utilisateur.gql"),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
          ID_Structure: payload.ID_Structure,
          ID_Saison: payload.ID_Saison,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_FluxFinancier;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getLemonwayPaiement({ }, payload) {
      let query = {
        query: require("@/graphql/Lemonway/getLemonwayData.gql"),
        variables: {
          id: payload.id,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Lemonway;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async createFluxFinancierDetail({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/create_flux_financier.gql"),
        variables: {
          flux: payload.flux,
        },
      });
    },
    async createLemonwayPaiement({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Lemonway/createLemonwayPaiement.gql"),
        variables: {
          data: payload.data,
        },
      });
    },

    async updateLemonwayStatus({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Lemonway/updateLemonwayStatus.gql"),
        variables: {
          id: payload.id,
          status: payload.status,
        },
      });
    },

    async addUserToStructure({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/add_user_to_structure.gql"),
        variables: {
          user: payload.user,
        },
      });
    },

    // },
    async removeUserFromStructure({ }, payload) {
      try {
        console.log("payload heereee=>>", payload);
        await apolloClient.mutate({
          mutation: require("@/graphql/Utilisateurs/remove_users_from_structure.gql"),
          variables: {
            ID_Utilisateur: payload.ID_Utilisateur,
            ID_Structure: payload.ID_Structure,
            EST_RoleFederal: payload.EST_RoleFederal,
          },
        });
        return true;
      } catch (error) {
        error_notification("Erreur de suppression!");
      }
    },

    async updateEstActif({ }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_utilisateur_structure_isActive.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_Utilisateur: payload.ID_Utilisateur,
          EST_Actif: false,
        },
      });
      return response;
    },

    async userStructure({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_user_structure.gql"),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
          ID_Structure: payload.ID_Structure,
        },
        fetchPolicy: "network-only",
      });

      return response.data.STR_StructureUtilisateur;
    },

    async searchUtlisateur({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_utilisateur_structure.gql"),
        variables: {
          whereClause: payload.where,
        },
        fetchPolicy: "network-only",
      });

      return response.data.STR_StructureUtilisateur;
    },

    //recherche par NOM
    async searchUserByName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_name.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          saisonId: payload.saisonId,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_Name;
    },

    //recherche par NOM avec le compte
    async searchUserByNameStructureAndSaison({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_name_structure_and_saison.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          saisonId: payload.saisonId,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_Name;
    },

    //recherche des adresses avec le nom
    async searchAddressesAndUsersByAddressName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_addresses_and_users_by_address_name.gql"),
        variables: {
          Adresse1: payload.Adresse1,
          CodePostal: payload.CodePostal,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ADR_Adresse;
    },

    //recherche par NOM
    async searchUserByNameAll({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_name_all.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_Name_All;
    },

    //recherche par PRENOM
    async searchUserByFirstName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_firstName.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          saisonId: payload.saisonId,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_FirstName;
    },
    //recherche par PRENOM sans filtre saison
    async searchUserByFirstNameAll({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_firstName_all.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_FirstName_All;
    },

    //recherche par NOM et PRENOM
    async searchUserByNameAndFirstName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_name_and_firstname.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          CT_Firstname: payload.CT_Firstname,
          saisonId: payload.saisonId,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_Name_FirstName;
    },

    //recherche par NOM et PRENOM sans filtre saison
    async searchUserByNameAndFirstNameAll({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_by_name_and_firstname_all.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          CT_Firstname: payload.CT_Firstname,
          structureId: payload.structureId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.ASSU_StructureUtilisateur_By_Name_FirstName_All;
    },

    // search utilisateurs with licence validé
    async searchUtlisateurWithLicence({ }, payload) {
      // console.log("payload.where searchUtlisateurWithLicence", payload.where);
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/search_user_with_licence.gql"),
        variables: {
          whereClause: payload.where,
        },
        fetchPolicy: "network-only",
      });

      return response.data.UTI_Utilisateurs;
    },

    async getUtlisateursInStructure({ }, payload) {
      // console.log(payload)
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_users_in_structure.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          //fetchPolicy: "network-only"
        },
        fetchPolicy: "network-only",
      });

      return response.data.STR_StructureUtilisateur;
    },

    async countUserInStructure({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/count_users_in_structure.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          ID_Saison: payload.ID_Saison,
        },
        fetchPolicy: "network-only",
      });

      return response.data.count;
    },

    async getListDirigeants({ }, payload) {
      "@/graphql/Utilisateurs/get_list_dirigeants.gql";
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_list_dirigeants.gql"),
        variables: {
          whereClause: payload.where,
        },
        fetchPolicy: "network-only",
      });
      return response.data.STR_StructureUtilisateur;
    },

    async getUtilisateursLigue({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_utilisateur_ligue.gql"),
        variables: {
          ID_Structure: payload.ID_Structure,
          //fetchPolicy: "network-only"
        },
        fetchPolicy: "network-only",
      });

      return response.data.STR_StructureUtilisateur;
    },

    async getLastLicenceNumber({ }) {
      let response = await apolloClient.query({
        query: require("@/graphql/General/get_lastLicenceNumber.gql"),
      });

      return response.data.INFO_General[0];
    },

    async updateLastLicenceNumber({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/General/update_lastLicenceNumber.gql"),
        variables: {
          newLicenceNumber: payload.newLicenceNumber,
        },
        update: (store, { data: { insert_INFO_General } }) => {
          let query = {
            query: require("@/graphql/General/get_lastLicenceNumber.gql"),
          };
          // console.log(insert_INFO_General)
          const data = store.readQuery(query);
          data.INFO_General[0].LastLicenceNumber =
            insert_INFO_General.returning[0].LastLicenceNumber;
          store.writeQuery({ ...query, data: data });
        },
      });
    },

    async deleteFluxFinancierDetail({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/delete_flux_details.gql"),
        variables: {
          ID_FluxFinancier: payload.ID_FluxFinancier,
        },
      });
    },

    async deleteUtilisateur({ }, payload) {
      console.log("payload deleteUtilisateur", payload);
      return await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/deleteUtilisateur.gql"),
        variables: {
          ID_Utilisateur: payload.id,
        },
      });
    },

    async exportUtilisateurStructure({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/export_user_structure.gql"),
        variables: {
          structUserIDS: payload.structUserIDS,
          ID_Saison: payload.ID_Saison,
          userIDS: payload.userIDS,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    },

    //exporter user by NOM
    async exportUtilisateurStructureByName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/export_user_structure_by_name.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          saisonId: payload.saisonId,
          userIDS: payload.userIDS,
          structureId: payload.structureId,
          filterDoc: payload.filterDoc,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    },

    //exporter user by PRENOM
    async exportUtilisateurStructureByFirstName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/export_user_structure_by_firstName.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          saisonId: payload.saisonId,
          userIDS: payload.userIDS,
          structureId: payload.structureId,
          filterDoc: payload.filterDoc,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    },

    //exporter user by NOM et PRENOM
    async exportUtilisateurStructureByNameAndFirstName({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/export_user_by_name_and_firstname.gql"),
        variables: {
          CT_Name: payload.CT_Name,
          CT_Firstname: payload.CT_Firstname,
          saisonId: payload.saisonId,
          userIDS: payload.userIDS,
          structureId: payload.structureId,
          filterDoc: payload.filterDoc,
        },
        fetchPolicy: "network-only",
      });
      return response.data;
    },

    async createPaymentTokenHorsClub({ }, payload) {
      return await apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/create_payment_token_hors_club.gql"),
        variables: { user: payload.user },
      });
    },

    async getUserTempWithToken({ }, payload) {
      console.log(payload);
      return await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_user_with_token_hors_club.gql"),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
          PaymentToken: payload.PaymentToken,
        },
      });
    },
    async deleteOptionAssurance({ }, { id }) {
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Assurances/delete_option_assurance.gql"),
          variables: {
            id,
          },
        });

        return true;
      } catch (error) {
        error_notification("Erreur de suppression!");
      }
    },

    async deleteCurrentLicence({ }, payload) {
      console.log("payload ******", payload);
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Licences/delete_current_licence.gql"),
          variables: {
            ID_Licence: payload.ID_Licence,
            ID_Saison: payload.ID_Saison,
          },
        });

        return true;
      } catch (error) {
        error_notification("Erreur de suppression!");
      }
    },

    async getLastLicence({ }, payload) {
      console.log("getLastLicence payload", payload);
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Licences/get_Last_Licence.gql"),
          variables: {
            id_User: payload.id_User,
          },
          fetchPolicy: "network-only",
        });
        return response.data.UTI_Licence;
      } catch (error) {
        console.log(error);
        return error;
      }
    },

    async setLastLicence({ }, payload) {
      console.log("payload ******", payload);
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Licences/set_Last_Licence.gql"),
          variables: {
            id_User: payload.id_User,
            id_LastLicence: payload.id_LastLicence,
          },
        });

        return true;
      } catch (error) {
        error_notification("Erreur de suppression!");
      }
    },

    // get assurance type
    async getAllAssuranceType() {
      const response = await apolloClient.query({
        query: require("@/graphql/Assurances/get_all_assurance_type.gql"),
      });
      return response.data.ASSU_AssuranceType;
    },
    async getUtilisateurOptionAssurance({ }, payload) {
      const response = await apolloClient.query({
        query: require("@/graphql/Assurances/get_utilisateurs_assurance.gql"),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
          ID_Saison: payload.ID_Saison,
        },
        fetchPolicy: "network-only",
      });
      return response.data.UTI_LicenceAssurance;
    },

    async getUtilisateursLicenceAssurance({ }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Assurances/get_utilisateurs_licence_assurance.gql"),
          variables: {
            ID_Utilisateur: payload.ID_Utilisateur,
            ID_TypeAssurance: payload.ID_Assurance,
            ID_Saison: payload.ID_Saison,
            ID_Licence: payload.ID_Licence,
            ID_Tarif: payload.ID_Tarif,
          },
          fetchPolicy: "network-only",
        });
        return response.data.UTI_LicenceAssurance;
      } catch (error) {
        console.log(error);
      }
    },
    // delete && addUserOptionAssurance
    async addOptionUserAssurance({ }, payload) {
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Assurances/delete_user_optionAssurance.gql"),
          variables: {
            data: payload.data,
            AssurancesIDS: payload.AssurancesIDS,
          },
        });
        success_notification("Option(s) d'assurance ajoutée(s) avec succès");
      } catch (error) {
        console.log(`error`, error);
        error_notification("Oups! Une erreur est survenue");
      }
    },
    async insertOptionAssurance({ }, payload) {
      try {
        await apolloClient.mutate({
          mutation: require("@/graphql/Assurances/insert_option_assurance.gql"),
          variables: {
            data: payload,
          },
        });
      } catch (error) {
        console.log(`error`, error);
        error_notification("Oups! Une erreur est survenue");
      }
    },
    async insertUserLicence({ }, payload) {
      try {
        const response = await apolloClient.mutate({
          mutation: require("@/graphql/Licences/insert_licence_salarier.gql"),
          variables: {
            licence: payload,
          },
        });
        return response.data.insert_UTI_Licence.returning;
      } catch (error) {
        console.log(`error`, error);
        error_notification("Oups! Une erreur est survenue");
      }
    },

    async getUserByLicenceNumero({ }, payload) {
      return await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_user_by_licence_numero.gql"),
        variables: {
          LicenceNumero: payload.LicenceNumero,
        },
      });
    },

    async getUserLicenceHorsClub({ }, payload) {
      return await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_user_hors_club.gql"),
        variables: { ID_Saison: payload.ID_Saison },
      });
    },

    async insertLicenceDecouverte(ctx, { licence }) {
      let mutation = {
        mutation: require("@/graphql/Licences/insert_licence_decouverte.gql"),
        variables: {
          licenceDecouverte: licence,
        },
        // update: (store, { data: { insert_STR_LicenceDecouverte } }) => {
        //   console.log('insert_STR_LicenceDecouverte:', insert_STR_LicenceDecouverte)
        //   if (insert_STR_LicenceDecouverte.affected_rows) {
        //     let query = {
        //       query: require("@/graphql/Licences/get_all_licence_decouverte.gql"),
        //       variables: { ID_Structure: licence.ID_Structure, ID_Saison: licence.ID_Saison },
        //     };
        //     try {

        //       const data = store.readQuery(query);
        //       let ID_LicenceDecouverte = insert_STR_LicenceDecouverte.returning[0].id;
        //       let licenceDecouverte = data.STR_LicenceDecouverte.findIndex((licence) => licence.id == ID_LicenceDecouverte);
        //       console.log('licenceDecouverteeeeeeeeeeeee:', licenceDecouverte)
        //       if (licenceDecouverte >= 0) {
        //         if(licence.EST_Annulee) {
        //           data.STR_LicenceDecouverte.splice(licenceDecouverte, 1)
        //         } else {
        //           data.STR_LicenceDecouverte[licenceDecouverte] = insert_STR_LicenceDecouverte.returning[0]
        //         }
        //       } else {
        //         data.STR_LicenceDecouverte.push(insert_STR_LicenceDecouverte.returning[0]);
        //       }
        //       store.writeQuery({ ...query, data: data });
        //     } catch (error) {
        //       console.log('errooooooooor:', error)
        //       if (error.name === "Invariant Violation") {
        //         store.writeQuery({ ...query, data: { STR_LicenceDecouverte: [insert_STR_LicenceDecouverte.returning[0]] } });
        //       }

        //     }
        //   }
        //   return null
        // },
      };
      return await apolloClient
        .mutate(mutation)
        .then((response) => {
          return response;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async searchUserPrimoAffiliation({ }, payload) {
      let response = await apolloClient.query({
        query: require("@/graphql/Utilisateurs/get_utilisateur_primo_affiliation.gql"),
        variables: {
          CT_Nom: payload.CT_Nom,
          CT_Prenom: payload.CT_Prenom,
          CT_Email: payload.CT_Email,
        },
      });

      return response.data;
    },

    async getFluxFinancierByDateAndType({ }, payload) {
      console.log("getFluxFinancierByDateAndType ====>", payload);
      let query = {
        query: require("@/graphql/Utilisateurs/get_Flux_Financier_ByDate_AndType.gql"),
        variables: {
          Date: payload.date,
          Type: payload.type,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_FluxFinancier;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getBrouillard({ }, payload) {
      let query = {
        query: require("@/graphql/Utilisateurs/queryBrouillard.gql"),
        variables: { whereClause: payload.whereClause },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_FluxFinancier;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // Cretae user in auth cognito and retreive the id
    // @object payload
    //    @object user
    //       @string CT_Email
    //       @string CT_Prenom
    //       @string CT_Nom
    //    @string ID_Admin
    //    @int ID_Structure

    async createAuthUser({ dispatch }, payload) {
      let identifiant =
        payload.user.CT_Prenom.toLowerCase().trim() +
        "_" +
        payload.user.CT_Nom.toLowerCase().trim() +
        "_" +
        randomNb(1, 100).toString();

      let payloadUser = {
        email: payload.user.CT_Email,
        firstName: trimAndCapitalizeFirstLetter(payload.user.CT_Prenom),
        lastName: trimAndUppercaseAllLetters(payload.user.CT_Nom),
      };

      // si je suis en mode différent de prod, je ne crée pas l'utilisateur dans cognito
      // if (process.env.VUE_APP_ENV !== "prod") {
      //   payloadUser = {
      //     id: generateFakeUserUuidForTest(),
      //     CT_Email: payload.user.CT_Email,
      //     LOG_Login: identifiant.trim().replace(/\s/g, "_"),
      //     // username: payload.newIdentifiant ?? payload.user.CT_Email.trim(),
      //     CT_Prenom: trimAndCapitalizeFirstLetter(payload.user.CT_Prenom),
      //     CT_Nom: trimAndUppercaseAllLetters(payload.user.CT_Nom),
      //     CT_PreferredUsername: identifiant.trim().replace(/\s/g, "_"),
      //   };
      //   let user = await dispatch("updateUser", {
      //    user: payloadUser
      //   });
      //   if (user) {
      //     return user.id;
      //   }
      // } else {
      const fetch = require("node-fetch");
      let user = await (async () => {
        const headers = {
          "Content-Type": "application/json",
        };
        const response = await fetch(
          process.env.VUE_APP_FFME_BACK_URL + "/api/users/admin-register",
          {
            method: "post",
            body: JSON.stringify(payloadUser),
            headers,
          }
        );

        const json = await response.json();
        console.log('response', response);
        if (response.status == 201) {
          return json;
          //await this.storeNewUser(json.message)
        } else if (json.type == "Error") {
          return json;
        } else {
          error_notification(json.message);
          //  console.log(json.message)
        }
      })();

      if (user) {
        return user.userDataId;
      }
      // }
    },

    async updateStructureUtilisateur({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Structure_Utilisateur.gql"),
        variables: {
          first: payload.first,
        },
      });

      return response.data;
    },

    async updateDemandeModificationUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Demande_Modification_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateLicenceAssuranceUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Licences/update_Licence_Assurance_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateNewsletterUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/updateNewsletterUser.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updatePersonnesAPrevenirUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Personnes_APrevenir_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateLicenceuser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Licences/update_Licence_user.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateActivitePratiqueeUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Activite_Pratiquee_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateStructureUtilisateurModuleUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Structure_Utilisateur_Module_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateAdresseUser({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Adresse_User.gql"),
        variables: {
          first: payload.first,
          selected: payload.selected,
        },
      });

      return response.data;
    },

    async updateFluxFinancierprelevement({ commit }, payload) {
      let response = apolloClient.mutate({
        mutation: require("@/graphql/Utilisateurs/update_Flux_Financier_prelevement.gql"),
        variables: {
          id: payload.id,
        },
      });

      return response.data;
    },
  },
};
