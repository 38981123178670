const routes = [
  {
    path: "cotisations-et-tarifs",
    name: "affiliations__cotisations_et_tarifs",
    meta: {
      can: true,
      title: "Cotisations et tarifs",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_cotisatinon_tarif" */ "@/modules/GestionDeLaStructure/Affiliations/CotisationsEtTarifs.vue"
      ),
  },

  {
    path: "organiser-groupe-et-sections",
    name: "affiliations__organiser_groupe_et_section",
    meta: {
      can: true,
      title: "Organiser groupe et section",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_groupe_section" */ "@/modules/GestionDeLaStructure/Affiliations/OrganiserGroupeEtSection.vue"
      ),
  },

  {
    path: "organiser-planning-des-seances",
    name: "affiliations__organiser_planning_des_seances",
    meta: {
      can: true,
      title: "Organiser le planning des séances",
    },
    component: () =>
      import(
        /* webpackChunkName: "affilitation_planning_seance" */ "@/modules/GestionDeLaStructure/Affiliations/OrganiserPlanningDesSeances.vue"
      ),
  },
];

export default routes;
