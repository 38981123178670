import router from '@/router';

const route = [
  {
    path: "/",
    name: "primo_affiliation__inscription__dashboard",
    meta: {
      public: true,
      can: false,
      title: "Prendre une primo affiliation dashboard",
    },
    component: () =>
      import(
        /* webpackChunkName: "PrimoAffiliation" */ "@/modules/PrimoAffiliation/PrimoAffiliationDashboard.vue"
      ),
  },
  {
    path: "member-association/:type",
    name: "primo_affiliation__inscription",
    meta: {
      public: true,
      can: false,
      title: "Prendre une primo affiliation association",
    },
    component: () =>
      import(
        /* webpackChunkName: "PrimoAffiliation" */ "@/modules/PrimoAffiliation/primo_affiliation__inscription.vue"
      ),
  },
  {
    path: "affiliation-MA",
    name: "primo_affiliation_MA",
    meta: {
      public: true,
      can: false,
      title: "Prendre une primo affiliation membre associe",
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/modules/PrimoAffiliationMA/PrimoAffiliationMAInscription.vue"
      ),
    beforeEnter: (to, from, next) => {
      // Interdir l'accès pour l'affi MA à partir du 1er décembre 2024 (voir https://ffme.atlassian.net/browse/MYFFME-136)
      if (new Date() < new Date("2024-12-01")) {
        return next();
      }

      return router.push({ name: "primo_affiliation__inscription__dashboard" });
    }
  },
];

export default route;
