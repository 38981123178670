import store from "@/store";
import router from "@/router";

const route = [
  {
    path: "/",
    name: "gestion_des_parametres_globaux",
    meta: {
      can: false,
      title: "Gestion des parametres globaux",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_parametres_globaux" */ "@/modules/GestionDesParametresGlobaux/GestionDesParametresGlobaux.vue"
      ),
  },
  {
    path: "/parametres-globaux/saisir-tarifs",
    name: "saisir_des_tarifs",
    meta: {
      can: true,
      title: "Saisir des tarifs",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_parametres_globaux" */ "@/modules/GestionDesParametresGlobaux/SaisirDesTarifs.vue"
      ),
  },
  {
    path: "/parametres-globaux/visualiser-templates",
    name: "visualiser_des_templates",
    meta: {
      can: true,
      title: "Visualiser des templates",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_parametres_globaux" */ "@/modules/GestionDesParametresGlobaux/VisualiserDesTemplates.vue"
      ),
  },
];

export default route;
