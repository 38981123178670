/* eslint-disable no-empty-pattern */

import { apolloClient } from "@/vue-apollo.js";

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    async getTemplateBySlug({ }, payload) {
      let query = {
        query: require("@/graphql/Documents/get_template_by_slug.gql"),
        variables: { season: payload.season, slug: payload.slug },
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.LIST_Template[0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
