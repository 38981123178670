import Vue from "vue";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
  setInteractionMode,
} from "vee-validate";
import {
  required,
  digits,
  min,
  max,
  email,
  regex,
} from "vee-validate/dist/rules";

// --------------------------------------
// CONFIG START
// --------------------------------------

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// When to trigger the validation rules
setInteractionMode("eager");

// Configure the classes we want apply
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
    dirty: ["is-dirty", "is-dirty"], // multiple classes per flag!
    // ...
  },
});

// --------------------------------------
// RULES START
// --------------------------------------

// Add a secret.
extend("secret", {
  validate: (value) => value === "secret",
  message: "it's a 'secret'...",
});

// Add a required rule.
extend("required", {
  ...required,
  message: "Le champ est obligatoire",
});

// Add a required rule.
extend("digits", {
  ...digits,
  message: "Le champ doit contenir {length} chiffres",
});

// Add a required rule.
extend("min", {
  ...min,
  message: "Le champ doit contenir {length} caractères minimum",
});

// Add a required rule.
extend("max", {
  ...max,
  message: "Le champ doit contenir {length} caractères maximum",
});

// Add a required rule.
extend("email", {
  ...email,
  validate: (value) => {
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
  },
  message: "Le champ {_field_} doit être un email valide",
});

/**
 * Checks for a valid date conforming to RFC2822
 */
extend("date", {
  validate: (date) => {
    if (date.length < 10) {
      return false;
    } else {
      var altDate = date
        .split("-")
        .reverse()
        .join("/");
      return Vue.moment(altDate, "DD/MM/YYYY").isValid();
    }
  },
  message: "Merci de rentrer une date valide (RFC2822, par ex. 01-01-2020)",
});
extend("date_parution", {
  validate: (date) => {
    // first test the basic format xx/xx/xxxx
    if (!/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
      return false;
    }
    // now transform our custom date to a recognized format (RFC2822) so that moment can process it correcly
    let date_RFC2822 =
      date.substr(6, 4) + "-" + date.substr(3, 2) + "-" + date.substr(0, 2);
    // and then test if the date is valid ( no 99/99/9999 possible ;) )
    return Vue.moment(date_RFC2822).isValid();
  },
  message: "Merci de rentrer une date valide (par ex. 01/01/2020)",
});

extend("passwordEqual", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Les mots de passe ne sont pas identiques",
});

extend("site_web", {
  validate: (val) => {
    return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
      val
    );
  },
  message: "Merci de rentrer une adresse de site valide (https://...)",
});

extend("facebook", {
  validate: (val) => {
    return /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/.test(
      val
    );
  },
  message: "Merci de rentrer une adresse facebook valide",
});

extend("twitter", {
  validate: (val) => {
    return new RegExp("(https?)?:?(www)?twitter.com/[a-z].{2}").test(val);
  },
  message: "Merci de rentrer une adresse twitter valide",
});

extend("instagram", {
  validate: (val) => {
    return new RegExp("(https?)?:?(www)?instagram.com/[a-z].{2}").test(val);
  },
  message: "Merci de rentrer une adresse instagram valide",
});

extend("whatsapp", {
  validate: (val) => {
    return new RegExp("(https?)?:?(www)?whatsapp.com/[a-z].{2}").test(val);
  },
  message: "Merci de rentrer une adresse whatsapp valide",
});

// SIRET
const isSIRET = function (siret) {
  return verify(siret, 14);
};

// const isSIREN = function(siren){
//   return verify(siren,9)
// }

function verify(number, size) {
  if (isNaN(number) || number.length != size) return false;
  return true;
}

extend("siret", {
  validate: (val) => {
    return isSIRET(val);
  },
  message: "Merci de saisir un numéro de SIRET valide (14 chiffres)",
});

extend("regexname", {
  ...regex,
  validate: (val) => {
    let doesNotContain = /^((?![*.!@$€"`%^&(){}[\]:;<>,?\\~_+=|#]+).)*$/.test(val);
    let doesNotContainNumbers = !/\d/.test(val);
    let containLetters = /[a-zA-Z]/.test(val);

    return doesNotContain && doesNotContainNumbers && containLetters;
  },
  message:
    "Merci de rentrer une valeur valide (lettres, accents, un espace, -)",
});

extend('dashCounter', {
  validate: (value) => {
    let dashCounter = !/--{2,}/.test(value);

    return dashCounter;
  },
  message: "Le champ ne doit pas contenir plus de 2 traits d’union consécutifs",
});

extend("trialMinDate", {
  validate: (value) => {
    return Vue.moment(value).isAfter(Vue.moment().subtract(1, "days"));
  },
  message: "La date doit être supérieure à la date du jour",
});

extend("trialMaxDate", {
  params: ["maxDate"],
  validate(value, { maxDate }) {
    return Vue.moment(value).isBefore(Vue.moment(maxDate));
  },
  message: "La date doit être inférieure à la date de fin de la saison",
});
