<template>
  <div v-if="mounted" class="avatar__config-link">
    <a href="#" @click.prevent="updateAvatarModal"
      ><font-awesome-icon icon="camera" />{{ label }}</a
    >
    <updateAvatarModal
      v-if="openAvatarChange"
      :defaultImage="defaultImage"
      :isOpen="openAvatarChange"
      :noNotice="noNotice"
      @onClose="closeAvatarModal"
      @onFileUploaded="avatarUploaded"
      :isLogo="isLogo"
    />
  </div>
</template>

<script>
import updateAvatarModal from "@/components/utils/modals/Avatar/AvatarUpload__Modal.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { bus } from "@/main";
export default {
  components: { updateAvatarModal },

  data: () => ({
    // updateAvatarModalIsOpen: false,
    defaultImage: null,
    mounted: false,
  }),

  async created() {
    this.mounted = true;
  },

  computed: {
    ...mapGetters("basics", ["getList"]),

    ...mapState({
      openAvatarChange: (state) => state.structure.openAvatarModalChange,
    }),
  },

  methods: {
    ...mapActions("utilisateurs", [
      "getUtilisateurAvatar",
      "updateUtilisateurAvatar",
    ]),
    ...mapActions("structure", ["getStructureAvatar", "updateStructureAvatar"]),

    updateAvatarModal() {
      this.updateAvatarModalIsOpen = true;
      this.$store.commit("structure/SET_OPEN_AVATAR_MODAL", true);
    },
    closeAvatarModal() {
      this.$store.commit("structure/SET_OPEN_AVATAR_MODAL", false);
    },

    async avatarUploaded(file) {
      let idAvatar = null;
      let categorie_id;

      let payload = {
        ...this.context,
        CheminDocument: file.url,
        EST_Actif: true,
        EST_DocumentValide: true,
      };

      if (this.context.ID_Utilisateur) {
        categorie_id = this.getList.LIST_DocumentType.find(
          (doc_type) => doc_type.SlugTypeDocument === "avatar_user"
        ).id;
        let avatars = await this.getUtilisateurAvatar(this.context);
        let avatar = avatars.find((av) => av.ID_Type_Document == categorie_id);
        if (avatar) idAvatar = avatar.id;
        payload.NomDocument = "avatar_user_" + this.context.ID_Utilisateur;
        payload.ID_Utilisateur = this.context.ID_Utilisateur;
        payload.ID_Type_Document = categorie_id;
        if (idAvatar) payload.id = idAvatar;
        await this.updateUtilisateurAvatar(payload);
        //this.$router.go();
      }

      if (this.context.ID_Structure) {
        categorie_id = this.getList.LIST_DocumentType.find(
          (doc_type) => doc_type.SlugTypeDocument === "avatar_structure"
        ).id;
        let avatars = await this.getStructureAvatar(this.context);
        let avatar = avatars.find((av) => av.ID_Type_Document == categorie_id);
        if (avatar) idAvatar = avatar.id;
        payload.NomDocument = "avatar_structure_" + this.context.ID_Structure;
        payload.ID_Structure = this.context.ID_Structure;
        payload.ID_Type_Document = categorie_id;
        if (idAvatar) payload.id = idAvatar;
        await this.updateStructureAvatar(payload);
      }

      bus.$emit("onAvatarUploaded");
      this.$store.commit("structure/SET_OPEN_AVATAR_MODAL", false);
    },
  },

  props: {
    context: {
      type: Object,
    },

    label: {
      type: String,
    },
    noNotice: {
      type: Boolean,
      default: false,
    },
    isLogo: {
      type: Boolean,
      default: false,
    },
    updateAvatarModalIsOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
