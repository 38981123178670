import { apolloClient } from "../../../vue-apollo";

const state = {
  roles: [],
  permissions: [],
};

const getters = {
  roles: (state) => state.roles,
  permissions: (state) => state.permissions,
};

const mutations = {
  SET_ROLES(state, payload) {
    state.roles = payload;
  },
  SET_PERMISSIONS() {},
};

const actions = {
  async initRolesAndPermissions({ state, commit }) {
    let response = await apolloClient.query({
      query: require("@/graphql/Auth/get_roles_permissions.gql"),
      variables: {
        currentUserId: state.user.id,
      },
    });
    console.log("response===>>", response.data.STR_StructureUtilisateur);
    return commit("SET_ROLES", response.data.STR_StructureUtilisateur);
  },
};

export { state, getters, mutations, actions };
