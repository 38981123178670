/* eslint-disable no-unused-vars */
import { apolloClient } from "@/vue-apollo.js";
export default {
  namespaced: true,

  state: {
    planComptable: [],
    fluxFinanciers: [],
  },

  getters: {
    getPlanComptable: (state) => state.planComptable,
    getFluxFinanciers: (state) => state.fluxFinanciers,
  },

  mutations: {
    SET_PLAN_COMPTABLE: (state, payload) =>
      (state.planComptable = [...payload]),
    SET_FLUX_FINANCIERS: (state, payload) =>
      (state.fluxFinanciers = [...payload]),
  },

  actions: {
    async getPlanComptableFiltred({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Finances/GetPlanComptable.gql"),
          fetchPolicy: "network-only",
          variables: { whereClause: payload.whereClause },
        });
        commit("SET_PLAN_COMPTABLE", response.data.FIN_PlanComptable);
        return response.data.FIN_PlanComptable;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async queryFluxFinanciers({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Finances/GetFluxFinanciers.gql"),
          fetchPolicy: "network-only",
          variables: { whereClause: payload.whereClause },
        });
        console.log(
          "response.data.MKP_Fflux :>> ",
          response.data.MKP_FluxFinancier
        );
        commit("SET_PLAN_COMPTABLE", response.data.MKP_FluxFinancier);
        return response.data.MKP_FluxFinancier;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
    async updateSepa({ commit }, payload) {
      console.log("payload updateSepa !!!!", payload);
      let response = await apolloClient.mutate({
        mutation: require("@/graphql/Finances/updateSepa.gql"),
        variables: {
          id: payload.id,
          date: payload.date,
        },
      });
      return response;
    },

    async querySepaPrelevement({ commit }, payload) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Finances/get_Sepa_Date_Prelevement.gql"),
          fetchPolicy: "network-only",
          variables: { id: payload.id },
        });
        return response.data.MKP_Sepa;
      } catch (error) {
        console.log("error=>>", error);
      }
    },

    async getPrelevements({ commit }) {
      try {
        const response = await apolloClient.query({
          query: require("@/graphql/Finances/get_Prelevements.gql"),
          fetchPolicy: "network-only",
        });
        return response.data.LIST_TypePrelevement;
      } catch (error) {
        console.log("error=>>", error);
      }
    },
  },
  // async updateFluxFinancierUser({ commit }, payload) {
  //   try {
  //     const response = await apolloClient.query({
  //       query: require("@/graphql/Finances/update_Flux_Financier_User.gql"),
  //       fetchPolicy: "network-only",
  //       variables: {
  //         first: payload.first,
  //         selected: payload.selected,
  //       },
  //     });

  //     return response.data;
  //   } catch (error) {
  //     console.log("error=>>", error);
  //   }
  // },
};
