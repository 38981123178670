export default [
  {
    path: "/",
    name: "mon_compte__modification_cordonnees",
    meta: {
      can: false,
      title: "Modification des coordonnées",
      personalMode: true,
    },

    component: () =>
      import(
        /* webpackChunkName: "MonCompte" */ "../views/MonCompte/ModificationDesCoordonnees.vue"
      ),
  },

  {
    path: "activites-pratiques",
    name: "mon_compte__activitees_pratiques",
    meta: {
      can: false,
      title: "Activités pratiquées",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MonCompte" */ "../views/MonCompte/ActiviteesPratiquees.vue"
      ),
  },

  {
    path: "personnes-a-prevenir",
    name: "mon_compte__personnes_incident",
    meta: {
      can: false,
      title: "Personnes à prévenir",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MonCompte" */ "../views/MonCompte/PersonneAPrevenir.vue"
      ),
  },

  {
    path: "certificat-medical",
    name: "mon_compte__certificat_medical",
    meta: {
      can: false,
      title: "Certificats médicaux",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MonCompte" */ "../views/MonCompte/CertificatMedical.vue"
      ),
  },

  {
    path: "mot-de-passe",
    name: "mon_compte__mot_de_passe",
    meta: {
      can: false,
      title: "Identifiant et mot de passe",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MonCompte" */ "../views/MonCompte/MotDePasse.vue"
      ),
  },
  {
    path: "abonnements",
    name: "mon_compte__abonnements",
    meta: {
      can: false,
      title: "Abonnements",
      personalMode: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "MonCompte" */ "../views/MonCompte/Abonnements.vue"
      ),
  },
];
