/* eslint-disable no-unused-vars */
import store from "@/store";

const user = () => {
  return store.getters["auth/user"];
};

const roles = () => {
  return store.getters["auth/roles"];
};

const is = (role) => {
  // TODO: check on the current structure

  if (role == "federal") {
    return roles().some((role) => {
      if (role.EST_RoleFederal) return true;
      return false;
    });
  }

  if (role == "licencie") {
    return roles().some((role) => {
      if (role.EST_RoleFederal === false) return true;
      return false;
    });
  }
};

export { user, is };
