/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */
import Vue from 'vue';
import { apolloClient } from '@/vue-apollo.js';
import * as pap from '@/store/modules/Account/PersonnesAPrevenir.js';

import {
  success_notification,
  error_notification,
} from "@/plugins/notifications.js";

import * as certificatsMedicaux from '@/store/modules/Account/CertificatsMedicaux.js';

export default {
  namespaced: true,

  state: {
    UTI_Utilisateur: null,
    licences: null,

    // TODO, update use now those keys instead  inside UTI_Utilisateur
    ADR_Adresse: {},
    DOC_Documents: [],

    user_federal_structure: [],
    ADM_RoleType: {},

    UTI_Utilisateurs_Disability: [],

    ...pap.state,
  },

  getters: {
    // if a user has acces to the given module slug
    can: (state, getters, rootState, rootGetters) => (module_name) => {
      let role = rootGetters['structure/currentStructure'].ADR_RoleType;
      let modules = rootGetters['structure/currentStructure'].ADR_DroitsType;
      // Pour accéder aux modules suivant il faut avoir une licence active sur la saison en cours
      if (['mes_licences__ajouter_des_options'].indexOf(module_name) !== -1) {
        if (rootGetters['user/getLicences'] && rootGetters['user/getLicences'].find(licence => licence.season.id === rootGetters['saisons/currentSaison'].Annee)) {
          return true;
        } else {
          return false
        }
      }
      if (role && role.SlugRole == 'super_administrateur') return true;
      if (modules) return modules.some((mod) => mod.SlugModule == module_name);
      return null;
    },
    getUserFull(state) {
      return state.UTI_Utilisateur;
    },

    currentUser(state, getters) {
      return state.UTI_Utilisateur;
    },

    getLicences(state) {
      return state.licences;
    },

    getAvailableModules(state, getters, rootState, rootGetters) {
      return rootGetters['structure/getCurrentModulesAvailable'];
    },

    getRole(state, getters, rootState, rootGetters) {
      return rootGetters['structure/currentStructure']?.ADR_RoleType;
    },

    currentUserId(state, getters) {
      return state.UTI_Utilisateur.id;
    },

    user_federal_structure(state) {
      return state.user_federal_structure;
    },

    hasRoleFederalOn: (state) => (structure_id) => {
      return state.user_federal_structure.find((str) => str == structure_id);
    },

    hasRoleFederal(state, getters) {
      if (getters.user_federal_structure.length) return true;
      return false;
    },

    currentUserAvatar(state, getters, rootState) {
      let categorie_id = rootState.basics.LIST.LIST_DocumentType.find(
        (doc) => doc.SlugTypeDocument == 'avatar_user'
      ).id;
      return getters.currentUser?.DOC_Documents?.filter(
        (doc) => doc.ID_Type_Document === categorie_id
      )[0];
    },

    currentUserValidLicence(state, getters, rootState, rootGetters) {
      return getters.currentUser.UTI_Licences.find(
        (lic) => lic.ID_Saison == rootGetters['saisons/selectedSaison'].id
      );
    },

    currentUserAdresse(state, getters) {
      if (!getters.currentUser.ADR_Adresse[0]) {
        return {};
      }
      return getters.currentUser.ADR_Adresse[0];
    },

    ...pap.getters,
  },

  mutations: {
    UPDATE_USER_NEWS_LETTER(
      state,
      {
        EST_CommunicationDirectInfo,
        EST_CommunicationPartenaire,
        EST_UtilisationInfoPersonnel,
      }
    ) {
      state.UTI_Utilisateur = {
        ...state.UTI_Utilisateur,
        EST_CommunicationDirectInfo,
        EST_CommunicationPartenaire,
        EST_UtilisationInfoPersonnel,
      };
    },
    SET_USER(state, payload) {
      state.UTI_Utilisateur = payload;
    },

    SET_LICENCE(state, payload) {
      state.licences = payload;
    },

    SET_USER_FEDERAL_STRUCTURE(state, payload) {
      state.user_federal_structure = payload;
    },

    UPDATE_CURRENT_USER_AVATAR(state, payload) {
      let avatar = state.UTI_Utilisateur.DOC_Documents.find(
        (doc) => doc.id === payload.id
      );

      if (avatar) {
        let index = state.UTI_Utilisateur.DOC_Documents.indexOf(avatar);
        state.UTI_Utilisateur.DOC_Documents[index] = payload;
      } else {
        state.UTI_Utilisateur.DOC_Documents.push(payload);
      }
    },

    SET_CURRENT_USER_LICENCES(state, licence_data) {
      state.UTI_Utilisateur.UTI_Licences = licence_data;
    },

    SET_USER_DISABILITIES(state, disabilities_data) {
      state.UTI_Utilisateurs_Disability = disabilities_data;
    },

    INSERT_DOCUMENT(state, document) {
      // delete any existing doc with the same id
      state.UTI_Utilisateur.DOC_Documents = state.UTI_Utilisateur.DOC_Documents.filter(
        (doc) => doc.id !== document.id
      );
      // add the new doc
      state.UTI_Utilisateur.DOC_Documents.push(document);
    },

    SET_USER_PREMIERE_CONNEXION(state, date) {
      state.UTI_Utilisateur.Z_DatePremiereConnexion = date;
    },

    ...pap.mutations,
    ...certificatsMedicaux.mutations,
  },

  actions: {
    async initUser({ commit, rootGetters, dispatch }, payload) {
      delete payload.STR_StructureUtilisateurs;
      commit('SET_USER', payload);
    },

    async initLicence({ commit, rootGetters, dispatch }, payload) {
      commit('SET_LICENCE', payload);
    },

    async setUserFeralStructure({ commit }, payload) {
      commit('SET_USER_FEDERAL_STRUCTURE', payload);
    },

    async updateCurrentUserAvatar({ commit }, payload) {
      commit('UPDATE_CURRENT_USER_AVATAR', payload);
      return;
    },

    async getCurrentUserLicencesData({ commit, state, getters }) {
      // TODO make it work with any id (add params)

      if (state.UTI_Utilisateur.UTI_Licences) {
        return state.UTI_Utilisateur.UTI_Licences;
      }

      let response = await apolloClient.query({
        query: require('@/graphql/Licences/get_licence_utilisateur.gql'),
        variables: {
          ID_Utilisateur: getters.currentUserId,
        },
        fetchPolicy: 'network-only',
      });

      // TODO get the correct fields
      commit('SET_CURRENT_USER_LICENCES', response.data.UTI_Licence);
      return response.data.UTI_Licence;
    },

    async getUserLicence({ }, payload) {
      let response = await apolloClient.query({
        query: require('@/graphql/Licences/get_licence_utilisateur.gql'),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
        },
        fetchPolicy: 'network-only',
      });

      return response.data.UTI_Licence;
    },
    async getUserLicenceType({ }, payload) {
      let response = await apolloClient.query({
        query: require('@/graphql/Licences/get_licence_utilisateur_type.gql'),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
          ID_Saison: payload.ID_Saison,
        },
        fetchPolicy: 'network-only',
      });

      return response.data.UTI_Licence;
    },
    async getLicenceSiegeUsers({ }, payload) {
      let response = await apolloClient.query({
        query: require('@/graphql/Licences/get_licence_siege_users.gql'),

        fetchPolicy: 'network-only',
      });

      return response.data.UTI_Licence;
    },
    async getUserHorsClubLicence({ }, payload) {
      let response = await apolloClient.query({
        query: require('@/graphql/Licences/get_licence_utilisateur_hors_club.gql'),
        variables: {
          ID_Utilisateur: payload.ID_Utilisateur,
        },
        fetchPolicy: 'network-only',
      });
      return response.data.UTI_Licence;
    },

    async getUserDisabilities({ }, userId) {
      let response = await apolloClient.query({
        query: require('@/graphql/Disability/getUserDisabilities.gql'),
        variables: {
          id: userId,
        },
        fetchPolicy: 'network-only',
      });

      return response.data.UTI_Utilisateurs_Disability;
    },

    async updateUserPremiereConnexion({ commit }) {
      commit('SET_USER_PREMIERE_CONNEXION', new Date());
      return;
    },

    // update user Disabilities
    async updateDisabilities({ commit }, { id, disabilities }) {
      try {
        let response = await apolloClient.mutate({
          mutation: require("@/graphql/Disability/updateUserDisabilities.gql"),
          variables: {
            id,
            data: disabilities
          },
        });

        commit('SET_USER_DISABILITIES', response.data.insert_UTI_Utilisateurs_Disability.returning);
        success_notification("Les données de handicap ont été mises à jour");
      } catch (error) {
        error_notification('Une erreur est survenue lors de la mise à jour des données de handicap')
      }
    },

    ...pap.actions,
    ...certificatsMedicaux.actions,
  },
};
