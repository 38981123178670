export default {
  namespaced: true,

  state: {
    notificationAffiliation: localStorage.getItem('notificationAffiliation') === 'true' ?? false,
    notificationLicenceDocument: localStorage.getItem('notificationLicenceDocument') === 'true' ?? false,
  },

  getters: {
    notificationAffiliation: (state) => state.notificationAffiliation,
    notificationLicenceDocument: (state) => state.notificationLicenceDocument,
  },

  mutations: {
    SET_NOTIFICATION_AFFILIATION(state, payload) {
      localStorage.setItem('notificationAffiliation', payload);
      state.notificationAffiliation = payload;
    },

    SET_NOTIFICATION_LICENCE_DOCUMENT(state, payload) {
      localStorage.setItem('notificationLicenceDocument', payload);
      state.notificationLicenceDocument = payload;
    },
  },

  actions: {
    setNotificationAffiliation({ commit }, payload) {
      commit("SET_NOTIFICATION_AFFILIATION", payload);
    },
    resetNotificationAffiliation({ commit }) {
      commit("SET_NOTIFICATION_AFFILIATION", false);
    },
    setNotificationLicenceDocument({ commit }, payload) {
      commit("SET_NOTIFICATION_LICENCE_DOCUMENT", payload);
    },
    resetNotificationLicenceDocument({ commit }) {
      commit("SET_NOTIFICATION_LICENCE_DOCUMENT", false);
    },
  },
};
