import store from "@/store";
import router from "@/router";

const route = [
  {
    path: "/",
    name: "gestion_des_utilisateurs__dashboard",
    meta: {
      can: false,
      title: "Gestion des adhérents",
    },
    // Pas de dashboard pour cette route pour le moment, mais se preparer au pire...
    //  component: () => import(/* webpackChunkName: "gestion_des_licences" */ '@/views/MembresDeMonClub/MembresDeMonClub__Dashboard.vue')
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_utilisateurs" */ "@/views/GestionDesUtilisateurs/GestionDesUtilisateurs__Dashboard.vue"
      ),
  },
  {
    path: "membres-structure/:id/detail",
    name: "gestion_des_utilisateurs__membres_de_mon_club__afficher_utilisateur",
    meta: {
      can: false,
      title: "Afficher un membre de mon club",
    },
    component: () =>
      import(
          /* webpackChunkName: "gestion_des_utilisateurs" */ "@/modules/GestionDesUtilisateurs/AfficherMembreDeMonClub.vue"
      ),
  },
  {
    path: "membres-structure/:IDListe?",
    name: "gestion_des_utilisateurs__membres_de_mon_club",
    meta: {
      can: true,
      title: "Membres de mon club",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_utilisateurs" */ "@/modules/GestionDesUtilisateurs/MembresDeMonClub.vue"
      ),
  },
  {
    path: "verifcation-licence-niveau-national",
    name: "gestion_des_utilisateurs__verification_licence_niveau_national",
    meta: {
      can: true,
      title: "Vérification licence niveau national",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_utilisateurs" */ "@/modules/GestionDesUtilisateurs/VerificationLIcenceNiveauNational.vue"
      ),
  },

  {
    path: "membres-federaux-structure",
    name: "gestion_des_utilisateurs__membres_federaux_du_club",
    meta: {
      can: true,
      title: "Membres féderaux de la structure",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_utilisateurs" */ "@/modules/GestionDesUtilisateurs/MembresFederauxDuClub.vue"
      ),
  },
  {
    path: "demandes-modification",
    name: "demandes_de_modification__gestion_des_demandes",
    meta: {
      can: false,
      title: "Gestion des demandes",
    },
    beforeEnter: (to, from, next) => {
      if (
        store.getters["structure/currentStructureType"].SlugTypeStructure !=
        "club"
      )
        return next();
      return router.push({ name: "Home" });
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_utilisateurs" */ "@/modules/GestionDesUtilisateurs/DemandesDeModification.vue"
      ),
  },
  {
    path: "saisir-une-licence-siege",
    name: "gestion_des_licences__saisir_une_licence_siege",
    meta: {
      can: true,
      title: "Saisir une licence siège",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences_siege" */ "@/modules/GestionDesLicences/SaisirUneLicenceSiege.vue"
      ),
  },
  {
    path: "/gestion-des-licences-siege/liste-licence-siege",
    name: "gestion_des_licences__list",
    meta: {
      can: true,
      title: "Liste des licences siège",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_licences_siege" */ "@/modules/GestionDesLicences/LicenceSiege__List.vue"
      ),
  },
];

export default route;
