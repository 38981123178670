import store from "@/store";
import router from "@/router";
import RouteSingleStructure from "@/router/routes/SingleStructure";
/* eslint-disable no-unused-vars*/

const route = [
  {
    path: "/",
    name: "gestion_des_structures",
    meta: {
      can: false,
      title: "Gestion des structures",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_structures" */ "@/modules/GestionDesStructures/GestionDesStructures__Dashboard.vue"
      ),
  },
  {
    path: "/structures/rechercher",
    name: "gestion_des_structures__search",
    meta: {
      can: true,
      title: "Rechercher une structure",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_structures" */ "@/modules/GestionDesStructures/GestionDesStructures__List.vue"
      ),
  },
  {
    path: "/structures/synthese",
    name: "gestion_des_structures__synthese",
    meta: {
      can: true,
      title: "Tableau de synthèse",
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_structures" */ "@/modules/GestionDesStructures/GestionDesStructures__Synthese.vue"
      ),
  },
  {
    path: "/structures/single/:id",
    name: "information_de_la_structure__infos",
    children: RouteSingleStructure,
    meta: {
      can: true,
      title: "Détails structure",
      public: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_structures" */ "@/modules/GestionDesStructures/GestionDesStructures__StructureDetail.vue"
      ),
  },
];

export default route;
