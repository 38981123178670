import { /*sleep,*/ isEmpty } from "@/plugins/utils.js";
import { apolloClient } from "@/vue-apollo.js";

const state = {
  personnesAPrevenir: {},
};

const getters = {};

const mutations = {
  SET_USER_PERSONNE_A_PREVENIR(state, payload) {
    state.UTI_Utilisateur.UTI_PersonnesAPrevenir = payload;
  },

  INSERT_PERSONNE_A_PREVENIR(state, personne) {
    state.UTI_Utilisateur.UTI_PersonnesAPrevenir.push(personne);
  },

  UPDATE_PERSONNE_A_PREVENIR(state, personne) {
    let personneState = state.UTI_Utilisateur.UTI_PersonnesAPrevenir.filter(
      (p) => p.id == personne.id
    );
    state.UTI_Utilisateur.UTI_PersonnesAPrevenir.indexOf(personneState);
    // It's not necessary to push the state, because it's already link with the computed in component.
    // But it can be useful to have the commit for others actions laters
  },

  DESTROY_PERSONNE_A_PREVENIR(state, id) {
    let index = state.UTI_Utilisateur.UTI_PersonnesAPrevenir.findIndex(
      (personne) => personne.id === id
    );
    state.UTI_Utilisateur.UTI_PersonnesAPrevenir.splice(index, 1);
  },
};

const actions = {
  // Get pzersonnes a prevenir
  // in PersonneAPrevenir.vue
  async getCurrentUserPAP({ commit, getters }) {
    // TODO make it work with any id (add params)
    if (!isEmpty(getters.currentUser.UTI_PersonnesAPrevenirs)) {
      return getters.currentUser.UTI_PersonnesAPrevenir;
    }

    let response = await apolloClient.query({
      query: require("@/graphql/Account/PersonneAPrevenir/get.gql"),
      variables: {
        __ID_Utilisateur: getters.currentUserId,
      },
    });
    let data = response.data.UTI_PersonnesAPrevenir;
    commit("SET_USER_PERSONNE_A_PREVENIR", data);
    return data;
  },

  // Insert personnes a prevenir
  // in PersonneAPrevenir.vue
  async insertCurrentUserPAP({ commit, getters }, personne) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Account/PersonneAPrevenir/insert.gql"),
      variables: {
        newPersonne: {
          ID_Utilisateur: personne.Owner || getters.currentUserId,
          Nom: personne.Nom,
          Prenom: personne.Prenom,
          Tel_Mobile: personne.Tel_Mobile,
          EST_Civilite: 1,
          Lien_Parente: personne.Lien_Parente,
          Email: personne.Email,
        },
      },
    });
    let data = response.data.insert_UTI_PersonnesAPrevenir.returning;
    if (data[0] && !personne.Owner) {
      commit("INSERT_PERSONNE_A_PREVENIR", data[0]);
    }
    return data;
  },

  // Update personnes a prevenir
  // in PersonneAPrevenir.vue
  async updateCurrentUserPAP({ commit }, personne) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Account/PersonneAPrevenir/update.gql"),
      variables: {
        __ID_PAP: personne.id,
        Nom: personne.Nom,
        Prenom: personne.Prenom,
        Tel_Mobile: personne.Tel_Mobile,
        EST_Civilite: 1,
        Lien_Parente: personne.Lien_Parente,
        Email: personne.Email,
      },
    });
    let data = response.data.update_UTI_PersonnesAPrevenir.returning;
    if (data.length && !personne.Owner) {
      commit("UPDATE_PERSONNE_A_PREVENIR", data[0]);
    }
    return data;
  },

  // Destroy personnes a prevenir
  // in PersonneAPrevenir.vue
  async deleteCurrentUserPAP({ commit }, personne) {
    let response = await apolloClient.mutate({
      mutation: require("@/graphql/Account/PersonneAPrevenir/destroy.gql"),
      variables: {
        __ID_PAP: personne.id,
      },
    });

    if (
      response.data.delete_UTI_PersonnesAPrevenir.affected_rows == 1 &&
      !personne.Owner
    ) {
      commit("DESTROY_PERSONNE_A_PREVENIR", personne.id);
      return true;
    } else if (
      response.data.delete_UTI_PersonnesAPrevenir.affected_rows == 1 &&
      personne.Owner
    ) {
      return true;
    }
  },
};

export { state, getters, mutations, actions };
