import { apolloClient } from "@/vue-apollo";
export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    async checkUser(ctx, { firstname, lastname, birthday }) {
      return await apolloClient.query({
        query: require("@/graphql/Playground/checkUser.gql"),
        variables: {
          firstname,
          lastname,
          birthday: birthday ? birthday : null,
        },
      });
    },
  },
};
