import store from "@/store"
import router from "@/router"

const route = [
  {
    path: "/",
    name: "gestion_des_competitions__dashboard",
    meta: {
      "can" : false,
      "title": "Gestion des compétitions"
    },
    // Pas de dashboard pour cette route pour le moment, mais se preparer au pire...
    //  component: () => import(/* webpackChunkName: "gestion_des_licences" */ '@/views/MembresDeMonClub/MembresDeMonClub__Dashboard.vue')
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/views/GestionDesCompetitions/GestionDesCompetitions__Dashboard.vue"
      ),
  },
  {
    path: "mes-competitions",
    name: "gestion_des_competitions__mes_competitions",
    meta: {
      "can" : true,
      "title": "Mes compétitions"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionDesCompetitions/MesCompetitions.vue"
      ),
  },
  {
    path: "saisir-une-competition",
    name: "gestion_des_competitions__saisir_une_competition",
    meta: {
      "can" : true,
      "title": "Saisir une compétition"
    },
    component: () =>
      import(
        /* webpackChunkName: "gestion_des_competitions" */ "@/modules/GestionDesCompetitions/SaisirUneCompetition.vue"
      ),
  },
];

export default route;
