<template>
  <div class="licence_mini-fiche-container mt-4">
    <v-card
      :loading="loading"
      class="mx-auto mt-2 text-center"
      max-width="374"
      elevation="4"
    >
      <v-card-text v-if="noUser">
        <div class="subtitle-2">Aucun profil trouvé</div>
      </v-card-text>

      <div v-if="user && !loading">
        <Avatar classes="__xl mt-5" :userData="currentUser"></Avatar>

        <v-card-text>
          <BasicInfoUser
            :context="{
              ID_Utilisateur: context.ID_Utilisateur,
              ID_Saison: currentSaison.id,
              UTI_Utilisateur: currentUser,
            }"
          />
        </v-card-text>

        <v-card-title
          class="blue-dark text-white justify-center subtitle-1 py-3 mb-3"
          >Personne à prévenir en cas d'incident</v-card-title
        >

        <v-list v-if="personne_prevenir.length">
          <v-list-item
            v-for="personne in personne_prevenir"
            :key="personne.index"
          >
            <v-list-item-content>
              <v-list-item-title class="subtitle-1 font-weight-bold">{{
                fullName(personne)
              }}</v-list-item-title>
              <v-list-item-subtitle class="subtitle-2 text-secondary">
                <v-chip
                  v-if="personne.Tel_Mobile"
                  small
                  class="mb-2"
                  color="gray accent-4"
                  outlined
                >
                  <v-icon small left>mdi-phone</v-icon>
                  {{ personne.Tel_Mobile }}
                </v-chip>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="subtitle-2 text-secondary"
                ><v-chip
                  v-if="personne.Email"
                  small
                  class=""
                  color="gray accent-4"
                  outlined
                >
                  <v-icon small left>mdi-at</v-icon>
                  {{ personne.Email }}
                </v-chip></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-card-text v-else>
          <div class="subtitle-2">Aucune personne à prévenir</div>
        </v-card-text>

        <v-card-title
          class="blue-dark text-white justify-center subtitle-1 py-3 mb-3"
          >Passeport FFME</v-card-title
        >

        <v-card-text>
          <div class="subtitle-2">Prochainement disponible</div>
        </v-card-text>
      </div>
    </v-card>
  </div>
</template>

<script>
import Avatar from "@/components/Avatar.vue";
import BasicInfoUser from "@/components/User/User__BasicInfo.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import {trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters} from "@/plugins/utils";

export default {
  components: { Avatar, BasicInfoUser },

  data: () => ({
    licenceData: {},
    user: null,
    noUser: false,
    loading: true,
    personne_prevenir: {},
  }),

  methods: {
    ...mapActions("utilisateurs", ["getUtilisateurFullData"]),
    ...mapActions("utilisateurs", ["getUtilisateursPAP"]),

    fullName(personne) {
      let lastname = trimAndUppercaseAllLetters(personne.Nom);
      let firstname = trimAndCapitalizeFirstLetter(personne.Prenom);
      return personne.Lien_Parente
        ? `${lastname} ${firstname} (${personne.Lien_Parente})`
        : `${lastname} ${firstname}`;
    },
  },
  async created() {
    this.user = { ...this.utilisateurFullData };
    this.personne_prevenir = await this.getUtilisateursPAP({
      ID_Utilisateur: this.context.ID_Utilisateur,
    });
    console.log("personne a prevenir ======>>", this.personne_prevenir);
    if (!this.user) this.noUser = true;
    this.loading = false;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    ...mapState({
      utilisateurFullData: (state) => state.utilisateurs.utilisateursFullData,
    }),

    currentUser() {
      if (!this.user?.UTI_Utilisateurs) return;
      return this.user?.UTI_Utilisateurs[0];
    },

    currentActiveLicence() {
      if (!this.user) return;
      let now = this.$moment();
      return this.currentUser.UTI_Licences.find((lic) => {
        if (
          now > this.$moment(lic.DateLicenceValidation) &&
          now < this.$moment(lic.DateLicenceFinValidite)
        ) {
          return true;
        }
      });
    },

    currentUserPAP() {
      if (!this.personne_prevenir) return;
      return this.personne_prevenir[0];
    },

    dateSaisieLicence() {
      return this.$moment(
        this.currentActiveLicence.DateLicenceValidation
      ).format("DD/MM/YYYY");
    },
    dateFinLicence() {
      return this.$moment(
        this.currentActiveLicence.DateLicenceFinValidite
      ).format("DD/MM/YYYY");
    },
    dateNaissance() {
      return this.$moment(this.currentUser.dateNaissance).format("DD/MM/YYYY");
    },
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>
