/* eslint-disable no-empty-pattern */
/* eslint-disable  no-unused-vars */
import { apolloClient } from "@/vue-apollo.js";
// import {error_notification} from '@/plugins/notifications'

export default {
  namespaced: true,

  state: {
    STR_Structures: [],
  },

  getters: {
    adminState: (state) => {
      return state;
    },
  },

  mutations: {
    ADMIN_SET_STRUCTURES(state, payload) {
      state.STR_Structures = payload.data.STR_Structure;
    },
  },

  actions: {
    async getAllStructuresInfo({}, payload) {
      // console.log("payload", payload)
      let file;
      if (payload.TypeStructure == "ligue") {
        file = require("@/graphql/admin/get_all_structures_ligue.gql");
      } else if (payload.TypeStructure == "comite_territorial") {
        file = require("@/graphql/admin/get_all_structures_ct.gql");
      } else if (payload.TypeStructure == "service_federal_ffme") {
        file = require("@/graphql/admin/get_all_structures_ffme.gql");
      }
      return await apolloClient
        .query({
          query: file,
          variables: {
            limit: payload.limit,
            offset: payload.offset,
            ID_Structure: payload.ID_Structure,
          },
        })
        .then((response) => {
          return response.data.STR_Structure;
        })
        .catch((response) => {
          // console.log(response);
        });
    },
    async searchStructure({}, payload) {
      // console.log(payload);
      let file;
      if (payload.TypeStructure == "ligue") {
        file = require("@/graphql/admin/search_structures_ligue.gql");
      } else if (payload.TypeStructure == "comite_territorial") {
        file = require("@/graphql/admin/search_structures_ct.gql");
      }
      return await apolloClient
        .query({
          query: file,
          variables: {
            StructureName: "%" + payload.StructureName + "%",
            ID_Structure: payload.ID_Structure,
          },
        })
        .then((response) => {
          return response.data.STR_Structure;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getStructure({}, payload) {
      console.log("getStructure payload --------");
      return await apolloClient
        .query({
          query: require("@/graphql/admin/get_structure_infos.gql"),
          variables: {
            ID_Structure: payload.ID_Structure,
            ID_Saison: payload.ID_Saison,
          },
        })
        .then((response) => {
          return response.data.STR_Structure[0];
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getStructureBasicInfo({}, payload) {
      return await apolloClient
        .query({
          query: require("@/graphql/admin/get_structure_basic_infos.gql"),
          variables: { ID_Structure: payload.ID_Structure },
        })
        .then((response) => {
          return response.data.STR_Structure[0];
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getRolesList({}) {
      return await apolloClient
        .query({ query: require("@/graphql/admin/get_list_roles.gql") })
        .then((response) => {
          return response.data.ADM_RoleType;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async editStrucutreUtilisateurRole({}, payload) {
      // console.log(payload)
      return await apolloClient
        .mutate({
          mutation: require("@/graphql/admin/edit_structure_utillisateur_role.gql"),
          variables: { user: payload.role },
        })
        .then((response) => {
          return response.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async addModuleToUser({}, payload) {
      // console.log(payload.user)
      return await apolloClient
        .mutate({
          mutation: require("@/graphql/admin/add_module_to_user.gql"),
          variables: { moduleUser: payload.module },
        })
        .then((response) => {
          return response.data;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async deleteModuleAccess({}, payload) {
      // console.log(payload);
      return await apolloClient
        .mutate({
          mutation: require("@/graphql/admin/delete_module_to_user.gql"),
          variables: payload.module,
        })
        .then((response) => {
          return response.data.delete_ADM_StructureUtilisateurModule;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async removeAllUserModule(ctx, { ID_Utilisateur, ID_Structure }) {
      const response = await apolloClient.mutate({
        mutation: require("@/graphql/admin/delete_all_module_to_user.gql"),
        variables: {
          ID_Utilisateur,
          ID_Structure,
        },
      });
      console.log("response:", response);
    },
  },
};
