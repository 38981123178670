/* eslint-disable no-unused-vars */
/* eslint-disable no-empty-pattern */

import { apolloClient } from "@/vue-apollo.js";

export default {
  namespaced: true,

  state: {},

  getters: {},

  mutations: {},

  actions: {
    async getTarifsAssurance({}, payload) {
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_assurances.gql"),
        variables: { ID_Saison: payload.ID_Saison },
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Tarif;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getTarifsBySaison({}, payload) {
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_by_saison.gql"),
        variables: { ID_Saison: payload.ID_Saison },
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Tarif;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getTarifsLicence_Assurances({}, payload) {
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_licence_assurances.gql"),
        variables: { ID_Saison: payload.ID_Saison },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Tarif;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getAllTarifForLicence({}, payload) {
      // console.log(payload.context)
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_all_licence.gql"),
        variables: {
          ID_Saison: payload.context.ID_Saison,
          ID_Tarif: payload.context.ID_Tarif,
          IDS_Users: payload.context.usersID,
          IDS_Structures: payload.context.IDS_Structures,
          ID_Structure: payload.context.ID_Structure,
        },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async TarifsLicence({}, payload) {
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_licence.gql"),
        variables: {
          ID_Saison: payload.context.ID_Saison,
          IDS_Users: payload.context.usersID,
          IDS_Structures: payload.context.IDS_Structures,
          ID_Structure: payload.context.ID_Structure,
        },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getAllTarifLicence({}, payload) {
      // console.log(payload.context)
      let query = {
        query: require("@/graphql/Tarifs/get_all_tarif_licence.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          IDS_Structures: payload.IDS_Structures,
        },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Tarif;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // get tarif licence pour CT
    async getTarifLicenceCT({}, payload) {
      // console.log(payload.context)
      let query = {
        query: require("@/graphql/Tarifs/get_tarif_licence_CT.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          ID_Structure: payload.ID_Structure,
        },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Tarif;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // get tarif licence pour Ligue
    async getTarifLicenceLigue({}, payload) {
      // console.log(payload.context)
      let query = {
        query: require("@/graphql/Tarifs/get_tarif_licence_Ligue.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          ID_Structure: payload.ID_Structure,
        },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data.MKP_Tarif;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getTarifForLicenceSiege({}, payload) {
      console.log(payload);
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_licence_siege.gql"),
        variables: {
          ID_Saison: payload.context.ID_Saison,
        },
        fetchPolicy: "network-only",
      };

      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async getTarifsSaison({}, payload) {
      let query = {
        query: require("@/graphql/Tarifs/get_tarifs_saison.gql"),
        variables: {
          ID_Saison: payload.ID_Saison,
          IDS_Structure: payload.IDS_Structure,
        },
        fetchPolicy: "network-only",
      };
      return await apolloClient
        .query(query)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
