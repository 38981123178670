const route = [
  {
    path: "/licences-hors-club/:user_id?",
    name: "hors_club__inscription",
    meta: {
      public: true,
      can: false,
      title: "Prendre une licence hors club",
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/modules/HorsClub/hors_club__inscription.vue"
      ),
  },

  {
    path: "paiement/:user_id/:token",
    name: "hors_club__paiement",
    meta: {
      public: true,
      can: false,
      title: "Payer une licence hors club",
    },
    component: () =>
      import(
        /* webpackChunkName: "MesAssurances" */ "@/modules/HorsClub/hors_club__paiement.vue"
      ),
  },
];

export default route;
